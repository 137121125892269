.obtain-financing-form .radio-label {
  height: unset;
  font-size: 0.9rem;
}
.obtain-financing-form .radio-container {
    padding: 8px;
}
.obtain-financing-form .radio-group {
  gap: 15px;
}
.obtain-financing-form .activites-list {
    top: 200px;
    margin-left: 10px;
}
