.header-container {
  padding: 20px 15px 15px;
  z-index: 1000;
  position: relative;
}
.header-buttons-container {
  display: flex;
  gap: 10px;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column-reverse;
}
.navbar-container {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin: 0 80px;
  align-items: center;
  width: 100%;
}
.navbar-container li {
  text-align: center;
  position: relative;
  height: fit-content;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.navbar-container li p,
.navbar-container li p > * {
  transition: font-family 0.3s;
}
.navbar-container li p:before,
.navbar-container li p > *:before {
  display: block;
  content: attr(custom-title);
  font-family: Outfit;
  font-weight: 700;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}
.navbar-container li:hover p,
.navbar-container li:hover p > * {
  font-family: Outfit;
  font-weight: 700;
  color: var(--dark-blue);
}
.navbar-content-arrow {
  margin: 0 0 0 -10px;
  position: relative;
  --color: var(--sky-blue) !important;
  opacity: 0;
  left: -15px;
  transition:
    opacity 0.3s,
    left 0.3s;
}
.navbar-content::-webkit-scrollbar-thumb {
  background-color: var(--creamy-white);
}
.navbar-content {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  white-space: nowrap;
  position: absolute;
  top: 40px;
  left: -70px;
  background-color: var(--creamy-white);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  transition:
    max-height 0.3s,
    opacity 0.5s;
  z-index: 1000;
  box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.35);
}
.navbar-content a:hover {
  color: var(--sky-blue);
}
.navbar-content a:hover .navbar-content-arrow {
  opacity: 1;
  left: 0;
}
.navbar-content a {
  display: flex;
  padding: 10px 20px;
  gap: 20px;
  align-items: center;
  transition: color 0.3s;
}
.logo-container {
  display: flex;
  align-items: center;
  transition: max-height 0.3s;
  max-height: 85px;
  width: fit-content;
}
.logo-container svg {
  object-fit: contain;
}
.opened-header-section {
  opacity: 1;
}
.header-bracket {
  display: none;
}
.mobile-header-buttons {
  display: none;
}
.demo-button {
  color: var(--dark-blue);
  width: max-content;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  height: fit-content;
  cursor: pointer;
  margin: auto 0;
  position: relative;
}
.demo-button:before {
  content: "";
  position: absolute;
  bottom: -2px;
  height: 2px;
  transition:
    width 0.3s,
    left 0.3s;
  width: 100%;
  background-color: var(--dark-blue);
  left: 0;
}
.homepage-header .demo-button {
  color: var(--white);
}
.homepage-header .demo-button:before {
  background-color: var(--white);
}
.demo-button:hover:before {
  width: 0;
  left: 50%;
}
.header-user-icon {
  cursor: pointer !important;
  --color: var(--dark-blue) !important;
}
.homepage-header .header-user-icon {
  --color: var(--white) !important;
}
.homepage-header .account-button {
  background-color: var(--dark-blue) !important;
  border-color: var(--dark-blue) !important;
  color: var(--white) !important;
}
.homepage-header  .account-button-container:hover .account-button {
  background-color: var(--white) !important;
  color: var(--dark-blue) !important;
}
.account-button {
  position: relative;
  border-radius: 50%;
  width: 30px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition:
    background-color 0.3s,
    border-color 0.3s,
    color 0.3s;
    border-width: 1px;
  font-family: Outfit;
  font-weight: 600;
  font-size: 0.8rem;
  background-color: var(--white) !important;
  border-color: var(--white) !important;
  color: var(--dark-blue) !important;
}
.account-button-container:hover .account-button {
  background-color: var(--dark-blue) !important;
  color: var(--white) !important;
}
.account-button-container:hover .account-button .header-user-icon {
  --color: var(--white) !important;
}
.homepage-header .account-button-container:hover .account-button .header-user-icon {
  --color: var(--dark-blue) !important;
}
.account-button-container > svg {
  --color: var(--white) !important;
  margin-top: 3px;
  transition: transform 0.3s;
}
.account-button-container {
  padding: 3px 10px 3px 3px;
  border-radius: 6px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: var(--dark-blue);
  color: var(--white);
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 12px;
}
.homepage-header .account-button-container {
  background-color: var(--creamy-white);
  color: var(--dark-blue);
}
.homepage-header .account-button-container > svg {
  --color: var(--dark-blue) !important;
}
.account-nav .header-deconnexion-link {
  color: var(--red);
}
.header-deconnexion-link .navbar-content-arrow {
  --color: var(--red) !important;
}
.header-sub-menu {
  margin-top: -3px;
}
.header-sub-menu a {
  margin-left: 15px;
  color: var(--dark-blue-alt);
  padding: 6px 20px;
}
.account-nav {
  overflow: visible;
  right: 0;
  left: unset;
  top: 45px;
}
.cart-badge {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 14px;
  height: 14px;
  background-color: var(--dark-red);
  border-radius: 50%;
  z-index: 1000;
}
.mobile-cart-badge {
  display: none;
}
.panier-navlink {
  position: relative;
}
.panier-navlink .cart-badge {
  height: 20px;
  width: 20px;
  top: 10.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 5px;
  color: var(--white);
  font-size: 0.8rem;
  border-radius: 5px;
}

.soon-tag-nav {
  background-color: var(--red);
  color: var(--dark-blue);
  font-size: 0.7rem;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 3px;
  text-transform: uppercase;
}

.disabled-nav-link {
  cursor: default;
  color: var(--grey);
  opacity: 0.5;
}

.v1-link {
  align-items: center;
  display: flex;
  gap: 5px;
  background-color: white;
  border-radius: 5px;
  border: 2px solid var(--gold);
  transition: background-color 0.3s;
  padding: 4px 8px 5px;
  position: absolute;
  bottom: -50px;
  right: 15px;
  z-index: 10;
  font-size: 0.8rem;
  font-weight: 600;
}
.v1-link:hover {
  background-color: var(--gold);
}

.disabled-nav-link:hover {
  color: var(--grey) !important;
}

.v1-link-mobile {
  align-items: center;
  display: flex;
  gap: 5px;
  background-color: white;
  border: 2px solid var(--gold);
  transition: background-color 0.3s;
  border-radius: 5px;
  padding: 4px 8px 5px;
  font-weight: 600;
  font-size: 0.8rem !important;
  margin-right: 20px;
}

.v1-link-mobile:hover {
  background-color: var(--gold);
}

.red-dot {
  position: absolute;
  bottom: 9%;
  left: 80%;
  width: 1rem;
  height: 1rem;
  background-color: var(--red);
  border-radius: 50%;
}

@media (max-width: 1200px) {
  .account-button-container > svg {
    --color: var(--dark-blue) !important;
  }
  .homepage-header .account-button-container > svg {
    --color: var(--white) !important;
  }
  .account-button-container {
    background-color: transparent !important;
    align-items: flex-start;
    color: var(--dark-blue);
  }
  .homepage-header .account-button-container {
    color: var(--white);
  }
  .account-button {
    background-color: var(--dark-blue) !important;
    border-color: var(--dark-blue) !important;
    color: var(--white) !important;
  }
  .homepage-header .account-button {
    background-color: var(--white) !important;
    border-color: var(--white) !important;
    color: var(--dark-blue) !important;
  }
  .account-button {
    width: 40px;
    height: 40px;
  }
  .cart-badge {
    display: none;
  }
  .mobile-cart-badge {
    display: block;
    top: -9px;
    right: -7px;
  }
  .navbar-content {
    left: auto;
  }
  .header-container * {
    font-size: 1.5rem;
  }
  .account-button {
    top: -1px;
    font-size: 1.2rem;
  }
  .header-rounded-div {
    top: -5px;
  }
  .opened-header .header-rounded-div {
    display: none;
  }
  .header-background.header-background-opened:not(.homepage-header) {
    background-color: var(--creamy-white);
  }
  .header-background {
    background-color: var(--dark-blue);
  }
  .header-background {
    transition: background-color 0.3s;
  }
  .logo-container svg {
    object-fit: unset;
  }
  .logo-container {
    overflow: hidden;
    transition: max-width 0.8s;
    display: block;
  }
  nav {
    min-height: calc(100vh - 35px);
    justify-content: flex-start !important;
  }
  .header-bracket {
    display: inline;
    margin-left: 15px;
    transition: transform 0.3s !important;
  }
  .header-container {
    transition:
      max-height 0.6s,
      background-color 0.3s;
    height: 101vh;
    overflow: hidden;
  }
  .header-container.opened-header {
    overflow: hidden;
  }
  .closed-header {
    max-height: 100px;
    background-color: var(--dark-blue);
  }
  .header-container nav {
    gap: 30px;
  }
  .opened-header .logo-container {
    max-width: 400px;
  }
  .header-container nav,
  .navbar-container {
    flex-direction: column;
  }
  .navbar-container {
    gap: 20px;
    margin: 0;
    margin-top: 15px;
    align-items: flex-start;
  }
  .opened-header {
    max-height: 101vh;
    background: var(--creamy-white);
  }
  .opened-header .mobile-header-buttons {
    right: 20px;
  }
  .mobile-header-buttons {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    transition: right 0.3s;
    align-items: center;
    height: 60px;
  }
  .mobile-header-buttons .button {
    transition:
      background-color 0.3s,
      color 0.3s,
      opacity 0.3s;
    opacity: 1;
  }
  .burger-menu {
    width: 30px;
    height: 20px;
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    justify-content: space-between;
  }
  .burger-menu div {
    height: 3px;
    width: 100%;
    background-color: var(--white);
    border-radius: 2px;
    transition: background-color 0.3s;
  }
  .opened-header .burger-menu div {
    background-color: var(--blue);
  }
  .burger-menu div {
    transition: all 0.3s;
  }
  .opened-header .mobile-header-buttons .button {
    opacity: 0;
  }
  .opened-header .burger-menu {
    justify-content: center;
    position: relative;
  }
  .opened-header .burger-menu div {
    display: none;
  }
  .opened-header .burger-menu div:first-child {
    display: block;
    position: absolute;
    transform: rotate(45deg);
    transform-origin: center;
  }
  .opened-header .burger-menu div:last-child {
    display: block;
    position: absolute;
    transform: rotate(-45deg);
    transform-origin: center;
  }
  .opened-header .header-buttons-container {
    align-items: flex-start;
    flex-direction: column;
  }
  .opened-header .account-button-container > svg {
    height: 15px;
    width: 15px;
    margin-top: 11px;
  }
  .opened-header .header-buttons-container .flex {
    flex-direction: row-reverse;
  }
  .navbar-container li {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
  }
  .navbar-content {
    position: relative;
    opacity: 1;
    top: 0;
    box-shadow: none;
  }
  .navbar-content a:hover {
    color: var(--sky-blue);
  }
  .navbar-content a {
    padding: 5px 40px;
  }
  .navbar-container li p,
  .navbar-container li p > * {
    font-size: 2rem;
  }
  .navbar-container li:hover p,
  .navbar-container li:hover p > * {
    font-family: Outfit;
    font-weight: 500;
    transition: font-family 0.3s;
  }
  .mobile-opened-index {
    font-family: Outfit !important;
    font-weight: 700 !important;
  }
  .mobile-opened-index .header-bracket {
    transform: rotate(0) !important;
  }
}

@media (max-width: 992px) {
  .logo-container {
    max-width: 85px;
  }
}

@media (max-width: 576px) {
  .opened-header .account-button-container > svg {
    margin-top: 7px;
}
  .opened-header .account-button-container {
    gap: 8px;
}
  .v1-link-mobile {
    font-size: 0.7rem !important;
    margin-right: 10px;
  }
  .account-button {
    font-size: 1rem !important;
    top: -4px;
    width: 35px;
    height: 35px;
  }
  .header-bracket {
    width: 12px;
    margin-left: 10px;
  }
  .logo-container {
    max-height: 43px;
  }
  .closed-header {
    max-height: 80px;
  }
  .mobile-header-buttons {
    height: 40px;
  }
  .navbar-container {
    gap: 5px;
  }
  .navbar-content-arrow {
    display: none;
  }
  .navbar-container li p,
  .navbar-container li p > *,
  .header-container * {
    font-size: 1.1rem;
  }
  .navbar-content a {
    font-size: 1rem !important;
  }
  .header-container nav {
    gap: 10px;
  }
  .navbar-content a {
    padding: 5px 10px;
    gap: 6px;
    max-width: calc(100vw - 30px);
  }
  .navbar-content svg {
    min-width: 30px;
  }
}
