.tab-content-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.title-ref-tab-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.settings-icon {
  margin-right: 10px;
}

.ref-folder {
  padding: 0 20px;
  margin-top: 0 !important;
}

.tab-content-header h2 {
  margin: 0;
  font-weight: 600;
  font-size: 1.7rem;
  display: flex;
  align-items: center;
}

.tab-content-header h2 .tuto-icon {
  margin-left: 15px;
}

.tab-content-header p {
  margin-top: 10px;
  font-size: 1.1rem;
  font-weight: 300;
  color: var(--grey);
}

.folder-info-buttons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.crm-dashboard {
  display: flex;
  gap: 20px;
}

.crm-dashboard .form-card {
  flex-basis: 100%;
}

.form-card p {
  text-align: center !important;
}
.crm-card:first-child:last-of-type {
  background-color: var(--creamy-white);
}
.crm-card:last-child * {
  color: var(--white);
}
.crm-card:last-child {
  background-color: var(--blue);
}
.crm-card {
  background-color: #b7c4fb;
  padding: 20px;
  position: relative;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.crm-card h4 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0;
  font-size: 1.3rem;
  font-weight: 700;
  margin-bottom: 10px;
}

.crm-card h4 span {
  cursor: pointer;
  border: 1px solid var(--grey);
  background-color: var(--white);
  color: var(--dark-blue) !important;
  min-height: 22px;
  height: 22px;
  min-width: 22px;
  width: 22px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  font-weight: 400;
}

.crm-card p {
  font-size: 1rem;
  font-weight: 200;
  text-align: justify;
}

.crm-card ul {
  margin-top: 10px;
  padding-left: 20px;
}

.cards-stack {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.cards-stack .crm-card {
  flex-basis: calc(50% - 10px);
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-left: 5px;
  color: var(--dark-blue);
  font-weight: 500;
}

.tooltip::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 125%;
  left: 50%;
  transform: translateX(-75%) translateY(-10px);
  background-color: var(--dark-blue);
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s;
  z-index: 10;
}

.tooltip::before {
  content: "";
  position: absolute;
  bottom: 115%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.75) transparent transparent transparent;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s;
  z-index: 10;
}

.tooltip:hover::after,
.tooltip:hover::before {
  opacity: 1;
  visibility: visible;
}

.number-count {
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-weight: 500;
  font-size: 4rem;
  color: var(--dark-blue);
}

@media (max-width: 992px) {
  .crm-dashboard {
    flex-wrap: wrap;
  }
  .crm-card.cursor-pointer h4 {
    margin-left: 70px;
    width: calc(100% - 70px);
  }
  .cards-stack .crm-card {
    flex-basis: 100%;
  }
  .cards-stack .crm-card {
    max-width: calc(50% - 10px);
  }
}

@media (max-width: 768px) {
  .number-count {
    top: 0;
    bottom: 0;
    margin: auto 0;
    height: fit-content;
  }
  .title-ref-tab-header {
    flex-direction: column;
    align-items: flex-start;
  }
  .form-card ,
  .cards-stack, .cards-stack .crm-card {
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media (max-width: 576px) {
  .crm-dashboard {
    flex-direction: column;
  }
  .crm-dashboard > * {
    max-width: 100%;
    flex-basis: 100%;
  }
}
