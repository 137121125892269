.calculateur-container {
  max-width: 800px;
  width: 90vw !important;
  padding: 30px;
  overflow-y: hidden;
  transition:
    max-height 0.5s,
    margin 0.5s,
    padding 0.5s;
}
.calculateur-container.parcours-calculateur {
  max-width: 100%;
  --form-background-color: var(--dark-blue);
  padding-bottom: 30px;
  margin: 0 auto;
}
.calculateur-container.parcours-calculateur.hidden-fieldset {
  padding: 0;
}
.calculateur-container h2 {
  justify-content: var(--align);
}
.calculateur-container.parcours-calculateur .form-label,
.calculateur-container.parcours-calculateur h2,
.calculateur-container.parcours-calculateur .form-legend,
.calculateur-container.parcours-calculateur span:before,
.calculateur-container.parcours-calculateur input {
  color: var(--white);
}
.tns-container,
.salarie-container {
  transition: 0.8s max-height !important;
}
.remuneration-button {
  align-items: center;
  display: flex;
  gap: 8px;
  cursor: pointer;
  transition: color 0.3s;
}
.remuneration-button.active > div {
  color: var(--gold);
}
.remuneration-buttons-container {
  display: flex;
  margin: 10px 0;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}
.remuneration-tooltip {
  background-color: var(--sky-blue);
  border-radius: 5px;
  padding: 5px 8px;
  color: var(--white);
  transition: opacity 0.3s;
  opacity: 0;
  position: absolute;
  bottom: -47px;
  font-size: 0.7rem;
  text-align: center;
  display: flex;
  justify-content: center;
}
.remuneration-tooltip:before {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 0 10px 10px;
  top: -10px;
  z-index: 5;
  opacity: 1;
  transition: opacity 0.5s;
  border-color: transparent transparent var(--sky-blue) transparent;
}
.remuneration-button > div {
  position: relative;
  color: var(--white);
  justify-content: center;
  text-decoration: underline;
  display: flex;
}
.remuneration-button > div:hover .remuneration-tooltip {
  opacity: 1;
}
.calculateur-cotisations-top-text {
  color: var(--grey);
  font-size: 0.9rem;
  font-family: Poppins-Medium;
  text-align: center;
}
