.carousel-title {
  text-transform: lowercase;
  font-variant: small-caps;
  font-size: 1.2rem;
  font-family: Outfit;
font-weight: 500;
  color: var(--color);
}
.carousel-container {
  position: relative;
  min-height: 50px;
  padding: 0 50px;
  margin-top: 20px;
}
.carousel-arrow-right,
.carousel-arrow {
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  padding: 0 10px;
  left: -20px;
  top: 0;
}
.carousel-arrow-right {
  left: auto;
  right: -20px;
}
.carousel-content {
  overflow-x: hidden;
}
.carousel-items {
  width: 100%;
  display: flex;
  gap: 2rem;
  transform: translateX(calc(-1 * var(--translate) * ((100% + 2rem) / var(--displayed-items))));
}
.carousel-items > * {
  width: calc((100% + 2rem) / var(--displayed-items) - 2rem);
  min-width: calc((100% + 2rem) / var(--displayed-items) - 2rem);
}
