.pdf-container .table-pdf-container {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}
.pdf-container .table-pdf-container thead th {
  font-size: 1.1rem;
  padding: 5px;
  background-color: var(--table-header);
  color: var(--table-header-text);
}
.pdf-container .table-pdf-container tbody th,
.pdf-container .table-pdf-container tbody td {
  padding: 0 7px;
  z-index: 2;
  text-align: center;
  border: 1px solid var(--dark-blue);
}
.pdf-container .table-pdf-container tbody td {
  text-align: right;
  font-size: 0.9rem;
}
.pdf-container .table-pdf-container tbody th {
  text-align: left !important;
}
.pdf-container .table-pdf-container .cell-dark-color-background * {
  background-color: var(--table-header);
  color: var(--table-header-text);
  font-weight: 500;
}
.pdf-container .table-pdf-container .cell-light-color-background * {
  background-color: var(--table-type-light);
  font-weight: 500;
}
.pdf-container .table-pdf-container .cell-medium-color-background th,
.pdf-container .table-pdf-container .cell-light-color-background th,
.pdf-container .table-pdf-container .cell-dark-color-background th {
  font-weight: 600;
}
.pdf-container .table-pdf-container .cell-medium-color-background * {
  font-weight: 500;
  background-color: var(--table-type-medium);
}
.pdf-container .table-pdf-container .cell-pale-color-background * {
  font-weight: 500;
  background-color: var(--table-type-pale);
}
.pdf-container .table-pdf-container td {
  width: 100px;
  text-align: right;
  height: 30px;
}
.pdf-container .table-pdf-container th {
  font-weight: 400;
}
.pdf-container .table-pdf-container tbody th {
  font-size: 0.7rem;
}
.pdf-container .table-pdf-container thead .hidden-th {
  background-color: transparent;
  border: none;
}
.pdf-container tbody tr:last-child *:last-child {
  border-bottom-right-radius: 5px;
}
.pdf-container tbody tr:last-child *:first-child {
  border-bottom-left-radius: 5px;
}
.pdf-container thead tr:first-child *:last-child {
  border-top-right-radius: 5px;
}
.pdf-container thead tr:first-child *:not(.hidden-th):first-child {
  border-top-left-radius: 5px;
}
.pdf-container .table-pdf-container tbody tr:not(:last-child) * {
  border-bottom-width: 0;
}
.pdf-container .table-pdf-container tbody tr *:not(:last-child) {
  border-right-width: 0;
}

.pdf-container thead tr:first-child th.hidden-th + th {
  border-top-left-radius: 5px;
}
.pdf-container .table-pdf-container {
  border-collapse: separate;
  border-spacing: 0;
}

.pdf-container .fdc-financial-table tbody tr:first-child th:first-child {
  border-top-left-radius: 5px;
}
.pdf-container .fdc-financial-table {
  margin-bottom: 10px;
}
.pdf-container .rh-table td:not(:last-child) {
  text-align: center;
}
