.account-tools-page-container {
  display: flex;
  position: relative;
}

.tools-sidebar {
  position: sticky;
  top: 100px;
  width: 25%;
  height: min-content;
  padding: 15px;
  margin-right: 10px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 0 20px rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column;
}

.tools-title {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
}

.tools-tabs {
  list-style: none;
  padding: 0;
  width: 100%;
}

.tool-tab-container {
  position: relative;
  margin-bottom: 20px;
}

.tool-tab svg {
  margin-right: 7px;
}

.tool-tab {
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 10px;
  border: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: var(--white);
  transition: background-color 0.1s ease;
}

.tool-tab.active {
  background-color: #f3f3f3;
}

.tool-tab.disabled {
  background-color: #e2e2e2;
  color: #8b8b8b;
  cursor: not-allowed;
}

.tool-tab.disabled:hover {
  background-color: #ccc;
  color: #666;
}

.soon-tag {
  position: absolute;
  top: -10px;
  right: 5px;
  background-color: #f56e52;
  color: var(--dark-blue);
  font-size: 0.7rem;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 3px;
  text-transform: uppercase;
  z-index: 1;
}

.tool-content {
  width: 80%;
}

@media (max-width: 1200px) {
  .account-tools-page-container {
    flex-direction: column;
  }

  .tools-sidebar {
    width: 100%;
    margin-right: 0;
    position: relative;
    top: 0;
    margin-bottom: 20px;
    flex-direction: column;
    justify-content: space-between;
  }

  .tool-content {
    width: 100%;
  }
}
