.mon-profil-container > div:first-child {
  flex: 2;
}
.mon-profil-container > div:last-child .link-with-arrow:hover svg {
  --color: var(--grey) !important;
}
.mon-profil-container > div:last-child {
  flex: 1;
}
.mon-profil-container h2 {
  font-size: 1.3rem;
}
.mon-profil-container .card {
  padding: 30px;
}
.mon-profil-container {
  display: flex;
  gap: 30px;
}
.mon-profil form .form-error {
  background-color: var(--light-blue);
}
.visiting-card-profile-container {
  z-index: 2;
  top: 0px;
  padding: 10px;
  margin-bottom: -30px;
  display: flex;
  justify-content: center;
  position: sticky;
  transform: scale(0.9);
  transform-origin: top;
}
.visiting-card-buttons {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin: 30px 0;
}
.visiting-card-buttons .file-input-container {
  width: fit-content;
  padding: 0 !important;
}
.visiting-card-buttons .file-input-container .form-error {
  top: 0;
  left: 0;
  background-color: transparent;
}
.mon-profil-links {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.mon-profil-links * {
  font-family: Outfit;
font-weight: 600;
}
.profile-modal .modal {
  width: 1150px;
  max-height: 95vh;
  top: 0;
  bottom: 0;
  margin: auto;
}

@media (max-width: 1200px) {
  .profile-modal .modal {
    width: 720px;
  }
  .visiting-card-profile-container {
    transform: none;
    margin-bottom: 0;
    width: calc(100% + 60px);
    margin-left: -30px;
  }
  .mon-profil-container {
    flex-direction: column;
  }
}

@media (max-width: 992px) {
  .mon-profil-links {
    flex-direction: row;
    justify-content: space-around;
    gap: 30px;
    margin-top: -30px;
  }
  .mon-profil-responsive-buttons {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: fit-content;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
@media (max-width: 576px) {
  .mon-profil-responsive-buttons {
    width: 100%;
  }
  .mon-profil-links {
    flex-direction: column;
    gap: 10px;
  }
  .visiting-card-profile-container {
    width: calc(100% + 100px);
    margin-left: -50px;
  }
  .visiting-card-buttons {
    margin: 20px 0;
  }
}
