.nouveautes-avis-container {
  display: flex;
  flex-direction: column;
}

.nouveautes-avis-form {
  display: flex;
  flex-direction: column;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.error-message {
  color: var(--red);
  font-size: 0.875rem;
}

.success-message {
  color: green;
  text-align: center;
}
