.liste-activites-container {
  display: flex;
  gap: 40px;
  justify-content: center;
}
.liste-activites-container > div {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.activity-category {
  font-size: 1.3rem;
  color: var(--white);
  font-family: Outfit;
font-weight: 700;
  text-transform: uppercase;
}
.liste-activites-container .card-with-headers {
  height: fit-content;
  background-color: var(--white);
}

@media (max-width: 1200px) {
  .liste-activites-container .card-with-headers {
    max-width: 100% !important;
  }
}

@media (max-width: 992px) {
  .liste-activites-container {
    flex-direction: column;
  }
}

@media (max-width: 576px) {
  .liste-activites-container {
    gap: 20px;
  }
}
