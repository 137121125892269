.visiting-card-form-hr {
  border: 0;
  height: 5px;
  border-radius: 3px;
  background-color: var(--dark-blue);
  width: 50%;
  margin: 10px auto;
}
.imported-card-container {
  display: flex;
  gap: 20px;
}
.imported-card-container > * {
  max-width: calc(50% - 10px);
  object-fit: contain;
}

@media (max-width: 992px) {
  .imported-card-container {
    flex-direction: column;
  }
  .imported-card-container > * {
    max-width: 100%;
    object-fit: contain;
  }
}
