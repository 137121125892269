.timeline-title-container {
  width: 224%;
  display: flex;
  justify-content: center;
  right: 0;
  position: absolute;
  top: -42px;
}
.timeline-title {
  z-index: 2;
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
}
.timeline-title p {
  color: var(--color);
  padding: 0 15px;
  font-size: 3rem;
  font-family: Outfit;
font-weight: 700;
  background-color: var(--creamy-white);
}
.timeline-title div {
  color: var(--color);
  background-color: var(--creamy-white);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  font-size: 3.6rem;
  border: 5px solid var(--color);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1200px) {
  .timeline-title-container {
    width: 227%;
    height: 60px;
    top: -32px;
  }
  .timeline-title p {
    font-size: 2rem;
  }
  .timeline-title div {
    font-size: 2.3rem;
    height: 60px;
    width: 60px;
    font-family: Outfit;
font-weight: 600;
  }
}

@media (max-width: 576px) {
  .timeline-title-container {
    width: 231%;
  }
  .timeline-title p {
    font-size: 1.5rem;
    padding: 0 5px;
  }
  .timeline-title div {
    font-size: 1.8rem;
    height: 40px;
    width: 40px;
    border-width: 3px;
  }
}
