.pdf-container .methode-pdf-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.pdf-container .methode-pdf-number {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--secondary-color);
}
.pdf-container .methode-pdf-approach {
    color: var(--secondary-color);
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 15px;
}