.toggle-container {
  display: flex;
  position: relative;
}
.toggle-error-container .field-container {
  height: auto;
  min-height: unset;
}
.toggle-error-container .form-error {
  position: absolute;
  top: 100%;
}
.toggle-input {
  cursor: pointer;
  visibility: hidden;
  height: 0 !important;
  width: 0 !important;
}
.toggle-front {
  cursor: pointer;
  width: 35px !important;
  margin: 0 2rem;
  border: 30px red;
}
.toggle-front::before {
  width: 35px;
  height: 20px;
  background-color: var(--bg-color);
}
.toggle-input:checked + .toggle-front::before {
  background-color: var(--color);
}
.toggle-front::before,
.toggle-front::after {
  visibility: visible;
  content: "";
  border-radius: 15px;
  display: block;
}
.toggle-front::after {
  margin-top: -18px;
  margin-left: 2px;
  background-color: #fff;
  height: 16px;
  width: 16px;
  transition: margin-left 0.3s;
}
.toggle-input:checked + .toggle-front::after {
  margin-left: 17px;
}
.toggle-container label {
  cursor: pointer;
}
