.pdf-container .descriptive-text-title {
  margin: calc(var(--default-margin) * 0.3) 0;
}
.pdf-container .descriptive-text-content {
  position: relative;
  right: -25px;
  margin-bottom: 10px;
  display: flex;
}
.pdf-container .descriptive-text-content:before {
  content: "";
  -webkit-mask-image: var(--dot);
  mask-image: var(--dot);
  fill: var(--secondary-color);
  position: absolute;
  height: 14px;
  width: 14px;
  background-color: var(--secondary-color);
  background-repeat: no-repeat;
  left: -21px;
  top: 6px;
}
.pdf-container .descriptive-text-content span {
  display: inline-block;
}
.pdf-container .normal-bold-texts-container-pdf {
  margin-top: var(--default-margin);
  display: flex;
  flex-direction: column;
  gap: calc(var(--default-margin) * 0.3);
}
