.tab-content-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.tab-content-header h2 {
  margin: 0;
  font-weight: 600;
  font-size: 1.7rem;
  display: flex;
  align-items: center;
  gap: 10px;
}

.tab-content-header h2 .tuto-icon {
  margin-left: 15px;
}

.tab-content-header p {
  margin-top: 10px;
  font-size: 1.1rem;
  font-weight: Outfit-Medium;
  color: var(--grey);
}

.folder-deliverable-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
}

.deliverables-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
}

.deliverable-item {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.deliverable-item-head {
  cursor: pointer !important;
  display: flex;
  align-items: center;
  position: relative;
  width: calc(100% + 40px);
  margin: -20px;
  padding: 20px;
}
.deliverable-name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.deliverable-name h3 {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  gap: 10px;
}

.deliverable-actions-buttons {
  width: fit-content;
  height: 30px;
  margin-right: 30px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.dropdown-icon-deliverables {
  cursor: pointer;
  transition: transform 0.3s;
}

.deliverable-actions-buttons .button {
  --color: var(--dark-blue) !important;
  --font-size: 0.8rem !important;
  --padding: 3px 5px !important;
  --border-radius: 5px !important;
}

.estimation-item {
  position: relative;
  padding: 10px;
  margin-bottom: 10px;
  background-color: var(--dark-blue);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.estimation-item p {
  margin: 0;
  color: var(--white);
}

.estimation-dropdown {
  position: relative;
}

.financingplan-item {
  position: relative;
  padding: 10px;
  margin-bottom: 10px;
  background-color: var(--dark-blue);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.financingplan-item p {
  margin: 0;
  color: var(--white);
}

.financingplan-dropdown {
  position: relative;
}

.dropdown-icon-financingplans {
  cursor: pointer;
  margin-right: 30px;
}

.dropdown-menu-deliverables {
  position: absolute;
  top: 35px;
  right: -40px;
  width: max-content;
  overflow: hidden;
  max-height: 0;
  background-color: var(--white);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  list-style: none;
  z-index: 1000;
  transition:
    max-height 0.3s,
    padding 0.3s;
  padding: 0 20px;
  display: block;
}

.dropdown-menu-deliverables.show {
  padding: 20px;
  max-height: fit-content;
}

.dropdown-menu-deliverables li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 8px;
}

.dropdown-menu-deliverables li:last-child {
  margin-bottom: 0;
}

.dropdown-menu-deliverables li button,
.dropdown-menu-deliverables li a {
  background-color: transparent;
  border: none;
  color: var(--dark-blue);
  cursor: pointer;
  transition: color 0.2s ease;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0;
}

.dropdown-menu-deliverables li button:hover,
.dropdown-menu-deliverables li a:hover {
  color: var(--blue);
}

.dropdown-menu {
  position: absolute;
  top: 25px;
  right: 0;
  width: max-content;
  overflow: hidden;
  max-height: 0;
  background-color: var(--white);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  list-style: none;
  z-index: 1000;
  transition:
    max-height 0.3s,
    padding 0.3s;
  padding: 0 20px;
  display: block;
}

.dropdown-menu.show {
  padding: 20px;
  max-height: fit-content;
}

.dropdown-menu li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 8px;
}

.dropdown-menu li:last-child {
  margin-bottom: 0;
}

.dropdown-menu li button,
.dropdown-menu li a {
  background-color: transparent;
  border: none;
  color: var(--dark-blue);
  cursor: pointer;
  transition: color 0.2s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0;
}

.dropdown-menu li button:hover,
.dropdown-menu li a:hover {
  color: var(--blue);
}

.deliverable-content-header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.deliverable-estimations-actions-button {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
}

.deliverable-financingplans-actions-button {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
}

.info-block-deliverable {
  display: flex;
  justify-content: center;
}

@media (max-width: 576px) {
  .deliverable-name {
    justify-content: space-between;
  }
  .deliverable-item-head {
    gap: 10px;
    flex-direction: column;
  }
  .deliverable-actions-buttons > .button {
    width: calc(50% - 10px);
  }
  .deliverable-actions-buttons {
    flex-wrap: wrap;
  }
}
