.container .necessary-cookies {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  max-width: 943px;
}
.container .necessary-cookies .text-necessary-cookies,
.container .necessary-cookies .radio-group-necessary-cookies {
  width: 50%;
  box-sizing: border-box;
}

.text-necessary-cookies p {
  max-width: 200px !important;
}

.container .radio-group-necessary-cookies {
  max-width: 200px;
  display: flex;
  justify-content: flex-end;
  margin-left: 160px;
}

.hr-cookie {
  height: 3px;
  background-image: linear-gradient(to right, transparent, var(--gold) 25%, var(--gold) 75%, transparent);
  border-radius: 2px;
  border: none;
  margin-bottom: -10px;
  margin-top: 30px;
}

.validate-form-cookie-button {
  --padding: 10px 25px !important;
  --font-size: 1.3rem !important;

  margin: 25px auto;
  width: fit-content;
}
@media (max-width: 768px) {
  .container .necessary-cookies {
    flex-direction: row;
    align-items: center;
    max-width: 700px;
  }
  .container .radio-group-necessary-cookies .radio-group {
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-left: 20px;
  }
  .container .radio-group-necessary-cookies .radio-group > div {
    width: 140px;
  }
}

@media (max-width: 576px) {
  .container .necessary-cookies {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 300px;
    margin: auto;
  }
  .container .necessary-cookies .text-necessary-cookies,
  .container .necessary-cookies .radio-group-necessary-cookies {
    width: 100%;
    padding: 0 10px;
    align-items: center;
    text-align: center;
  }
  .container .radio-group-necessary-cookies .radio-group {
    margin-left: 5px;
  }
}
