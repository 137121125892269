.confirm-inscription-inputs-container {
  display: flex;
  justify-content: center;
  gap: 3px;
  margin: 15px auto;
  position: relative;
  width: fit-content;
}
.confirm-inscription-inputs-container .field-container,
.confirm-inscription-inputs-container .w-100 {
  width: 40px !important;
  gap: 0;
}
.confirm-inscription-inputs-container input {
  padding: 0 5px;
  width: 20px;
}
.confirm-inscription-inputs-container.with-error input {
  border-color: var(--red);
}
.confirm-inscription-inputs-container .form-error {
  width: calc(100% - 30px);
  bottom: 1px;
  left: 16px;
  top: unset;
  justify-content: center;
}
.confirm-inscription-inputs-container .form-error::before {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
