.parcours-starter-container {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.parcours-card {
  flex: 1;
  padding: 50px;
  text-align: center;
  background-color: #ffffff;
  border-radius: 8px;
  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease;
  box-sizing: border-box;
}

.parcours-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  height: 100%;
  justify-content: space-between;
}

.parcours-card h3 {
  font-size: 1.3rem;
  color: #000000;
  margin-top: auto;
}

.parcours-card p {
  font-size: 0.9rem;
  color: #000000;
  margin-bottom: auto;
}

.parcours-card button {
  margin-top: 20px;
}

.parcours-card:hover {
  box-shadow: inset 0 0 0 3px var(--sky-blue);
}

.parcours-starter-button {
  display: flex;
  justify-content: center;
}

.carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  position: relative;
}

.carousel-bracket-right {
  position: absolute;
  top: 40%;
  right: 0;
  cursor: pointer;
}

.carousel-bracket-left {
  position: absolute;
  top: 40%;
  left: 0;
  cursor: pointer;
}

.carousel-bracket.d-none {
  display: none;
}

.carousel-parcours-indicators {
  width: 100%;
  display: flex;
  justify-content: center;
}

.carousel-parcours-indicators span {
  color: var(--sky-blue);
  text-decoration: underline var(--sky-blue);
}
