.review-container {
  width: 270px;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: none;
}
.review-container div {
  margin-bottom: 10px;
}
.reviews-row {
  --margin: 0 auto;

  height: 326px;
  max-width: calc(290px * 3);
}
.reviews-row .components-slider {
  position: absolute;
  right: unset;
  left: calc(var(--slide) * -1);
  transition: left 0.3s;
}
.reviews-row .components-row {
  overflow: unset;
  position: relative;
}
.slider-height {
  height: 275px;
  width: 0;
}
.slider-hide {
  width: 100%;
}
.reviews-row .formations-row-bracket {
  left: -80px;
}
.reviews-row .formations-row-bracket:last-of-type {
  right: -80px;
}
.slider-mask {
  height: 100%;
  position: absolute;
  top: 0;
  width: calc((100vw - 100%) / 2);
  left: calc((100vw - 100%) / -2);
  background: linear-gradient(to right, var(--creamy-white), transparent);
}
.slider-mask:last-of-type {
  right: calc((100vw - 100%) / -2);
  background: linear-gradient(to left, var(--creamy-white), transparent);
  left: unset;
}
.review-link {
  font-family: Outfit;
font-weight: 600;
  text-decoration: underline;
  display: block;
}

.review-modal-title {
  width: 100%;
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
}

.review-modal-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
}

.review-modal-content p {
  text-align: justify;
}