.folders-button {
  margin: 0;
}

.folders-container {
  background-color: var(--white) !important;
  box-shadow: 0 0 20px rgb(0 0 0 / 10%);
  border-radius: 15px;
  padding: 20px 30px;
  margin-top: 20px;
}
.folders-container-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.folders-container-header p {
  font-size: 1.3rem;
  display: inline;
  cursor: pointer;
}

.folder-icon-tuto {
  margin-left: 25px;
}

.folders-container-header p.active {
  font-weight: 700;
  text-decoration: underline;
}

.folders-container-header p:first-child {
  margin-right: 50px;
}

.folders-search-bar {
  margin: 15px 0;
  border: 1px solid var(--light-grey);
  border-radius: 10px;
}

.folders-filters {
  margin-bottom: -5px;
  margin-right: 5px;
}

.folders-filters,
.folder-overview-container {
  display: grid;
  gap: 10px;
  padding: 0 10px;
  grid-template-columns: 40px 3fr 100px 70px;
  grid-template-rows: 100%;
  position: relative;
}

.folders-filters-buttons:hover {
  background-color: var(--dark-blue) !important;
}

.folders-filters-buttons {
  background-color: var(--dark-blue);
  transition: background-color 0.3s;
  margin: auto 0;
  width: fit-content;
  padding: 7px;
  padding-top: 8px;
  padding: 8px 8px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  position: absolute;
  right: 15px;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.folder-overview-container:hover .item-copy,
.folder-overview-container:hover .folders-filters-buttons {
  background-color: var(--dark-blue-alt);
}

.folders-filters .centered:before {
  content: attr(custom-title);
  height: 0;
  display: block;
  padding: 0 8px;
  font-size: 0.8rem;
  overflow: hidden;
  visibility: hidden;
}

.folders-filters div {
  display: flex;
  align-items: center;
  font-weight: 700;
}

.folders-table-body {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 300px;
}

.folders-table-body::-webkit-scrollbar {
  width: 10px;
}

.folder-overview-container {
  background-color: var(--dark-blue);
  border-radius: 10px;
  padding: 10px;
  margin-right: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.folder-overview-container:hover {
  background-color: var(--dark-blue-alt);
}

.folder-overview-container div {
  color: var(--white);
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  max-height: 30px;
  cursor: pointer;
}

.folder-thumbnail-container {
  width: 30vw;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px;
}

.folder-thumbnail {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--white);
  box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.folder-name {
  color: var(--white);
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  background-color: var(--dark-blue);
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.folder-infos {
  text-align: center;
  margin-top: 20px;
}

.folder-infos p {
  margin-bottom: 5px;
}

.folder-infos .label {
  margin-bottom: 0;
  font-size: 1.1rem;
  font-weight: 700;
}

.folder-buttons {
  display: flex;
  justify-content: space-between;
  margin: auto 25px 20px;
  padding-top: 20px;
}

.folder-buttons .button {
  --padding: 8px !important;
  margin: 0 auto 5px;
}

.folder-buttons a,
.folder-buttons div {
  text-align: center;
  font-size: 0.8rem;
}

.folder-thumbnail .fake-button {
  padding: 8px;
  width: fit-content;
  margin: 0 auto 5px;
  border-radius: 10px;
}

.folder-thumbnail .fake-button div {
  left: -100%;
  top: -32px;
  bottom: unset;
}

.folder-overview-container .fake-button,
.folder-thumbnail .fake-button {
  background-color: var(--light-grey);
  color: var(--grey);
  border-radius: 5px;
  font-size: 0.8rem;
  border: 3px solid var(--light-grey);
  padding: 3px 5px;
  cursor: not-allowed;
}

.fake-button div {
  display: flex;
  align-items: center;
  color: var(--dark-blue);
  max-height: 0;
  overflow: hidden;
  position: absolute;
  transition:
    max-height 0.2s,
    padding 0.2s;
  background-color: var(--red);
  border-radius: 5px;
  bottom: 15px;
  padding: 0 11px;
  z-index: 3;
  width: max-content;
  left: -50%;
}

.fake-button:hover div {
  max-height: 30px;
  padding: 10px;
}

.tuto-folders-modal-button {
  width: 100%;
  display: flex;
  justify-content: center;
}

.dropdown-icon-folder {
  cursor: pointer;
  transition: transform 0.3s;
}

.dropdown-menu-folder {
  position: absolute;
  top: 22px;
  right: -25px;
  width: max-content;
  overflow: hidden;
  max-height: 0;
  background-color: var(--white);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  list-style: none;
  z-index: 1000;
  transition:
    max-height 0.3s,
    padding 0.3s;
  padding: 0 20px;
  display: block;
}

.dropdown-menu-folder.show {
  padding: 20px;
  max-height: 160px;
}

.dropdown-menu-folder li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 8px;
}

.dropdown-menu-folder li:last-child {
  margin-bottom: 0;
}

.dropdown-menu-folder li button,
.dropdown-menu-folder li a {
  background-color: transparent;
  border: none;
  color: var(--dark-blue);
  cursor: pointer;
  transition: color 0.2s ease;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0;
}

.dropdown-menu-folder li button:hover,
.dropdown-menu-folder li a:hover {
  color: var(--blue);
}

@media (max-width: 1200px) {
  .folders-button {
    margin: 0 auto;
  }
  .folders-container {
    background-color: unset;
  }
  .folders-container-header {
    text-align: center;
  }
  .folders-search-bar {
    max-width: 70%;
    margin: 15px auto -15px;
    width: fit-content;
  }
  .folders-search-bar input {
    max-width: 100%;
  }
  .folders-search-bar.search-bar-container .searchbar-cross {
    right: 14px;
  }
  .folder-overview-container .fake-button,
  .folder-thumbnail .fake-button {
    border-radius: 10px;
    padding: 8px;
  }
}

@media (max-width: 992px) {
  .folder-thumbnail-container {
    width: 45vw;
    margin: 20px 10px;
  }
  .folder-thumbnail svg {
    width: 20px;
    height: 20px;
  }
  .folder-buttons div,
  .folder-buttons a {
    font-size: 0.8rem;
  }
}

@media (max-width: 576px) {
  .folder-thumbnail-container {
    width: 80vw;
  }
  .folders-container {
    margin-top: 20px;
    padding: 20px 0 0;
  }
  .folders-container-header p {
    font-size: 1rem;
  }
  .folders-container-header p:first-child {
    margin-right: 20px;
  }
  .folders-search-bar {
    max-width: 100%;
    width: 100%;
  }
}
