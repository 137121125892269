.card-img {
  max-width: 35%;
  object-fit: contain;
  width: auto;
  max-height: 130px;
}
.visiting-card-container {
  max-width: 700px;
  width: 100%;
  border: solid 1px var(--dark-blue);
  background-color: white;
  border-radius: 15px;
  padding: 20px;
  z-index: 2;
}
.visiting-card-container * {
  line-height: 1.4;
  font-weight: 300;
  color: var(--black);
}
.card-top,
.card-bottom {
  display: flex;
}
.card-bottom {
  justify-content: flex-end;
}
.card-top > div,
.card-bottom > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.visiting-card-container svg {
  --color: var(--sky-blue) !important;

  width: 20px;
  height: 20px;
}
.card-mid {
  margin: 20px 0 5px;
}
.card-mid-content {
  display: flex;
}
.card-name span {
  font-family: Outfit;
  font-weight: 700;
  font-size: 2.2rem;
}
.card-position {
  font-size: 1.3rem;
  font-family: Outfit;
  font-weight: 600;
}
.card-slogan {
  font-size: 1.3rem;
  font-family: Outfit;
  font-weight: 300;
}
.card-bottom * {
  text-align: right;
}
.card-mid-content p {
  margin-bottom: 3px;
}
.card-mid-content svg,
.card-mid-content img {
  margin-top: 2px;
  width: 15px;
  margin-right: 10px;
}
.visiting-card-container .card-top img {
  margin-right: 20px;
}
.visiting-card-container .card-bottom img {
  margin-left: 20px;
}
.visiting-card-container .card-company {
  font-size: 1.8rem;
  font-family: Outfit;
  font-weight: 700;
}

@media (max-width: 576px) {
  .visiting-card-container {
    padding: 10px;
  }
  .visiting-card-container * {
    font-size: 0.8rem;
  }
  .visiting-card-container .card-mid-content svg,
  .visiting-card-container .card-mid-content img,
  .visiting-card-container .card-top img {
    margin-right: 5px;
  }

  .visiting-card-container .card-bottom img {
    margin-left: 5px;
  }
  .card-name span {
    font-size: 1.1rem;
  }
  .card-img {
    max-height: 70px;
  }
}
