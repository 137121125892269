.items-button {
  margin: 0;
}
.head-row-container {
  display: flex;
  justify-content: space-between;
  align-items: end;
}
.items-container {
  background-color: var(--white);
  box-shadow: 0 0 20px rgb(0 0 0 / 10%);
  border-radius: 15px;
  padding: 20px 30px;
  margin-top: 20px;
}
.items-container-header p {
  font-size: 1.3rem;
  display: inline;
  cursor: pointer;
}
.items-container-header p:not(.active) {
  color: var(--grey);
}
.items-container-header p.active {
  font-family: Outfit;
  font-weight: 700;
  text-decoration: underline;
}
.items-container-header p:first-child {
  margin-right: 50px;
}
.items-search-bar {
  border: 1px solid var(--light-grey);
  border-radius: 10px;
  margin: 15px 0;
}
.items-filters {
  padding: 0 10px 10px;
  margin-right: 20px;
}
.items-filters,
.item-overview-container {
  display: grid;
  grid-template-rows: 100%;
}
.estimations-table .items-filters,
.estimations-table .item-overview-container {
  display: grid;
  grid-template-columns: 40px 5fr 85px 4fr 100px 30px 70px;
  grid-template-rows: 100%;
}

.estimations-ref-header {
  margin-left: 8px;
}
.financing-plans-table .items-filters,
.financing-plans-table .item-overview-container {
  grid-template-columns: 40px 5fr 85px 4fr 100px 30px 70px;
}

.credit-simulators-table .items-filters,
.credit-simulators-table .item-overview-container {
  grid-template-columns: 40px 3fr 100px 30px 70px;
}
.item-copy {
  background-color: var(--dark-blue);
  transition: background-color 0.3s;
  margin: auto 0;
  width: fit-content;
  padding: 7px 9px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  margin: -7px 0;
}
.items-filters .items-filters-buttons {
  margin-bottom: 10px;
}
.item-filters-buttons:hover, .item-copy:hover {
  background-color: var(--dark-blue) !important;
}
.item-filters-buttons {
  background-color: var(--dark-blue);
  transition: background-color 0.3s;
  margin: auto 0;
  width: fit-content;
  padding: 7px;
  padding-top: 8px;
  padding: 8px 8px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  position: absolute;
  right: 15px;
  top: 0;
  bottom: 0;
  cursor: pointer;
}
.estimation-type-fonds {
  color: #b9bafd !important;
}
.estimation-type-titres {
  color: #cac8c8 !important;
}
.estimation-type-murs {
  color: var(--light-gold) !important;
}
.estimation-type-fonds * {
  --color: #b9bafd !important;
}
.estimation-type-titres * {
  --color: #cac8c8 !important;
}
.estimation-type-murs * {
  --color: var(--light-gold) !important;
}
.estimation-type-fonds::before,
.estimation-type-titres::before,
.estimation-type-murs::before {
  content: "";
  position: absolute;
  top: -3px;
  border-radius: 4px;
  height: calc(100% + 6px);
  z-index: -1;
  width: 100%;
  left: 0;
}
.estimation-type-fonds,
.estimation-type-titres,
.estimation-type-murs {
  border-radius: 4px;
  margin: auto 0;
  width: 73px;
  height: fit-content;
  position: relative;
  z-index: 2;
}
.estimation-validated {
  position: relative;
  padding: 0 4px;
  z-index: 2;
  top: 1px;
  margin: 0 auto;
}
.estimation-validated::before {
  content: "";
  border-radius: 50%;
  position: absolute;
  top: -3px;
  background-color: var(--gold);
  left: -1px;
  width: 21px;
  z-index: -1;
  height: calc(100% + 4px);
}
.estimation-not-validated {
  position: relative;
  margin-top: -4px;
  max-height: 16px !important;
  margin-bottom: -3px;
  margin: 0 auto;
}
.item-filters-buttons .button {
  --color: var(--dark-blue) !important;
  --font-size: 0.8rem !important;
  --padding: 3px 5px !important;
  --border-radius: 5px !important;
}
.items-filters .centered:before {
  content: attr(custom-title);
  height: 0;
  display: block;
  padding: 0 8px;
  font-size: 0.8rem;
  overflow: hidden;
  visibility: hidden;
}
.items-filters div {
  display: flex;
  align-items: center;
  font-family: Outfit;
  font-weight: 700;
}
.items-filters div:last-child {
  justify-content: flex-end;
}
.items-table {
  position: relative;
}
.items-table-body {
  position: relative;
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 300px;
  overflow-y: scroll;
}
.items-table-body::-webkit-scrollbar {
  width: 10px;
}
.item-overview-container {
  background-color: var(--dark-blue);
  border-radius: 10px;
  padding: 10px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
}

.item-overview-container:hover,
.item-overview-container:hover .item-filters-buttons,
.item-overview-container:hover .item-copy {
  background-color: var(--dark-blue-alt);
  transition: background-color 0.3s;
}

.item-overview-container div {
  color: var(--white);
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  max-height: 30px;
  text-overflow: ellipsis;
  cursor: pointer;
}
.item-thumbnail-container {
  width: 30vw;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px;
}
.item-thumbnail {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--white);
  box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.item-enseigne {
  color: var(--white);
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  background-color: var(--dark-blue);
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}
.item-infos {
  text-align: center;
  margin-top: 20px;
}
.item-infos p {
  margin-bottom: 5px;
}
.item-infos .label {
  margin-bottom: 0;
  font-size: 1.1rem;
  font-family: Outfit;
  font-weight: 700;
}
.item-buttons {
  display: flex;
  justify-content: space-between;
  margin: 0 25px 20px;
  padding-top: 20px;
}
.item-buttons .button {
  --padding: 8px !important;
  margin: 0 auto 5px;
}
.item-buttons a,
.item-buttons div {
  text-align: center;
  font-size: 0.8rem;
}

.dropdown-icon-item {
  cursor: pointer;
  transition: transform 0.3s;
}
.dropdown-menu-item.show {
  top: var(--top);
  transition:
    max-height 0.3s,
    padding 0.3s;
  max-height: 190px;
  overflow: visible !important;
  padding: 10px 20px;
}

.dropdown-menu-item li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 8px;
}

.dropdown-menu-item li:last-child {
  margin-bottom: 0;
}

.dropdown-menu-item li button,
.dropdown-menu-item li a {
  background-color: transparent;
  all: unset;
  border: none;
  color: var(--dark-blue);
  cursor: pointer;
  transition: color 0.2s ease;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0;
}

.dropdown-menu-item li button:hover svg,
.dropdown-menu-item li a:hover svg {
  --color: var(--blue) !important;
}
.dropdown-menu-item li button:hover,
.dropdown-menu-item li a:hover {
  color: var(--blue);
}

.disabled-button-dropdown-item {
  cursor: default !important;
  color: var(--light-grey) !important;
}

.disabled-button-dropdown-item:hover {
  color: var(--light-grey) !important;
}

.tooltip-item {
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: var(--dark-blue);
  font-weight: 500;
}

.tooltip-item::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: calc(100% + 15px); /* permet de décaler le tooltip de 10px par rapport au bas de l'élément */
  left: 50%;
  transform: translateX(-60%);
  background-color: var(--red);
  color: #fff;
  padding: 8px 12px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s;
  z-index: 1500;
  pointer-events: none;
  font-size: 0.875rem;
}

.tools-loader {
  position: relative;
  width: 100%;
  background-color: white;
  padding: 30px 0;
  height: 100%;
}

.tooltip-item::before {
  content: "";
  position: absolute;
  bottom: 115%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.75) transparent transparent transparent;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s;
  z-index: 1001;
}

.tooltip-item:hover::after,
.tooltip-item:hover::before {
  opacity: 1;
  visibility: visible;
}
.dropdown-menu-item {
  position: absolute;
  top: 22px;
  right: 0px;
  width: max-content;
  max-height: 0;
  background-color: var(--white);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  list-style: none;
  z-index: 1000;
  padding: 0 20px;
  display: block;
  overflow: hidden;
}

/* Financing plan only */
.financing-plan-link-icon {
  position: absolute;
  height: 30px;
  width: 30px;
  padding-top: 1px;
  border-radius: 50%;
  margin: auto;
  left: -7px;
  align-items: center;
  justify-content: center;
  right: 0;
}
.financing-plan-link-icon-container {
  margin-left: 5px;
  position: relative;
  display: flex;
  width: 16px;
  align-items: center;
  justify-content: center;
}
.financing-plan-link-icon-container:hover .financing-plan-link-icon {
  background-color: var(--dark-blue);
}
.financing-plan-link-icon-container:hover .financing-plan-link-tooltip {
  opacity: 1;
  visibility: visible;
}
.financing-plan-link-tooltip {
  position: absolute;
  top: -7px;
  right: 35px;
  background-color: var(--blue);
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s;
  z-index: 1000;
}

@media (max-width: 1200px) {
  .estimation-thumbnail-previsu-button {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: auto;
  }
  .items-button {
    margin: 0 auto;
  }
  .items-container {
    background-color: unset;
  }
  .items-container-header {
    text-align: center;
  }
  .items-search-bar {
    max-width: 70%;
    margin: 15px auto -15px;
    width: fit-content;
  }
  .items-search-bar input {
    max-width: 100%;
  }
  .items-search-bar.search-bar-container .searchbar-cross {
    right: 14px;
  }
  .item-overview-container .fake-button,
  .item-thumbnail .fake-button {
    border-radius: 10px;
    width: fit-content;
    border: solid transparent 3px;
    margin: auto;
    margin-bottom: 5px;
    padding: 8px;
  }
}

@media (max-width: 992px) {
  .item-thumbnail-container {
    width: 45vw;
    margin: 20px 10px;
  }
  .item-thumbnail svg {
    width: 20px;
    height: 20px;
  }
  .item-buttons div,
  .item-buttons a {
    font-size: 0.8rem;
  }
}

@media (max-width: 576px) {
  .head-row-container {
    flex-direction: column;
    gap: 10px;
  }
  .head-row-container > * {
    width: 100%;
    justify-content: center;
  }
  .item-thumbnail-container {
    width: 80vw;
  }
  .items-container {
    margin-top: 20px;
    padding: 20px 0 0;
  }
  .items-container-header p {
    font-size: 1rem;
  }
  .items-container-header p:first-child {
    margin-right: 20px;
  }
  .items-search-bar {
    max-width: 100%;
    width: 100%;
  }
}
