.article-main-picture {
  margin: auto;
  margin-top: 30px;
  max-height: 200px;
  border-radius: 10px;
  object-fit: cover;
  width: 80%;
  display: block;
}
.article-title {
  max-width: 80%;
  margin: auto;
}
.article-nav li {
  scroll-behavior: smooth;
}
.conseils-pros-container {
  width: 100%;
}
.article-container:first-child:before {
  content: "";
  position: absolute;
  top: -30px;
  left: -30px;
  width: calc(100% + 60px);
  height: calc(100% + 60px);
  background-color: var(--white);
  filter: blur(10px);
  z-index: -1;
}
.article-container {
  position: relative;
  margin: 20px auto;
  max-width: 70ch;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.article-aside {
  width: 25%;
  margin: 20px 0;
  padding: 10px;
  height: fit-content;
  border: 2px solid var(--gold);
  border-radius: 8px;
  position: sticky;
  top: 30px;
  scroll-behavior: smooth;
}
.article-aside h4 {
  font-size: 1.2rem;
  margin-bottom: 20px;
}
.article-aside li {
  padding-left: 15px;
  position: relative;
}
.article-aside li a {
  text-overflow: ellipsis;
  max-width: calc(100% - 10px);
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.article-aside li:after {
  content: "";
  height: 0;
  width: 3px;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 4px;
  background-color: var(--dark-blue);
  transition: height 0.3s;
}
.article-aside li.active:after {
  height: calc(100% - 8px);
}
.article-aside {
  max-height: calc(100vh - 60px);
  overflow-y: scroll;
}
.article-aside::-webkit-scrollbar-track {
  margin: 3px 0;
}
.article-aside::-webkit-scrollbar {
  width: 5px;
}
.outil-cta-slider {
  position: fixed;
  bottom: 20px;
  right: -370px;
  z-index: 10000;
  width: 350px;
  opacity: 1;
  transition:
    right 1s,
    opacity 0.3s;
}
.outil-cta-container {
  padding: 30px;
  background-color: var(--dark-blue);
  border-radius: 15px;
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.25);
}
.outil-cta-close {
  position: absolute;
  right: 15px;
  top: 15px;
}
.outil-cta-close:hover {
  --color: var(--sky-blue) !important;
}
.outil-cta-container p,
.outil-cta-container span {
  color: var(--white);
}
.article-aside li:hover,
.article-aside li.active a {
  text-shadow: 0 0 0.7px var(--blue);
}
.estimation-cta {
  border-radius: 10px;
  background: linear-gradient(180deg, var(--light-blue) 0%, #f1e9d0 100%);
  padding: 40px;
  margin: 0 auto;
  gap: 20px;
  display: flex;
}
.estimation-cta .estimation-cta-img-container img {
  max-height: 170px;
  border-radius: 0;
  box-shadow: 0px 4px 12px 8px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: box-shadow 0.3s;
}

.estimation-cta-container .mx-auto span {
  font-family: Outfit;
  font-weight: 700;
}
.estimation-cta .mx-auto span {
  font-weight: 700;
}
.estimation-cta-img-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.estimation-cta-img-container div {
  position: absolute;
}
.estimation-cta-img-container:hover img {
  box-shadow: 0px 5px 13px 9px rgba(0, 0, 0, 0.35);
}
.cta-title {
  font-size: 1.2rem;
}
.article-top-scroller .top-scroller::before,
.article-top-scroller .top-scroller::after {
  width: 25px;
}
.top-scroller-container {
  position: sticky;
  top: calc(100vh - 100px);
}
.lead-magnet-preview {
  width: 100%;
}
.top-scroller {
  position: sticky;
  bottom: 50px;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-color: var(--blue);
  cursor: pointer;
  margin: auto;
  transition: transform 0.3s;
}
.top-scroller:hover {
  transform: translateY(-5px);
}
.top-scroller:before,
.top-scroller:after {
  content: "";
  position: absolute;
  top: 47%;
  left: 61.5%;
  width: 20px;
  height: 5px;
  border-radius: 2px;
  background-color: var(--white);
  transform: translate(-50%, -50%) rotate(45deg);
}
.top-scroller:after {
  left: 38.5%;
  transform: translate(-50%, -50%) rotate(-45deg);
}
.aside-container {
  width: 25%;
}
.aside-container aside {
  margin-bottom: 80px;
  width: 100%;
}

@media (max-width: 1200px) {
  .article-aside,
  .aside-container {
    display: none;
  }
}

@media (max-width: 768px) {
  .article-main-picture,
  .article-title {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .estimation-cta {
    flex-direction: column;
    padding: 30px;
  }
  .article-container {
    overflow: hidden;
    margin: 15px;
  }
  .cta-title {
    font-size: 1rem;
  }
}

/* CSS article */

.article-container ul {
  padding-left: 30px;
}
.article-container a:not(.button):hover {
  text-decoration: underline;
  color: var(--blue);
}
.article-container a:not(.button) {
  color: var(--link-blue);
}
.article-container .bold {
  font-family: Outfit;
  font-weight: 700;
}
.article-button {
  --border-radius: 10px;
  --background-color: var(--blue);
  --hover-color: var(--blue);
  --color: var(--white);
  --padding: 10px 20px;
  --font-size: 1.3rem;

  max-width: 80%;
  margin: 0 auto;
}
.article-container img {
  max-width: 100%;
  max-height: 300px;
  margin: 0 auto;
  border-radius: 6px;
}
.hr-article {
  height: 4px;
  background-image: linear-gradient(to right, var(--gold), transparent);
  border-radius: 2px;
  border: none;
  margin-bottom: -10px;
  margin-top: 30px;
}
.article-container h2 {
  text-transform: uppercase;
  font-size: 1.8rem;
  font-family: Outfit;
  font-weight: 700;
  margin-bottom: 20px;
}
.max-width-auto,
.maxWidthAuto {
  max-width: 100% !important;
  max-height: 100% !important;
}
.max-height-auto,
.maxHeightAuto {
  width: auto !important;
  max-width: 700px !important;
}

@media only screen and (max-width: 650px) {
  .max-height-icon-auto {
    max-width: 150px;
  }
  .max-height-auto {
    width: auto;
    max-width: 350px;
  }
  .article-container h2 {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 900px) {
  .max-height-icon-auto {
    max-width: 250px;
  }
  .max-height-auto {
    width: auto;
    max-width: 500px;
  }
  .article-button {
    max-width: 100%;
  }
}
