.progressison-meter-container {
  display: flex;
  justify-content: center;
  position: relative;
}
.progression-percentage {
  position: absolute;
  font-size: 1.3rem;
  bottom: 18px;
  color: var(--white);
  font-family: Outfit;
font-weight: 700;
}

@media (max-width: 1200px) and (min-width: 576px) {
  .progressison-meter-container svg {
    width: 500px;
  }
  .progression-percentage {
    font-size: 2.2rem;
    bottom: 30px;
  }
}
