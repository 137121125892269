.customisation-preview-container.page-2 {
  display: none;
}
.customisation-preview-container {
  aspect-ratio: 1/1.4;
  position: relative;
  height: 100%;
  flex-basis: 50%;
  border: 1px solid var(--grey);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.customisation-pdf-container {
  display: flex;
  gap: 20px;
  padding: 10px 0;
}
.banner-color-logo img {
  height: 23px;
  max-width: 220px;
  object-fit: contain;
}
.banner-color-logo {
  width: 100%;
  background-color: var(--background-color);
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner-color-logo * {
  color: var(--text-color);
  font-weight: 300;
  font-size: 0.7rem;
}
.banner-color-logo:last-child {
  justify-content: space-between;
  padding: 0 13px;
}
.customisation-pdf-buttons {
  flex-basis: 50%;
  display: flex;
  padding-right: 25px;
  flex-direction: column;
  align-items: center;
}
.reset-customisation-text {
  color: var(--dark-red);
  margin-top: 15px;
  cursor: pointer;
  text-decoration: underline;
}
.customisation-preview-container > p {
  margin-top: -150px;
  font-weight: 600;
  font-size: 1.6rem;
}
.cover-logo-preview {
  margin-top: -100px;
  height: 50px;
  width: 250px;
  object-fit: contain;
}
.customisation-pdf-container .react-colorful {
  width: 100%;
}
.reset-customisation-button {
  position: relative;
  cursor: pointer;
  top: -78px;
  margin: 0 -12px -35px auto;
  background-color: var(--red);
  width: 35px;
  height: 35px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s;
}
.reset-customisation-button:hover {
  transform: scale(1.1);
}
.hex-color-container {
  display: flex;
  gap: 5px;
  align-items: center;
  margin: 10px 0;
  width: 100%;
}
.customisation-pdf-buttons input {
  width: 100%;
  padding: 3px 5px;
  border-radius: 10px;
  border: 2px solid var(--blue);
}
.rgba-inputs-container {
  display: flex;
  gap: 5px;
  align-items: center;
  width: 100%;
}
.customisation-preview-container .customisation-pagination {
  display: none;
}

@media (min-width: 1200px) {
  .customisation-preview-container,
  .customisation-pdf-buttons {
    flex-basis: 33%;
  }
  .customisation-preview-container.page-2 {
    display: flex;
  }
  .customisation-preview-container:not(.page-2) .banner-color-logo p {
    display: none;
  }
  .customisation-preview-container.page-2 .banner-color-logo:last-of-type {
    padding: 0 15px;
    justify-content: space-between;
  }
  .customisation-preview-container:not(.page-2) .banner-color-logo:first-child {
    display: none;
  }
  .customisation-preview-container .customisation-pagination {
    position: absolute;
    display: block;
    justify-content: center;
    bottom: -25px;
    font-weight: 300;
    font-style: italic;
    font-size: 0.9rem;
  }
  .cover-logo-preview {
    margin-top: 120px;
  }
  .reset-customisation-text {
    position: relative;
    top: 10px;
  }
  .customisation-preview-container svg {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .customisation-preview-container {
    display: none;
  }  .customisation-pdf-buttons {
    flex-basis: 100%;
  }
}
