.tab-content-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.tab-content-header h2 {
  margin: 0;
  font-weight: 600;
  font-size: 1.7rem;
  display: flex;
  align-items: center;
}

.tab-content-header h2 .tuto-icon {
  margin-left: 15px;
}

.tab-content-header p {
  margin-top: 10px;
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--grey);
}

.folder-plan-buttons {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 20px;
}

.folder-table-plan {
  margin-top: 20px;
  border: 1px solid var(--light-grey);
  border-radius: 10px;
  padding: 10px;
}

.folder-table-plan-header {
  margin-right: 14px;
  margin-bottom: -5px;
  display: grid;
  gap: 10px;
  padding: 0 10px;
  grid-template-columns: 1fr auto;
  grid-template-rows: 100%;
}

.folder-table-plan-body {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
}

.info-block-plan {
  display: flex;
  justify-content: center;
  align-items: center;
}

.financing-plan-item {
  position: relative;
  padding: 10px;
  margin-bottom: 10px;
  background-color: var(--dark-blue);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.folder-table-plan-header,
.financing-plan-item {
  display: grid;
  grid-template-columns: 35px 2fr 1fr 1fr;
  align-items: center;
}

.financing-plan-item .financing-plan-link-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.folder-table-plan-header p:last-child {
  text-align: right;
}

.financing-plan-item p {
  margin: 0;
  color: var(--white);
}

.financing-plan-actions {
  display: flex;
  gap: 10px;
}

.financing-plan-dropdown {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.dropdown-icon-financing-plans {
  transition: transform 0.3s;
  cursor: pointer;
  margin-right: 30px;
}

.dropdown-menu-plan {
  position: absolute;
  top: 25px;
  right: 0;
  width: max-content;
  overflow: hidden;
  max-height: 0;
  background-color: var(--white);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  list-style: none;
  z-index: 1000;
  transition: max-height 0.3s, padding 0.3s;
  padding: 0 20px;
  display: block;
}

.dropdown-menu-plan.show {
  padding: 20px;
  max-height: 150px;
}

.dropdown-menu-plan li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 8px;
}

.dropdown-menu-plan li:last-child {
  margin-bottom: 0;
}

.dropdown-menu-plan li button {
  background-color: transparent;
  border: none;
  color: var(--dark-blue);
  cursor: pointer;
  transition: color 0.2s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0;
}

.dropdown-menu-plan li button:hover {
  color: var(--blue);
}

.folder-table-plan-footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.modal-buttons-row {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}