.thumbnail-container {
  max-height: 600px;
  border-radius: 10px;
  padding: 0 0 10px;
  margin: var(--margin);
  max-width: 380px;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}
.thumbnail-container:hover img {
  transform: scale(1.04);
}
.thumbnail-container:hover .thumbnail-link span {
  color: var(--sky-blue);
}
.thumbnail-container:hover .thumbnail-link svg {
  --color: var(--sky-blue) !important;
}
.thumbnail-container img {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  width: 100%;
  aspect-ratio: 220/154;
  object-fit: cover;
  transition: transform 0.3s;
}
.thumbnail-tag {
  text-align: center;
  font-family: Poppins-Medium;
  font-size: 0.7rem;
  background-color: var(--tag-color);
  width: fit-content;
  padding: 7px 10px;
  margin: 10px 0 10px;
  border-radius: 5px;
}
.thumbnail-title {
  display: block;
  font-size: 1.1rem;
  font-family: Outfit;
font-weight: 600;
}
.thumbnail-container a,
.thumbnail-container p span {
  text-decoration: underline;
  margin-top: auto;
}
.thumbnail-link svg {
  margin-left: 5px;
}
.unbreakable {
  white-space: nowrap;
}
.thumbnail-link {
  margin-top: auto;
  padding-top: 10px;
}

@media (max-width: 576px) {
  .thumbnail-container img {
    position: relative;
    top: -10px;
  }
  .thumbnail-img-container {
    max-height: 140px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    overflow: hidden;
  }
  .thumbnail-container {
    margin: var(--margin) 0;
    padding: 15px;
    background-color: var(--background-color);
    box-shadow: 0px 4px 6px 0px #00000040;
  }
  .thumbnail-container a {
    padding-top: 15px;
    margin-top: 0;
  }
  .thumbnail-title {
    margin-top: 15px;
  }
  .thumbnail-title span {
    font-size: inherit;
    font-weight: inherit;
  }
  .thumbnail-tag {
    /* display: none; */
    position: absolute;
    top: 120px !important;
  }
  .thumbnail-title svg {
    margin-left: 5px;
  }
}
