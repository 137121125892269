.review-notification-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.title-review-notification {
  font-weight: 700;
}
.review-notification {
  margin-right: auto;
  width: fit-content;
  box-shadow: 0 0 20px rgb(0 0 0 / 10%);
}
.text-review-notification {
  font-size: 0.7rem;
}
