.expendable-group-container {
  --transition: 0.3s;

  display: flex;
  gap: 20px;
  flex-direction: column;
}
.expendable-container {
  border-radius: 10px;
  position: relative;
}
.expendable-top {
  background-color: var(--white);
  box-shadow: 0 0 20px rgb(0 0 0 / 10%);
  display: flex;
  justify-content: space-between;
  z-index: 2;
  position: relative;
  cursor: pointer;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  overflow: hidden;
}
.expendable-container:not(.active) .expendable-top {
  border-radius: 10px;
}
.expendable-title {
  padding: 15px;
  transition: color var(--transition);
}
.expendable-arrow {
  background-color: var(--dark-blue);
  padding: 15px 25px;
  display: flex;
  align-items: center;
}
.expendable-arrow svg {
  transition: transform var(--transition);
}
.expendable-content {
  max-height: 0;
  padding: 0 30px;
  transition:
    max-height var(--transition),
    padding-top var(--transition),
    padding-bottom var(--transition);
  overflow: hidden;
}
.expendable-container.active .expendable-content {
  overflow: unset !important;
}
.expendable-container::before {
  z-index: 0;
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 10px;
  padding: 3px;
  background: linear-gradient(to bottom, var(--dark-blue), var(--white));
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
}

.expendable-container.active::before {
  background: linear-gradient(to bottom, var(--dark-blue), var(--white));
}
.expendable-container .expendable-top {
  transition: background-color var(--transition);
}
.expendable-container.active .expendable-top {
  background-color: var(--dark-blue);
  border: 3px 0 solid var(--dark-blue);
}
.expendable-container.active .expendable-title {
  color: var(--white);
}
.expendable-container.active .expendable-content {
  max-height: fit-content;
  padding: 30px;
  background-color: var(--white);
  margin: 4px;
  position: relative;
  border-radius: 10px;
}
.expendable-checkbox-container {
  padding: 10px;
  position: absolute;
  right: 90px;
  display: flex;
  gap: 15px;
}
.expendable-container.active .expendable-checkbox-container label:first-child {
  color: var(--white);
}
.expendable-checkbox-container label:first-child {
  display: block;
  margin-top: 5px;
  cursor: pointer;
}
.expendable-checkbox-container input {
  display: none;
}
.expendable-checkbox {
  width: 50px;
  height: 25px;
  border-radius: 15px;
  background-color: var(--grey);
  transition: background-color 0.3s;
  position: relative;
  cursor: pointer;
  transition: color 0.3s;
}
.expendable-checkbox.expendable-checked {
  background-color: var(--sky-blue);
}
.expendable-checkbox::before {
  content: "";
  height: 19px;
  width: 19px;
  border-radius: 11px;
  background-color: var(--white);
  position: absolute;
  top: 3px;
  left: 3px;
  transition: left 0.3s;
}
.expendable-checkbox.expendable-checked::before {
  left: 28px;
}

@media (max-width: 576px) {
  .expendable-checkbox-container label:first-child {
    display: none;
  }
  .expendable-checkbox-container {
    right: 70px;
  }
  .expendable-container.active .expendable-content {
    padding: 0 15px;
  }
}
