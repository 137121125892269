.container {
  margin: auto;
  padding: 15px 10px;
  max-width: var(--container-width);
}
.horizontal-container {
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  max-width: var(--container-width);
}
.vertical-container {
  padding-top: 15px;
  padding-bottom: 15px;
}
* {
  --container-width: auto;
}
.row,
.row-1000,
.row-700,
.row-700-reverse,
.row-500,
.row-1000-reverse {
  display: flex;
  flex-wrap: nowrap;
}
.row-1000,
.row-500,
.row-700 {
  flex-direction: column;
}
.row-1000-reverse,
.row-700-reverse {
  flex-direction: column-reverse;
}
.col {
  width: 100%;
}
.w-100 {
  width: 100% !important;
  position: relative;
}
.h-100 {
  height: 100%;
}
.w-0 {
  width: 0% !important;
  position: relative;
}
.h-0 {
  height: 0%;
}

@media (min-width: 576px) {
  * {
    --container-width: 540px;
  }
}

@media (min-width: 576px) {
  * {
    --container-width: 720px;
  }
  .row-500 {
    flex-direction: row;
  }
}

@media (min-width: 768px) {
  .row-700,
  .row-700-reverse {
    flex-direction: row;
  }
}

@media (min-width: 992px) {
  * {
    --container-width: 960px;
  }
  .row-1000,
  .row-1000-reverse {
    flex-direction: row;
  }
}

@media (min-width: 1200px) {
  * {
    --container-width: 1140px;
  }
}

@media (min-width: 1400px) {
  * {
    --container-width: 1320px;
  }
}
