.google-form-review-link {
    align-items: center;
    display: flex;
    gap: 5px;
    background-color: var(--background-color, white);
    border: 2px solid var(--border-color, var(--gold));
    color: var(--text-color, var(--gold));
    transition: background-color 0.3s, border-color 0.3s, color 0.3s;
    border-radius: 5px;
    padding: 4px 8px 5px;
    width: fit-content;
    height: fit-content;
    z-index: 10;
    font-size: 0.8rem;
    font-weight: 600;
    cursor: pointer;
}

.google-form-review-link:hover {
    background-color: var(--hover-color, var(--gold));
    border-color: var(--hover-color, var(--gold));
    color: white;
}

.rocket-img {
    width: 15px;
    height: 15px;
}

.review-modal-title {
    display: flex;
    text-align: center;
}

.rocket-img-modal {
    width: 40px;
    height: 40px;
}