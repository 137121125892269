.pdf-container .strong-weak-points-h4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: calc(var(--default-margin) * 0.5) 0;
}
.pdf-container .strong-weak-points-stars svg:nth-child(2) {
  margin: 0 7px;
}
.pdf-container .strong-weak-points-stars {
  display: flex;
}
.pdf-container .strong-weak-points-h4 h4 {
  color: var(--text-color);
  text-transform: uppercase;
  font-weight: 500;
}
.pdf-container .strong-point,
.pdf-container .weak-point {
  position: relative;
  margin-bottom: calc(var(--default-margin) * 0.3);
  left: 23px;
  font-weight: 500;
  color: var(--light-green);
}
.pdf-container .weak-point {
  color: var(--red);
}
.pdf-container .strong-point:before,
.pdf-container .weak-point:before {
  content: "";
  mask-image: var(--dot);
  -webkit-mask-image: var(--dot);
  position: absolute;
  height: 14px;
  width: 14px;
  background-repeat: no-repeat;
  left: -21px;
  top: 4px;
}
.pdf-container .strong-point:before {
  background-color: var(--light-green) !important;
}
.pdf-container .weak-point:before {
  background-color: var(--red) !important;
}
.pdf-container .pdf-point-container p {
  margin-left: 23px;
  margin-bottom: 15px;
}
