.import-simulateur-searchbar {
  width: 100%;
  border: 1px solid var(--dark-blue);
  border-radius: 10px;
}

.import-simulateur-checkbox-header {
  width: 100%;
  margin-bottom: 10px;
}

.import-simulateur-checkbox-header h3 {
  font-size: 1.2rem;
  margin-bottom: 5px;
}

.import-simulateur-checkbox-header p {
  color: var(--grey);
}

.import-simulateur-checkbox {
  margin-top: 20px;
  background-color: var(--dark-blue);
  border-radius: 10px;
}

.import-simulateur-checkbox label {
  color: white;
}

.import-simulateur-no-simulateur {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.import-simulateur-no-simulateur p {
  color: var(--red);
}

.import-simulateur-list-container {
  max-height: 260px;
  overflow-y: auto;
}

.import-simulateur-list-container::-webkit-scrollbar {
  width: 6px;
}

.import-simulateur-list-container::-webkit-scrollbar-thumb {
  background-color: var(--grey);
  border-radius: 3px;
}