.file-input-container {
  width: 100%;
  display: flex;
  gap: 5px;
  flex-direction: column;
  position: relative;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.file-input-label.filled-button {
  box-shadow: none;
}
.file-input-container.with-remove-button {
  padding-bottom: 0;
}
.file-input-label {
  width: 100%;
  border-radius: 10px;
  padding: var(--padding);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.file-input {
  display: none;
}
.file-input-remove-button {
  color: var(--dark-red);
  text-decoration: underline;
  text-align: center;
  cursor: pointer;
}
.file-input-container .form-error {
  bottom: -10px;
}
.file-input-container.with-remove-button .form-error {
  bottom: 5px;
}
.file-input-container .form-error::before {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

@media (max-width: 576px) {
  .file-input-container {
    padding: 0;
  }
}
