.guideline-step-container {
  display: flex;
  gap: 15px;
  margin-bottom: 80px;
  align-items: center;
  width: fit-content;
}
.guideline-step-container p {
  color: var(--color);
  font-size: 2rem;
}
.zigzag-container {
  position: relative;
  height: 40px;
  bottom: 15px;
  width: 40px;
}
.guideline-step-container.right .zigzag-container {
  transform: rotate3d(0, -100, 1, 180deg);
}
.zigzag-1,
.zigzag-2 {
  position: absolute;
  background-color: var(--color);
  width: 22px;
  height: 20px;
}
.zigzag-1 {
  border-top-right-radius: 12px;
  top: 0;
  left: 0;
}
.zigzag-2 {
  border-bottom-left-radius: 12px;
  bottom: 1px;
  right: 0;
}
.zigzag-1:after,
.zigzag-2:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 18px;
  background-color: var(--creamy-white);
}
.zigzag-1:after {
  bottom: -2px;
  left: 0px;
  border-top-right-radius: 8px;
  width: 18px;
}
.zigzag-2:after {
  top: -2px;
  right: -2px;
  border-bottom-left-radius: 8px;
}
.zigzag-mask {
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  width: 41px;
  background: linear-gradient(135deg, transparent 5%, rgba(252, 249, 245, 0.5) 50%, var(--creamy-white) 95%);
}

@media (max-width: 1200px) {
  .guideline-step-container svg {
    width: 30px;
  }
  .guideline-step-container p {
    font-size: 1.2rem;
  }
  .zigzag-container {
    height: 30px;
    width: 30px;
  }
  .zigzag-1,
  .zigzag-2 {
    width: 17px;
    height: 15px;
  }
  .zigzag-1:after,
  .zigzag-2:after {
    width: 15px;
    height: 13px;
  }
  .zigzag-1:after {
    width: 13px;
  }
  .guideline-step-container {
    margin-bottom: 40px;
  }
}
