.loader-container {
  top: 0;
  background-color: var(--creamy-white);
  width: 100vw;
  position: fixed;
  height: 100vh;
  z-index: 998;
  display: flex;
  align-items: center;
  justify-content: center;
}
.activities-loader {
  position: absolute;
  height: 100%;
  width: 100%;
  align-items: flex-start;
  overflow: hidden;
}
.app-loader {
  position: absolute;
  width: 100%;
  top: 120px;
  height: calc(100vh - 120px);
}
