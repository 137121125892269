.pdf-container .text-h2-container {
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -15px;
}
.pdf-container .text-h2-container h2 {
  font-weight: 700;
  font-size: 1.8rem;
}
.pdf-container .h2-pdf-number {
  margin-right: 10px;
}