.footer-container {
  background-color: var(--dark-blue);
  width: 100%;
  z-index: 100;
  padding-top: 30px;
  position: relative;
  margin-top: 130px;
}
.footer-top {
  position: absolute;
  transform: translateY(calc(-100% - 29px)) !important;
}
.footer-container *:not(button, .footer-title) {
  font-size: 0.9rem;
  color: var(--white);
}
.footer-content {
  justify-content: space-between;
  gap: 30px;
  display: flex;
  flex-wrap: nowrap;
}
.footer-content > div {
  min-width: max-content;
}
.footer-content > div:last-child {
  min-width: auto;
}
.footer-container li,
.footer-container p {
  margin: 10px;
}
.footer-logo {
  margin: 0 10px 35px 10px;
}
.footer-title {
  color: var(--white);
  font-size: 1.5rem;
  padding: 40px 10px 27px;
}

@media (max-width: 1200px) {
  .footer-container * {
    font-size: 1rem;
  }
  .footer-content {
    flex-wrap: wrap;
  }
  .footer-content > div {
    max-width: calc(50% - 20px);
    width: 100%;
  }
  .footer-container li,
  .footer-container p {
    margin: 10px;
  }
  .footer-title {
    padding: 20px 10px 10px;
  }
}

@media (max-width: 768px) {
  .footer-content > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer-content > div:first-child ul {
    width: 100%;
  }
  .footer-logo {
    width: calc(100vw - 50px);
    margin: 0 auto 10px;
  }
  .footer-content > div {
    width: 100%;
  }
  .newsletter-form-container {
    padding: 0 20px;
  }
}

@media (max-width: 576px) {
  .footer-container {
    margin-top: 30px;
  }
}
