.banner-container {
  margin: 20px 0;
  width: fit-content;
}
.banner-container h1,
.banner-container p {
  display: inline;
}
.banner-container h1 {
  font-family: Outfit;
  font-weight: 700;
  color: var(--black);
  font-size: 2.5rem;
}
.banner-hr {
  background: linear-gradient(90deg, #ccb46f 0%, rgba(0, 0, 0, 0) 100%);
  border: none;
  height: 4px;
  margin-top: 10px;
  margin-bottom: 15px;
  border-radius: 3px;
}

@media (max-width: 1200px) {
  .banner-container {
    margin: 15px 0 0;
  }
  .banner-container h1 {
    font-size: 2rem;
  }
  .banner-hr {
    height: 2px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

@media (max-width: 576px) {
  .banner-container h1 {
    font-size: 1.8rem;
  }
  .banner-container {
    margin-top: 10px;
  }
}
