.file-input-preview-container {
  position: relative;
  border: 2px dashed var(--hover-color);
  border-radius: var(--border-radius);
  padding: var(--padding);
  color: var(--color);
  cursor: pointer;
  text-align: center;
  min-height: 150px;
  min-width: 240px;
  width: 100%;
}

.ile-input-preview-container.drag-active {
  background-color: var(--hover-color);
}

.file-input-preview-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-input-preview-label {
  margin-top: 35px;
  padding: 10px 20px;
}

.file-input-img {
  display: none;
}

.file-input-previews {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}

.file-input-preview {
  margin-top: 12px;
  position: relative;
  width: 150px;
  height: 100px;
}

.file-input-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--border-radius);
}

.file-input-preview-edit {
  position: absolute;
  top: -5px;
  right: -5px;
  background: var(--background-color);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.file-input-preview-remove {
  position: absolute;
  top: 27px;
  right: -5px;
  background: var(--background-color);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 576px) {
  .file-input-container {
    padding: 0;
  }
}
