.tooltip-modal {
  width: 80vw;
  top: 40px;
  max-height: calc(100vh - 80px);
  border: none;
  border-radius: 10px;
}
.tooltip-container {
  max-height: calc(90vh - 80px);
  overflow-y: scroll;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.tooltip {
  width: -webkit-fill-available;
  max-width: 1100px;
}
.close-tooltip {
  height: 80px;
  background-color: #f55;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  gap: 1rem;
  flex-direction: row;
  cursor: pointer;
}
.close-tooltip p {
  color: white;
}
.tooltip-arrow {
  transition:
    transform 0.3s,
    opacity 0.3s;
  opacity: 1;
  animation: 1s infinite arrow-tooltip;
}
.tooltip-arrow-container {
  z-index: 1000;
  position: fixed;
  bottom: 15vh;
  right: 4vw;
}

@keyframes arrow-tooltip {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}
