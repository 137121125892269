:root {
  --black: #000000;
  --white: #ffffff;
  --dark-blue: #252b41;
  --darker-blue: #180047;
  --blue: #000471;
  --link-blue: #2727ff;
  --sky-blue: #6064d6;
  --sky-blue-alt: #b9bafd;
  --pale-blue: #d3e1f7;
  --dark-blue-alt: #6d6f92;
  --grey: #939393;
  --grey-alt: #cac8c8;
  --light-blue: #e7eaf5;
  --light-grey: #d9d9d9;
  --beige: #f1e9d0;
  --creamy-white: #fcf9f5;
  --dark-gold: #8c742e;
  --gold: #ccb46f;
  --light-gold: #ebd595;
  --red: #f56e52;
  --green: #199016;
  --light-green: #21be1d;
  --dark-red: #ef3749;
  --orange: #ce3200;
}
