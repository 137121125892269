.financing-plan-buttons.show {
  bottom: 20px;
}
.financing-plan-buttons {
  transition: bottom 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: sticky;
  bottom: -130px;
  z-index: 19;
  padding: 20px;
  border-radius: 10px;
  margin: auto;
  background-color: var(--white);
  width: fit-content;
  box-shadow: 0 0 20px rgb(0 0 0 / 10%);
}
.financing-plan-save-download-buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
}
.financing-plan-container > div {
  transition: max-height 0.5s;
}
.financing-plan-container .card-header h2 {
  text-align: center;
  text-transform: uppercase;
}
.financing-plan-container .card-footer .case-input-container {
  padding-bottom: 0;
}
.financing-plan-cards-container section {
  width: 100%;
  min-height: 100%;
  height: unset;
}
.financing-plan-cards-container {
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
}
.financing-plan-title {
  padding: 20px 20px;
  border-radius: 10px;
  background-color: var(--background-color);
  font-weight: 600;
  color: var(--white);
  text-transform: uppercase;
  box-shadow: 0 0 20px rgb(0 0 0 / 20%);
}
.last-financing-plan-title span {
  text-transform: none;
}
.last-financing-plan-title {
  --background-color: var(--beige);
  color: var(--dark-blue);
}
.financing-plan-bottom {
  position: relative;
}
.financing-plan-bottom > .relative,
.financing-plan-bottom > .w-100 {
  padding: 15px 30px;
}
.financing-plan-subtitle {
  font-weight: 400;
  margin-top: 30px;
  text-align: center;
  margin-bottom: 10px;
}
.input-row-financing-plan {
  display: flex;
  gap: 20px;
}
.input-row-financing-plan > * {
  flex: 1;
}
.estimation-input label {
  color: white !important;
}
.estimations-list {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 9999;
  font-size: 0.85rem;
  max-height: 410px;
  row-gap: 0;
  position: absolute;
  height: auto;
  background-color: rgb(255, 255, 255);
  box-shadow: grey 3px 3px 7px;
  transition:
    max-height 0.3s,
    padding 0.3s;
  list-style: none;
}
.estimations-list li {
  font-family: Outfit;
  font-weight: 700;
  font-size: 0.8rem;
  position: relative;
  padding: 5px;
  padding-left: 10px;
  color: var(--blue-focus);
  transition:
    color 0.3s,
    background-color 0.3s;
}
.estimations-list li.selected {
  background-color: var(--pale-blue);
  color: var(--dark-blue);
}
.estimations-list li:not(.estimations-no-result) {
  cursor: pointer;
}
.estimations-list li:not(.estimations-no-result):hover {
  color: var(--white);
  background-color: var(--dark-blue);
}
.h-none {
  display: none;
}
.financing-plan-ebe-checkboxes-container {
  display: flex;
  position: absolute;
  right: 250px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: fit-content;
}
.financing-plan-last-label label {
  font-weight: 700;
}
.financing-plan-container .card-footer label {
  font-size: 1.1rem;
}
.financing-plan-container .case-input-container input {
  text-align: right;
  padding-right: 29px;
}
.financing-plan-container .multiples-inputs-container:not(:last-child) {
  margin-bottom: 25px;
}

@media (max-width: 1400px) {
  .financing-plan-ebe-checkboxes-container {
    right: 205px;
  }
}

@media (max-width: 1200px) {
  .financing-plan-ebe-checkboxes-container {
    position: static;
    margin-bottom: -10px;
    margin-top: -20px;
  }
}

@media (max-width: 992px) {
  .input-row-financing-plan {
    flex-direction: column;
  }
  .financing-plan-cards-container {
    flex-direction: column;
  }
}

@media (max-width: 568px) {
  
  .financing-plan-ebe-checkboxes-container {
    margin: 0;
  }
}
