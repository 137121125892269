.connexion-page-container {
  display: flex;
  gap: 50px;
}
.connexion-page-container > * {
  width: 100%;
}
.connexion-page-container > *:last-child {
  padding: 20px 30px 65px;
  margin: 16px 0;
  display: flex;
  flex-direction: column;
}

.modal-block-login {
  display: block !important;
}

.bold-modal {
  font-weight: 800;
}

@media (max-width: 1200px) {
  .connexion-page-container {
    flex-direction: column;
    gap: 0;
  }
  .connexion-page-container > *:last-child {
    padding: 10px 0;
    margin: 0;
  }
  .connexion-page-container > *:last-child .centered {
    margin-top: 20px !important;
  }
}
