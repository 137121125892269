.radio-group {
  display: flex;
  column-gap: 30px;
  flex-wrap: nowrap;
  width: 100%;
  flex-direction: var(--direction);
  justify-content: space-evenly;
}
.radio-group > div {
  width: 100%;
  min-height: 100%;
  margin: 0.5rem 0 0 0;
}
.radio-group-label {
  font-family: Outfit;
font-weight: 500;
  font-variant: small-caps;
  text-transform: lowercase;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.radio-container {
  height: 100%;
  background-color: var(--white);
  border-radius: 6px;
  position: relative;
  padding: var(--padding);
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: Outfit;
font-weight: 500;
  gap: 1rem;
  transition:
    box-shadow 0.3s,
    background-color 0.3s;
}
.radio-label {
  height: 100%;
  text-align: center;
}
.radio-container::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 2px solid var(--dark-blue);
  transition:
    top 0.1s,
    left 0.1s,
    border 0.07s;
}
.radio-container:hover::before {
  border-width: 3px;
}
.input-radio:checked + .radio-container {
  background-color: var(--dark-blue);
  border-color: var(--dark-blue);
  box-shadow: 0 0 20px rgb(0 0 0 / 10%);
}
.input-radio:checked + .radio-container p {
  color: var(--white);
}
.input-radio {
  max-height: 0;
  max-width: 0;
  position: absolute;
}
.radio-field-container.with-error .radio-container::before {
  border-color: var(--red);
}
.radio-field-container .form-error {
  bottom: -18px;
}

@media (max-width: 1400px) {
  .selectVille {
    width: calc(49% - 20px);
  }
}

@media (max-width: 1000px) {
  .radio-group {
    flex-wrap: wrap;
  }
}

@media (max-width: 650px) {
  .selectVille {
    width: calc(100%);
  }
}
