.pdf-container .page-pdf-container,
.pdf-generator-container {
  position: relative;
  width: 210mm;
  height: 297.13mm;
  border: 1px solid black;
  background-color: #ffffff;
  /* aspect-ratio: 1/1.4; */
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.pdf-generator-container {
  height: auto;
}
.pdf-container .page-pdf,
.pdf-generator-container {
  padding: 40px 70px;
  display: flex;
  height: 100%;
  flex-direction: column;
  position: relative;
}
.pdf-container .header-pdf img,
.pdf-container .footer-pdf img {
  height: 40px;
}
.pdf-container .header-pdf,
.pdf-container .footer-pdf {
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  background-color: var(--banner-background-color);
}
.pdf-container .header-pdf {
  justify-content: center;
}
.pdf-container .footer-pdf {
  padding: 0 40px;
  justify-content: space-between;
}
.pdf-container .page-number {
  color: var(--banner-text-color);
}
.pdf-container .footer-pdf.first-page {
  justify-content: center;
}
