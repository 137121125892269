.carousel-buttons {
  display: flex;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  padding: 1vw 5vw;
}
.starter-parcours-container {
  display: flex;
  justify-content: center;
}
.starter-parcours-container img {
  margin: auto;
  max-width: 70%;
}

@media (max-width: 992px) {
  .starter-parcours-container img {
    max-width: calc(100% - 40px);
  }
}
@media only screen and (max-width: 650px) {
  .carousel-buttons {
    flex-direction: column-reverse;
    gap: 1rem;
  }
}
