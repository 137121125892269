.components-row-container {
  --thumbnail-width: 70vw;
  margin: var(--margin);
}
.components-row {
  max-width: 100%;
  overflow-x: hidden;
}
.components-slider {
  width: max-content;
  display: flex;
  position: relative;
  right: var(--slide);
  padding: 0 var(--padding);
  transition: right 0.3s;
}
.components-slider .thumbnail-container {
  max-width: var(--thumbnail-width);
  width: 100%;
  margin: 10px;
}
.formations-row {
  margin: 0 50px;
  max-width: calc(100% - 100px);
}
.formations-row-bracket {
  position: absolute !important;
  top: calc(50% - 20px);
  left: -55px;
}
.formations-row-bracket:last-of-type {
  left: auto;
  right: -55px;
}

@media (min-width: 400px) {
  .components-row-container {
    --thumbnail-width: calc(55vw);
  }
}

@media (min-width: 576px) {
  .components-row-container {
    --thumbnail-width: calc(100%);
  }
}
