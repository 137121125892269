.tab-content-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.tab-content-header h2 {
  margin: 0;
  font-weight: 600;
  font-size: 1.7rem;
  display: flex;
  align-items: center;
}

.tab-content-header h2 .tuto-icon {
  margin-left: 15px;
}

.tab-content-header p {
  margin-top: 10px;
  font-size: 1.1rem;
  font-weight: Outfit-Medium;
  color: var(--grey);
}

.folder-estimations-buttons {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 20px;
}

.folder-table-estimations {
  margin-top: 20px;
  border: 1px solid var(--light-grey);
  border-radius: 10px;
  padding: 10px;
}

.folder-table-estimations-header {
  margin-right: 14px;
  margin-bottom: -5px;
  display: grid;
  gap: 10px;
  padding: 0 10px;
  grid-template-columns: 1fr auto;
  grid-template-rows: 100%;
}

.info-block-estimations {
  display: flex;
  justify-content: center;
  align-items: center;
}

.estimation-item {
  position: relative;
  padding: 10px;
  margin-bottom: 10px;
  background-color: var(--dark-blue);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.estimation-item p {
  margin: 0;
  color: var(--white);
}

.estimation-dropdown {
  position: relative;
}

.dropdown-toggle-button {
  background-color: var(--light-grey);
  border: none;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.dropdown-menu-estimation {
  position: absolute;
  top: 25px;
  right: 0;
  width: max-content;
  overflow: hidden;
  max-height: 0;
  background-color: var(--white);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  list-style: none;
  z-index: 1000;
  transition:
    max-height 0.3s,
    padding 0.3s;
  padding: 0 20px;
  display: block;
}

.dropdown-menu-estimation.show {
  padding: 20px;
  max-height: 170px;
}

.dropdown-menu-estimation li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 8px;
}

.dropdown-menu-estimation li:last-child {
  margin-bottom: 0;
}

.dropdown-menu-estimation li button {
  background-color: transparent;
  border: none;
  color: var(--dark-blue);
  cursor: pointer;
  transition: color 0.2s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0;
}

.dropdown-menu-icon {
  margin-right: 9px;
}

.dropdown-menu-estimation li button:hover {
  color: var(--blue);
}

.dropdown-icon-estimations {
  transition: transform 0.3s;
  cursor: pointer;
  margin-right: 30px;
}

.folder-table-estimations-footer {
  display: flex;
  justify-content: center;
}

.modal-buttons-row {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

@media (max-width: 576px) {
  .folder-estimations-buttons svg,
  .folder-simulateur-buttons svg,
  .folder-deliverable-buttons svg,
  .folder-plan-buttons svg {
    display: none;
  }
}
