.form-textarea {
  width: 100%;
  border: 2px solid var(--dark-blue-alt);
  border-radius: 6px;
  min-height: 200px;
  padding: 10px;
  resize: vertical;
  transition: all 0.2s;
  outline: 0;
}
.non-resizable {
  resize: none !important;
}
.form-textarea:focus {
  border: 2px solid var(--gold);
}
.small-textarea .form-textarea {
  min-height: 150px;
  resize: none;
}
.form-textarea.with-error {
  border-color: var(--red);
}
.textarea-container .form-error {
  bottom: -9px;
  left: 30px;
}
.textarea-container .form-error::before {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.textarea-container .input-words-count {
  top: 30px;
}
