.guideline-end {
  display: none;
}
.guideline-container {
  padding: 30px;
  margin-top: 100px;
  display: flex;
  flex-wrap: wrap;
}
.guideline-container > div {
  width: 45%;
}
.guideline-line {
  position: relative;
  border: 5px solid transparent;
  border-radius: 30px;
  background: var(--creamy-white);
  background-clip: padding-box;
  margin-bottom: -5px;
  padding: 140px 0 80px;
  display: flex;
  flex-direction: column;
}
.guideline-line:after {
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  height: calc(var(--line-height, 0%));
  content: "";
  z-index: -1;
  border-radius: 30px;
  background: linear-gradient(var(--gradient-color-start), var(--gradient-color-end));
  overflow: hidden;
}
.guideline-step-wrapper {
  opacity: 0;
  transform: translateX(20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.guideline-step-wrapper.visible {
  opacity: 1;
  transform: translateX(0);
}

.guideline-step-right-wrapper {
  opacity: 0;
  transform: translateX(-20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.guideline-step-right-wrapper.visible {
  opacity: 1;
  transform: translateX(0);
}
.guideline-1 {
  --gradient-color-start: var(--darker-blue);
  --gradient-color-end: var(--dark-gold);
}

.guideline-2 {
  --gradient-color-start: var(--dark-gold);
  --gradient-color-end: var(--darker-blue);
}

.guideline-3 {
  --gradient-color-start: var(--darker-blue);
  --gradient-color-end: var(--dark-gold);
}

.guideline-4 {
  --gradient-color-start: var(--dark-gold);
  --gradient-color-end: var(--darker-blue);
}

.guideline-5 {
  --gradient-color-start: var(--darker-blue);
  --gradient-color-end: var(--dark-gold);
}

.guideline-1:after {
  background: linear-gradient(var(--darker-blue), var(--dark-gold));
  top: 5px;
  right: 5px;
}
.guideline-img {
  width: 350px;
  margin: auto auto 50px auto;
  display: block;
}
.guideline-side {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.guideline-side .button {
  margin: 0 auto 150px auto !important;
}
.guideline-2 {
  align-items: flex-end;
}
.guideline-2:after,
.guideline-4:after {
  background: linear-gradient(var(--dark-gold), var(--darker-blue));
  left: 5px;
  bottom: 5px;
}
.guideline-3:after,
.guideline-5:after {
  background: linear-gradient(var(--darker-blue), var(--dark-gold));
  right: 5px;
}
.guideline-4 {
  margin-left: 55%;
}
.guideline-5:after {
  bottom: 5px;
}
.guideline-5 {
  padding-bottom: 0;
}
.guideline-5 .guideline-title-bis {
  margin-left: 180px;
}
.guideline-button-container {
  width: 100%;
}
.guideline-title-bis {
  font-size: 3rem;
  font-family: Outfit;
font-weight: 700;
  color: var(--color);
  text-align: center;
  position: relative;
  top: -38px;
  background-color: var(--creamy-white);
  width: max-content;
  padding: 0 15px;
  margin-left: -80px;
}
.guideline-text-container {
  width: 250%;
  position: relative;
  margin: 0 0 20px calc(-132%);
  display: flex;
  justify-content: space-between;
}
.guideline-text-container .mid-page-button {
  margin: 30px 0 0;
}
.guideline-text-container > div {
  height: fit-content;
  margin: auto;
  width: 50%;
}
.guideline-text-container img {
  height: 450px;
  padding: 25px 0;
  margin: -40px 50px 0;
  width: auto !important;
}
.guideline-text-container p {
  font-size: 1.2rem;
}
.guideline-5 .guideline-text-container img {
  margin-bottom: -60px;
}
.guideline-5 .guideline-text-container {
  margin: 20px auto 0 -100px;
}

@media (max-width: 1200px) {
  .guideline-container {
    margin-top: 60px;
  }
  .guideline-img {
    width: 230px;
  }
  .guideline-side .button {
    margin-bottom: 100px !important;
  }
  .guideline-text-container img {
    height: 300px;
  }
  .guideline-4 {
    padding: 60px 0;
  }
  .guideline-5 {
    padding-top: 60px;
  }
  .guideline-5 .guideline-text-container {
    margin: 20px auto 0 -85px;
  }
  .guideline-text-container {
    margin: 0 0 20px calc(-130%);
  }
  .guideline-1 .timeline-title-container {
    top: -26px;
  }
}

@media (max-width: 992px) {
  .guideline-5 .guideline-text-container {
    margin-left: -80px;
  }

  .guideline-text-container {
    margin: 0 0 20px calc(-120%);
  }
}

@media (max-width: 576px) {
  .guideline-container {
    margin-top: 30px;
  }
  .guideline-container {
    padding: 30px 15px;
  }
  .guideline-img {
    display: none;
  }
  .guideline-line,
  .guideline-line:after {
    border-radius: 20px;
    padding: 90px 0;
  }
  .guideline-line:after {
    padding: 5px 0;
  }
  .guideline-1::after {
    padding: 0 0 1px;
  }
  .guideline-4,
  .guideline-5 {
    padding-top: 70px;
    padding-bottom: 50px;
  }
  .guideline-button-container {
    width: 100vw;
    position: absolute;
    bottom: 0;
    right: -25px;
  }
  .guideline-side {
    position: relative;
  }
  .guideline-button-container .button {
    margin: 0 auto 50px !important;
  }
  .guideline-button-container.align-left {
    right: unset;
    left: -25px;
    z-index: 2;
  }
  .guideline-5:after {
    bottom: -5px;
  }
  .guideline-5:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 20px;
    bottom: -8px;
    left: -6px;
    background: var(--creamy-white);
  }
  .guideline-text-container {
    margin: 20px 0 -10px !important;
    width: calc(100vw - 50px);
    flex-direction: column;
  }
  .guideline-5 .guideline-text-container {
    margin-bottom: -50px !important;
  }
  .guideline-text-container > div {
    width: auto;
    left: -5px;
    margin: 0;
    position: relative;
  }
  .guideline-4 .guideline-text-container > div {
    margin-left: 0;
    left: 5px;
  }
  .guideline-text-container p {
    text-align: left !important;
    margin: 0 15px;
  }
  .guideline-text-container .mid-page-button {
    margin: 20px auto 0 !important;
    position: relative;
  }
  .guideline-text-container img {
    display: block;
    margin-bottom: 20px !important;
    height: unset;
    width: 70%;
    padding: 0;
  }
  .guideline-4 {
    align-items: flex-end;
  }
  .guideline-4 .guideline-text-container {
    flex-direction: column-reverse;
  }
  .guideline-end {
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: var(--dark-gold);
    position: absolute;
    bottom: 5px;
    left: -11px;
  }
}
