/*
GENERAL
*/
.homepage-container h2 {
  font-size: 3.5rem;
  margin-bottom: 70px;
  color: black;
  text-align: center;
}
.homepage-section-2 {
  min-height: calc(100vh - 60px);
}
.homepage-section-3,
.homepage-section-5,
.homepage-section-7 {
  padding-top: 50px !important;
  min-height: 100vh;
}
.homepage-section-8,
.homepage-section-6,
.homepage-section-4 {
  padding-top: 50px;
}
.homepage-container {
  overflow: hidden;
  position: relative;
  top: -2px;
}
/* 
HEADER
 */
.homepage-header {
  background-color: var(--dark-blue);
}
.homepage-header .navbar-header,
.homepage-header .navbar-container li:last-child * {
  color: var(--white);
}

.homepage-header .header-bracket {
  --color: var(--white) !important;
}
.homepage-header .navbar-container li:hover p,
.homepage-header .navbar-container li:hover p > * {
  color: var(--beige);
}
.homepage-header .filled-button {
  --background-color: var(--light-gold) !important;
  --color: var(--dark-blue) !important;
  --hover-color: var(--light-gold) !important;
}
.homepage-header .unfilled-button,
.homepage-header .unfilled-button * {
  --color: var(--white) !important;
  --hover-color: var(--dark-blue) !important;
}
.homepage-header .opened-header .burger-menu div {
  background: var(--white);
}
.homepage-header .opened-header {
  background-color: var(--dark-blue);
}

/*
LANDING
*/
.landing-container {
  min-height: calc(100vh - 95px);
  position: relative;
  top: -2px;
  width: 100%;
  background-color: var(--dark-blue);
}
.landing-container .container {
  display: flex;
  flex-direction: column;
  gap: 5.5vh;
  padding-top: 185px;
}
.landing-container * {
  z-index: 2;
  position: relative;
}
.homepage-container .rounded-div {
  top: -3px;
}
.landing-page-fade {
  margin-top: 35px;
  position: relative;
  height: calc(100% - 270px);
  width: 100%;
  position: absolute;
  background: var(--dark-blue);
}
.landing-page-fade:before {
  content: "";
  position: absolute;
  width: 100%;
  top: -60px;
  height: 60px;
  background: linear-gradient(180deg, transparent, var(--dark-blue));
}
.landing-image {
  margin: auto;
  height: 200px;
  position: fixed;
  top: 130px;
  display: block;
  left: 50%;
  transform: translateX(-50%);
}
.homepage-h1,
.homepage-h1 * {
  font-weight: 700;
  font-size: 5rem;
  text-align: center;
  color: var(--white);
  line-height: 1.2;
}
.landing-page-description,
.landing-page-description-2 {
  color: var(--white);
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 10px;
}
.landing-page-description div,
.landing-page-description-2 div {
  width: 6px;
  min-width: 6px;
  height: 6px;
  min-height: 6px;
  border-radius: 3px;
  background-color: var(--gold);
}
.landing-page-description-2 div {
  width: 5px;
  height: 5px;
}
.landing-button {
  width: fit-content;
  margin: 0 auto;
}

@media (min-height: 760px) and (min-width: 1200px) {
  .landing-image {
    height: 300px;
  }
  .landing-container .container {
    padding-top: 300px;
  }
  .homepage-h1,
  .homepage-h1 span {
    font-size: 6rem;
  }
  .landing-page-description,
  .landing-page-description-2 {
    font-size: 1.2rem;
  }
  .landing-page-fade {
    height: calc(100% - 400px);
  }
}
/* 
SECTION 2
*/
.homepage-section-2 {
  margin-top: 100px;
}
.homepage-section-2 > div {
  display: flex;
  justify-content: space-around;
}
.homepage-section-2-text-container {
  max-width: 400px;
}
.homepage-section-2-text-container:last-of-type * {
  color: #002369;
}
.homepage-section-2-text-container ul {
  margin: 40px -20px 40px 40px;
  width: fit-content;
  position: relative;
}
.homepage-section-2-text-container li {
  font-size: 1.3rem;
}
.homepage-section-2 img {
  height: 170px;
  margin: 0 auto 30px;
  display: block;
}
.homepage-section-2 p {
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.2;
}
.homepage-dots-column {
  position: absolute;
  left: -35px;
  top: 9px;
}
.list-item {
  opacity: 0;
  transform: translateX(-40px);
  transition:
    transform 0.6s ease-out,
    opacity 0.6s ease-out;
}

.list-item.is-visible {
  opacity: 1;
  transform: translateX(0);
}

.list-item-1.is-visible {
  transition-delay: 0.1s;
}
.list-item-2.is-visible {
  transition-delay: 0.2s;
}
.list-item-3.is-visible {
  transition-delay: 0.3s;
}
.list-item-4.is-visible {
  transition-delay: 0.4s;
}
.list-item-5.is-visible {
  transition-delay: 0.5s;
}

/*
SECTION 3
*/
.global-rate-card {
  margin: auto;
  width: fit-content;
}
.global-rate-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}
.global-rate-card * {
  font-size: 1.3rem;
  font-family: Outfit;
font-weight: 700;
  color: black;
}
.global-rate {
  font-size: 2.3rem;
}
/*
SECTION 4
*/
.homepage-activities-button {
  color: var(--dark-blue) !important;
}
.homepage-activities-button:hover {
  color: var(--white) !important;
}
/*
SECTION 5
*/
.homepage-section-5 {
  padding: 0 70px;
}
.fonctionnement-estimation-img {
  width: 65%;
  margin: 0 auto;
  display: block;
}
.homepage-cards-container {
  display: flex;
  justify-content: space-between;
  gap: 80px;
}
/*
SECTION 6
*/

.lights-img {
  width: 40vw;
  margin: 60px auto;
  display: block;
}
/*
SECTION 8
*/
.homepage-bottom-div-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
}
.homepage-bottom-div-container img {
  max-width: 400px;
  margin: 0 50px;
}
.homepage-bottom-div-container span {
  font-size: 1.3rem;
  font-family: Outfit;
font-weight: 600;
}
.homepage-bottom-div-container p {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.innovation-img {
  animation: takeoff 4s ease-in-out infinite alternate;
}

@keyframes takeoff {
  0% {
    transform: translate3d(0) scale(1) rotate3d(0);
  }
  100% {
    transform: translate3d(14px, -10px, -20px) scale(0.9) rotate3d(1, 1, 1, 10deg);
  }
}

@media (max-width: 1200px) {
  /*
  GENERAL
  */
  .homepage-container h2 {
    font-size: 2.2rem;
  }
  /*
  LANDING PAGE
  */
  .landing-reviews-container {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .landing-reviews-container a {
    color: var(--white);
    font-size: 0.9rem;
    font-family: Outfit;
    font-weight: 500;
    text-decoration: underline;
  }

  /*
  SECTION 2
  */
  .homepage-section-2 p {
    font-size: 1.4rem;
  }
  .homepage-section-2 li {
    font-size: 1.2rem;
  }
  .homepage-section-2-text-container {
    max-width: 320px;
  }
  .homepage-section-2 img {
    max-height: 140px;
  }
  .landing-page-description-2 {
    display: none;
  }
  .landing-container .container {
    gap: 7vh;
  }
  /*
  SECTION 3
  */
  .homepage-section-3 {
    display: none;
  }

  /*
  SECTION 5
  */
  .homepage-section-5 {
    padding: 0;
  }
  .homepage-cards-container {
    gap: 50px;
  }
  .fonctionnement-estimation-img {
    width: 70%;
  }

  /*
  SECTION 6
  */
  .lights-img {
    margin: 40px auto 60px;
  }

  /*
  SECTION 8
  */
  .homepage-bottom-div-container img {
    max-width: 250px;
    margin: 0;
  }
  .homepage-bottom-div-container p {
    font-size: 1.2rem;
    gap: 30px;
  }
  .homepage-bottom-div-container {
    gap: 60px;
  }
}

@media (max-width: 992px) {
  /*
  GENERAL
  */
  .homepage-section-2,
  .homepage-section-3,
  .homepage-section-5,
  .homepage-section-7 {
    min-height: auto;
  }

  /*
  LANDING PAGE
  */
  .landing-image {
    max-height: 25vh;
    top: calc(115px);
  }
  .landing-container .container {
    padding-top: 15vh;
    gap: 4vh;
  }
  .homepage-h1,
  .homepage-h1 span {
    font-size: 4rem;
    line-height: 1.1;
  }
  .landing-page-description,
  .landing-page-description-2 {
    text-align: center;
  }
  .landing-page-description-2 {
    flex-direction: column;
    gap: 2px;
    font-size: 0.9rem;
  }
  .landing-page-description-2 div {
    height: 0;
    min-height: 0;
  }

  /*
  SECTION 2
  */
  .homepage-section-2 > div {
    flex-direction: column;
    align-items: center;
    gap: 100px;
  }
  .homepage-section-2-text-container {
    max-width: 430px;
  }
  .homepage-section-2-text-container ul {
    margin-left: 70px;
  }
  .homepage-section-2 img {
    max-height: 170px;
  }

  /*
  SECTION 5
  */
  .homepage-cards-container {
    flex-direction: column;
    padding: 0 10vw;
    gap: 20px;
  }
  .homepage-card-container p {
    font-size: 1.2rem;
  }

  /*
  SECTION 8
  */
  .homepage-bottom-div-container {
    flex-direction: column;
  }
  .homepage-bottom-div-container span {
    text-align: center;
    max-width: 80%;
    margin: auto;
  }
}

@media (max-width: 1200px) and (min-height: 900px) {
  .landing-image {
    height: 250px;
  }
  .landing-container .container {
    padding-top: 230px;
    gap: 4.5vh;
  }
  .landing-page-fade {
    margin-top: 35px;
  }
  .homepage-h1,
  .homepage-h1 span {
    font-size: 5rem;
  }
  .landing-page-description,
  .landing-reviews-container a {
    padding-top: 5px;
    font-size: 1.2rem;
  }
  .landing-button {
    --padding: 10px 20px !important;
    --font-size: 1.5rem !important;
  }
}

@media (max-width: 576px) {
  /*
  LANDING PAGE
  */
  .homepage-h1,
  .homepage-h1 span {
    font-size: 3rem;
  }
  .landing-container .container {
    padding-top: calc(20px + 23vh);
    gap: 5vh;
  }
  .landing-image {
    max-height: 23vh;
    top: calc(50px + 10vh);
  }
  .landing-page-fade {
    margin-top: 20px;
  }

  /*
  SECTION 2
  */
  .homepage-section-2 {
    margin-top: 50px;
  }
  .homepage-section-2-text-container ul {
    margin-left: 40px;
  }

  /*
  SECTION 5
  */
  .fonctionnement-estimation-img {
    width: 90%;
  }
  .homepage-cards-container {
    padding: 0 10px;
    gap: 30px;
  }

  /*
  SECTION 6
  */
  .lights-img {
    width: 70vw;
    margin-bottom: 60px;
  }

  /*
  SECTION 7
  */
  .homepage-container .exemples-rapports-container {
    margin: 60px 0;
  }

  /*
  SECTION 8
  */
  .homepage-bottom-div-container span {
    font-size: 1rem;
  }
  .homepage-bottom-div-container img {
    max-width: 200px;
  }
}
