.synthese-container {
  display: flex;
  gap: 20px;
}
.synthese-container .card {
  border-radius: 10px;
  height: 100%;
  position: relative;
}
.synthese-container > .card {
  width: 75%;
}
.synthese-container > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.synthese-title {
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 500;
  font-family: Outfit;
  font-weight: 500;
}
.synthese-hr {
  background: linear-gradient(90deg, var(--gold) 0%, rgba(0, 0, 0, 0) 100%);
  border: none;
  height: 4px;
  margin-top: 10px;
  margin-bottom: 15px;
  border-radius: 3px;
}
.synthese-infos-table * {
  text-align: left;
}
.synthese-infos-table th {
  padding: 8px 30px 8px 0;
}
.synthese-bilans-container {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.synthese-bilan-line {
  height: 4px;
  flex: 1;
  border-radius: 2px;
  background-color: var(--blue);
}
.synthese-bilans-subtitle svg {
  position: relative;
  top: -2px;
  padding: 3px;
  margin: 0 3px;
  border-radius: 50%;
  border: 2px solid var(--blue);
}
.synthese-bilan-button.disabled::before {
  content: "Vous devez d'abord terminer le bilan " attr(data-main-year);
  position: absolute;
  top: -40px;
  width: 160px;
  padding: 0;
  border-radius: 5px;
  background-color: var(--red);
  z-index: 3;
  transition:
    max-height 0.3s,
    padding 0.3s;
  max-height: 0;
  overflow: hidden;
}
.synthese-bilan-button.disabled:hover::before {
  max-height: 40px;
  padding: 10px;
}
.synthese-bilan-button.disabled * {
  --color: var(--grey) !important;
}
.synthese-bilan-button {
  position: relative;
  cursor: pointer;
  width: 75px;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid var(--white);
  background-color: var(--white);
  aspect-ratio: 93/110;
}
.synthese-bilan-button * {
  cursor: pointer !important;
}
.synthese-rapport-icon {
  position: absolute;
  transition: transform 0.3s;
  top: 0;
  width: 100% !important;
  height: auto;
  aspect-ratio: 93/110;
}
.synthese-bilan-button p {
  font-size: 0.8rem;
  text-align: center;
  line-height: 1.3;
}
.synthese-bilan-button span {
  font-family: Outfit;
  font-weight: 700;
  font-size: 0.8rem;
}
.synthese-delete-bilan-icon {
  position: absolute;
  bottom: -2px;
  right: -5px;
  border-radius: 50%;
  background-color: var(--red);
  padding: 4px;
}
.synthese-main-bilan {
  width: 90px;
  top: -9px;
}
.synthese-main-bilan p,
.synthese-main-bilan span {
  font-size: 1rem;
}
.synthese-bilan-button .filled-button {
  position: absolute;
  bottom: -30px;
  width: calc(100% - 3px);
}
.synthese-bilans-container .button {
  z-index: 2;
}
.synthese-bilan-button:hover .synthese-rapport-icon {
  transform: scale(1.05);
}
.overview-financing-plan-button svg {
  margin: 1px 0 -1px
}

@media (max-width: 992px) {
  .synthese-container {
    flex-direction: column;
  }
  .synthese-infos-table {
    position: relative;
    top: 0;
    margin-top: 20px;
  }
  .synthese-container > .card {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .synthese-bilans-container {
    flex-direction: column;
  }
  .synthese-bilan-button {
    margin-bottom: 15px;
  }
  .synthese-bilan-button .filled-button {
    bottom: 0;
    right: -75px;
    top: 0;
    margin: auto;
    height: fit-content;
    width: fit-content;
  }
  .synthese-main-bilan {
    top: 0;
  }
  .synthese-main-bilan .filled-button {
    right: -67px;
  }
}
