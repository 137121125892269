.breadcrumbs-container {
  margin-top: -10px;
  font-size: 0.8rem;
}
.breadcrumbs-container * {
  font-size: 0.8rem;
  font-family: Poppins-Medium;
}
.breadcrumbs-container a:hover {
  color: var(--blue);
  text-decoration: underline;
}

@media (max-width: 576px) {
  .breadcrumbs-container {
    margin-top: -20px;
  }
}
