.case-input-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  padding: 5px 10px 15px;
  margin-bottom: 5px;
}
.case-input-container input {
  width: 160px;
  padding: 10px;
  padding-right: 25px;
  border: none;
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}
.case-input-container input:focus {
  outline: 2px solid var(--gold);
}
.case-input-container label {
  font-family: Outfit;
font-weight: 600;
  color: var(--color);
}
.case-input-container .before-euro,
.case-input-container .before-percent,
.case-input-container .before-null {
  position: relative;
}
.case-input-container .label-tip {
  position: absolute;
  bottom: 8px;
  top: unset;
  height: fit-content;
}
.case-input-container .before-euro::before,
.case-input-container .before-percent::before {
  content: "€";
  position: absolute;
  display: flex;
  z-index: 1;
  align-items: center;
  right: 10px;
  top: 9px;
  font-family: Outfit;
font-weight: 700;
  font-size: 1.1rem;
}
.case-input-container .before-percent::before {
  content: "%";
}
.case-input-container .form-error {
  left: 3px;
  width: calc(100% - 6px);
  bottom: -16px;
}
.case-input-container .form-error::before {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.case-input-container.with-error input {
  outline: 2px solid var(--red);
}
@media (max-width: 576px) {
  .case-input-container {
    flex-direction: column;
    gap: 5px;
  }
  .case-input-label {
    text-align: center;
  }
  .case-input-container span,
  .case-input-container input {
    width: 100%;
  }
  .case-input-container .label-tip {
    position: static;
  }
}
