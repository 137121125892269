.raisons-sociales-container {
  z-index: 1000;
  font-family: Outfit;
font-weight: 600;
  text-transform: uppercase;
  max-width: 750px;
  max-height: 580px;
  transition: max-height 0.3s;
  overflow-y: scroll;
  padding-right: 10px;
  position: absolute;
  left: 0;
  margin-top: 10px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  cursor: pointer;
}
.raison-sociale-container {
  padding: 10px 25px;
  border-radius: 15px;
  height: auto;
  background-color: var(--beige);
  width: 700px;
  max-width: 85vw;
  transition:
    color 0.3s,
    background-color 0.3s;
}
.raison-sociale-container p {
  transition: 0.3s color;
  width: 100%;
}
.raison-sociale-label {
  opacity: 0.7;
  font-size: 0.9rem;
}
.raison-sociale-value {
  opacity: 1;
  transition: 0.3s color;
  font-size: 0.9rem;
  font-family: Outfit;
font-weight: 600;
}
.raison-sociale-container:hover {
  background-color: var(--dark-blue);
}
.raison-sociale-title {
  opacity: 1 !important;
  font-size: 1.4rem;
  font-family: Outfit;
font-weight: 700;
  color: var(--sky-blue);
  margin: 5px 0 0;
  transition: color 0.3s;
}
.raison-sociale-container:hover p:not(.raison-sociale-title),
.raison-sociale-container:hover span {
  color: white;
}
.raison-sociale-container:hover .raison-sociale-title {
  color: var(--gold);
}
