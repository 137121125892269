.download-pdf-notification-group-container {
  position: fixed;
  bottom: 4vh;
  left: 10px;
  width: fit-content;
  background-color: var(--blue);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
  z-index: 100000;
  animation: slideIn 0.5s forwards;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.download-pdf-notification-container {
  padding: 18px;
  display: flex;
  gap: 15px;
  align-items: center;
}

.infinite-loader {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  box-shadow: 0 -2px 0 var(--white);
}

.loading-text {
  font-size: 1rem;
  color: var(--white);
  text-align: left;
}