@media (min-width: 576px) {
  .thumbnails-slider {
    gap: 2px;
  }
}

@media (min-width: 992px) {
  .thumbnails-row-container {
    max-width: calc(var(--container-width) - 130px);
  }
}
