.pdf-container .h3-pdf-container {
  background-color: var(--titles-background);
  border-radius: 10px;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  left: -20px;
  width: calc(100% + 40px);
  margin-bottom: var(--default-margin);
}
.pdf-container .h3-pdf-container h3 {
  font-size: 1.5rem;
  font-weight: 500;
}
.pdf-container .h3-pdf-container svg {
  height: 40px;
  width: 40px;
}
.pdf-container .text-hr-side {
  width: 450px;
  height: 3px;
  border-radius: 3px;
  margin-top: 45px;
  background: linear-gradient(-90deg, var(--main-color), transparent 100%);
}
