.methode-row-text-img {
  gap: 50px;
  display: flex;
  justify-content: space-around;
  position: relative;
  align-items: center;
}
.methode-row-text-img > div {
  max-width: var(--text-max-width);
  margin: var(--text-margin);
}
.methode-row-text-img img {
  max-width: var(--max-width);
  max-height: var(--max-width);
}

@media (max-width: 1200px) {
  .methode-row-text-img img {
    max-height: calc(var(--max-width) - 80px);
    max-width: calc(var(--max-width) - 80px);
  }
  .methode-row-text-img > div {
    margin: 0 60px;
  }
  .methode-row-text-img img {
    margin-left: 60px;
  }
  .methode-row-text-img-right img {
    margin-left: 0;
  }
}

@media (max-width: 992px) {
  .methode-row-text-img {
    flex-direction: column;
    gap: 20px;
  }
  .methode-row-text-img-right {
    flex-direction: column-reverse;
  }
  .methode-row-text-img > div {
    max-width: 100%;
    text-align: center;
  }
  .methode-row-text-img img {
    margin: 0 auto;
    max-height: 200px;
    max-width: 200px;
  }
}
@media (max-width: 576px) {
  .methode-row-text-img > div {
    text-align: left;
    margin: 0;
  }
}
