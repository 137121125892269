.filled-button:not(.disabled) {
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.25);
  background-color: var(--background-color);
  border: 3px solid var(--background-color);
  color: var(--color);
  transition: box-shadow 0.3s;
  position: relative;
  transition:
    background-color 0.4s,
    color 0.4s;
  border-color: var(--background-color);
}
.filled-button:not(.disabled):hover {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
  color: var(--hover-color);
  background-color: transparent;
  border-color: transparent;
}
.filled-button:not(.disabled):hover svg {
  --color: var(--hover-color) !important;
}
.filled-button:not(.disabled)::after,
.filled-button:not(.disabled)::before {
  content: "";
  position: absolute;
  border: 3px solid var(--background-color);
  border-top-width: 0;
  border-right-width: 0;
  bottom: -3px;
  left: -3px;
  border-radius: var(--border-radius);
  width: calc(100% + 3px);
  height: calc(100% + 3px);
}
.filled-button:not(.disabled)::before {
  border-bottom-width: 0;
  border-left-width: 0;
  bottom: auto;
  left: auto;
  right: -3px;
  top: -3px;
  border-bottom-left-radius: 0;
  border-top-width: 3px;
  border-right-width: 3px;
}
.filled-button:not(.disabled):hover::before {
  animation: buttonBorderBefore 0.35s linear;
  height: 0;
  width: 0;
  opacity: 0;
}
.filled-button:not(.disabled):hover::after {
  animation: buttonBorderAfter 0.7s linear;
  height: 0;
}
.filled-button:not(.disabled):hover .button-loader-container {
  background-color: var(--transparent);
}
.filled-button:not(.disabled):hover .button-loader {
  box-shadow: 0 -2px 0 var(--hover-color);
}
.button-loader-container {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: var(--background-color);
  position: absolute !important;
  justify-content: center;
  align-items: center;
}
.filled-button.loading:hover {
  transition: none;
  color: transparent;
}
.filled-button.loading:hover svg {
  --color: transparent !important;
}
.button-loader {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  box-shadow: 0 -2px 0 var(--color);
}

.filled-button.disabled {
  background-color: #cccccc;
  border: 3px solid #cccccc;
  color: #666666;
  position: relative;
  cursor: not-allowed;
  pointer-events: auto;
}

.filled-button-container {
  position: relative;
  display: inline-block;
}

.filled-button.disabled[data-tooltip]:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--red) !important;
  color: var(--white);
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 0.85rem;
  z-index: 1000;
}

.filled-button.disabled[data-tooltip]:hover::before {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent var(--dark-blue) transparent;
  z-index: 1000;
}

.filled-button.disabled[data-tooltip]::after,
.filled-button.disabled[data-tooltip]::before {
  pointer-events: none;
  border: none;
  box-shadow: none;
  animation: none;
  opacity: 0;
  transition: opacity 0.2s;
}

.filled-button.disabled[data-tooltip]:hover::after,
.filled-button.disabled[data-tooltip]:hover::before {
  opacity: 1;
}

.filled-button[data-tooltip]:not(.disabled):hover::after,
.filled-button[data-tooltip]:not(.disabled):hover::before {
  content: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes buttonBorderBefore {
  0% {
    height: calc(100% + 3px);
    width: calc(100% + 3px);
  }
  50% {
    height: 0;
    width: calc(100% + 3px);
    right: -3px;
    border-bottom-right-radius: 0;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    width: 0;
    right: calc(100%);
  }
}

@keyframes buttonBorderAfter {
  0% {
    border-top-left-radius: 10px;
    height: calc(100% + 3px);
  }
  40% {
    border-top-left-radius: 0;
  }
  50% {
    height: calc(100% + 3px);
  }
  75% {
    height: 0;
  }
  100% {
    border-radius: 2px;
  }
}
