.une-container > a {
  width: 70%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  gap: 10px;
}
.une-picture {
  max-height: 300px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: transform 0.3s;
}
.une-tag {
  font-size: 0.8rem;
  background-color: var(--light-grey);
  width: fit-content;
  padding: 7px 10px;
  border-radius: 5px;
}
.une-title {
  font-size: 1.5rem;
  font-family: Outfit;
font-weight: 600;
}
.une-link span {
  text-decoration: underline;
}
.une-link svg {
  margin-left: 5px;
}
.une-container {
  margin-top: 30px;
  margin-bottom: 10px;
}
.une-container > a:hover .une-picture {
  transform: scale(1.03);
}
.une-container > a:hover .une-link span {
  color: var(--sky-blue);
}
.une-container > a:hover .une-link svg {
  --color: var(--sky-blue) !important;
}
.conseils-pros-container .text-hr-id {
  top: -110px;
}

.div-searchbar-article {
  width: 100%;
}

.conseils-pros-container .featured-article .thumbnail-container {
  max-width: unset !important;
  width: 100%;
}

.conseils-pros-container .featured-article .thumbnail-container img {
  max-height: 230px;
  margin-bottom: 6px;
  object-fit: cover;
  border-radius: 10px;
}

.conseils-pros-container .featured-article .thumbnail-container .thumbnail-tag {
  position: absolute;
  top: 188px;
  background-color: var(--sky-blue);
}

.conseils-pros-container .thumbnail-container .thumbnail-tag {
  color: white;
  --tag-color: var(--sky-blue);
}

.conseils-pros-container .featured-article .thumbnail-container {
  overflow: hidden;
}

.conseils-pros-container .featured-article .thumbnail-container:before {
  content: "A LA UNE";
  z-index: 10;
  position: absolute;
  transform: rotate(-40deg);
  font-size: 1.1rem;
  top: 25px;
  left: -40px;
  background-color: var(--dark-red);
  padding: 5px 50px;
  padding-left: 48px;
  color: var(--white);
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .conseils-pros-container .featured-article .thumbnail-container img {
    max-height: 200px;
  }
  .conseils-pros-container .featured-article .thumbnail-container .thumbnail-tag {
    top: 158px;
  }
}

@media (max-width: 1200px) {
  .conseils-pros-container {
    margin-top: 0;
  }
  .conseils-pros-container .featured-article .thumbnail-container img {
    max-height: 217px;
    margin-bottom: 11px;
  }
  .conseils-pros-container .featured-article .thumbnail-container .thumbnail-tag {
    top: 175px;
  }
}
