.unfilled-button {
  color: var(--color);
  font-size: var(--font-size);
  border: solid 3px var(--color);
  min-height: var(--height);
  padding: var(--padding);
  background-color: var(--transparent);
  transition:
    background-color 0.3s,
    color 0.3s;
}
.unfilled-button:hover {
  background-color: var(--color);
  color: var(--hover-color);
}
.unfilled-button:hover svg {
  --color: var(--hover-color) !important;
}
