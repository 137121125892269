.info-update-livrable {
  margin-bottom: 20px;
}

.info-update-livrable p {
  color: var(--grey);
  text-align: justify;
}

.modal-buttons-row {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}