.letter-component {
  border: 2px solid var(--gold);
  font-family: Outfit;
font-weight: 600;
  border-radius: 50%;
  padding: 10px;
  font-size: 0.9rem;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  transition: background-color 0.3s;
}
.letter-component:hover,
.letter-component:focus {
  color: var(--dark-blue);
  background-color: var(--gold);
}
.letter-component-container .components-slider {
  gap: 10px;
  margin: auto;
  padding: 15px 0;
}
.letter-component-container {
  background-color: var(--creamy-white);
  left: 0;
  z-index: 100;
}
.letter-component-container .components-slider {
  padding: 15px 5px;
  margin: 0 auto;
}
.lexique-hr {
  margin: 50px 0 15px;
}
.lexique-hr .text-hr-id {
  top: -10px;
}
.lexique-hr:first-of-type {
  margin-top: 0;
}

@media (min-width: 1200px) {
  .letter-component-container .components-slider {
    border-top: 2px solid;
    border-bottom: 2px solid;
    border-image: linear-gradient(to right, transparent, var(--gold), var(--gold), var(--gold), transparent) 1;
  }
}

@media (max-width: 1200px) {
  .letter-component-container .components-row-sticky {
    box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.15);
  }
  .lexique-hr {
    margin: 60px 0 15px;
  }
}

@media (max-width: 992px) {
  .lexique-hr {
    margin: 30px 0 10px;
  }
}

@media (max-width: 576px) {
  .lexique-hr {
    margin: 25px 0 10px;
  }
}
