.info-create-livrable p {
  color: var(--grey);
  text-justify: auto;
}

.create-deliverable-modal {
  display: flex;
  max-height: 65vh;
  flex-direction: column;
}

.create-deliverable-modal-list {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.checkbox-list-container {
  width: 100%;
}

.checkbox-list-container h3 svg {
  transition: transform 0.3s;
  margin-left: 5px;
}
.checkbox-list-container h3 {
  font-size: 1.2rem;
  text-decoration: underline;
  cursor: pointer;
}

.checkbox-list {
  width: 100%;
}

.create-deliverable-checkbox-row {
  position: relative;
}

.create-deliverable-table .item-overview-container:first-child {
  margin-top: 10px;
}

.create-deliverable-table {
  transition: max-height 0.3s;
  margin-bottom: 10px;
  max-height: 0;
}

.create-deliverable-checkbox .checkbox-container label:last-child {
  display: none;
}

.create-deliverable-checkbox .checkbox-check {
  top: 5px;
  left: 3px;
}

.create-deliverable-checkbox .checkbox-container {
  width: fit-content;
  padding: 0;
  margin: 0;
  left: 5px;
  top: -1px;
  border-width: 0;
  height: auto;
}

.create-deliverable-checkbox-row .field-container:focus-within {
  border-color: transparent;
  box-shadow: none;
}

.financingplan-checkbox {
  background-color: var(--dark-blue);
  border-radius: 10px;
}

.financingplan-checkbox label {
  color: white;
}

@media (max-width: 768px) {
  .create-deliverable-modal p {
    font-size: 0.8rem !important;
    margin: -5px 0;
  }
}
