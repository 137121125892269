.pdf-container .frame-gradient-border-container {
    padding: 3px;
    background: linear-gradient(to left, var(--gold), var(--blue), var(--gold));
    border-radius: 17px;
}
.pdf-container .frame-gradient-border-container > * {
    background-color: var(--white);
    border-radius: 15px;
  }
.pdf-container .frame-text-content {
    padding: 25px 30px;
    width: 100%;
    border-radius: 15px;
    position: relative;
    text-align: justify;
}
.pdf-container .frame-text-content br {
    display: block;
    margin-bottom: 1em;
}
