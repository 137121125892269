.faq-hr {
  margin: 60px 0 30px;
}
.faq-hr .text-hr-id {
  top: -15px;
}

@media (max-width: 1200px) {
  .faq-hr {
    margin: 60px 0 30px;
  }
}

@media (max-width: 992px) {
  .faq-hr {
    margin: 40px 0 15px;
  }
}

@media (max-width: 576px) {
  .faq-hr {
    margin: 25px 0 10px;
  }
}
