.dropdown-label {
  margin-right: 5px;
  font-size: 1rem;
  color: var(--blue);
}

.dropdown-select {
  font-size: 1rem;
  border: 2px solid var(--blue);
  border-radius: 5px;
  background-color: var(--white);
  color: var(--blue);
}

.add-news-button {
  margin-top: 20px;
}
