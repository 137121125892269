.articles-editor-buttons-row {
  display: flex;
  justify-content: center;
  gap: 20px;
  position: sticky;
  bottom: 50px;
  z-index: 10000;
}
.articles-editor-buttons-row.fullscreen {
  position: fixed;
  top: 20px;
  right: 20px;
  bottom: unset;
}
