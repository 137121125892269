.pdf-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}
.final-pdf {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.pdf-container * {
  font-weight: 300;
  color: var(--text-color);
  white-space: pre-wrap;
  text-decoration: none;
}
.pdf-container .pdf-bold {
  font-weight: 500;
}
.pdf-container .legal-information-container p:first-child {
  font-size: 1.2rem;
  margin-bottom: 20px;
  font-weight: 600;
}
.pdf-container .pdf-gold-text {
  font-weight: 400;
  color: var(--secondary-color);
  text-decoration: underline;
}
.pdf-container .pdf-text-container p {
  text-align: justify;
}
.pdf-container .pdf-text-container {
  display: flex;
  flex-direction: column;
}
.pdf-container .pdf-text-container > *:not(:last-child) {
  margin-bottom: calc(var(--default-margin) * 0.3);
}
.pdf-container .pdf-text-container h4 {
  font-size: 1.2rem;
  font-weight: 500;
}
.pdf-container .pdf-mt {
  margin-top: var(--default-margin);
}
.pdf-container .pdf-mb {
  margin-bottom: var(--default-margin);
}
.pdf-container .pdf-vertical-margin {
  margin-top: var(--default-margin);
  margin-bottom: var(--default-margin);
}
.pdf-container .pdf-default-margin {
  height: 0;
  width: 100%;
  margin-top: 30px;
}
.pdf-container .pdf-dots-list li {
  position: relative;
  left: 23px;
}
.generator-container {
  position: absolute !important;
  opacity: 0 !important;
  width: fit-content !important;
  left: -1000px !important;
  top: -1000px !important;
}
.pdf-top-scroller::before,
.pdf-top-scroller::after {
  content: "";
  z-index: 10;
  width: 3px;
  background-color: white;
  transition: transform 0.3s;
  height: 17px;
  right: 16px;
  top: 13px;
  border-radius: 2px;
  position: absolute;
}
.pdf-top-scroller::after {
  right: unset;
  left: 16px;
}
.pdf-top-scroller {
  position: relative;
  height: 46px;
  width: 46px;
  border-radius: 50%;
  background-color: var(--blue);
  cursor: pointer;
  margin: auto;
  transition:
    transform 0.3s,
    max-width 0.3s,
    margin-left 0.3s;
  overflow: hidden;
  max-width: 0;
  margin-left: 0;
}
.pdf-top-scroller.visible:before {
  transform: rotate(-45deg);
}
.pdf-top-scroller.visible:after {
  transform: rotate(45deg);
}
.pdf-top-scroller.visible {
  max-width: 46px;
  margin-left: 10px;
}
.pdf-top-scroller:hover {
  transform: translateY(-5px);
}
.pdf-container .table-pdf-container.financial-recap-table tbody td {
  padding: 10px;
  text-align: center;
}
.pdf-container .pdf-estimation-value {
  width: 100%;
  border: 3px solid var(--text-color);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  padding: 30px 0;
  font-weight: 500;
}
.pdf-container .pdf-underline {
  text-decoration: underline;
}
.pdf-container .method-estimation p {
  text-align: center !important;
}
.pdf-container .estimation-result-title {
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  font-size: 2rem;
}
.pdf-container .estimation-result-container {
  width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  padding: 30px 0;
  background-color: var(--main-color);
}
.pdf-container .estimation-result-container * {
  color: var(--secondary-text-color);
  text-align: center;
  font-size: 1.3rem;
}
.pdf-container .estimation-result-container .value {
  font-weight: 500;
  font-size: 3rem;
}
.pdf-container .pdf-large-text {
  font-size: 1.05rem;
}
.pdf-container .pdf-blue-background {
  font-weight: 400;
  background-color: var(--main-color);
  color: var(--secondary-text-color);
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  margin: 10px 0 15px !important;
}
.pdf-container .pdf-mb-sm {
  margin-bottom: 10px;
}
.pdf-container .pdf-chart-container {
  width: 100%;
  max-height: 410px;
  object-fit: contain;
  border-radius: 15px;
  border: 1px solid var(--main-color);
  padding: 30px;
}
.pdf-container .rh-table-legend {
  display: flex;
  align-items: center;
  font-weight: 300;
  justify-content: center;
}
.pdf-container .rh-table-legend div {
  width: 30px;
  margin-right: 10px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--table-type-pale);
}
.pdf-download-container {
  position: fixed;
  display: flex;
  left: 50%;
  bottom: 5vh;
  transform: translateX(-50%);
  z-index: 10;
}
.pdf-container .dots-list li:before {
  content: "";
  background-image: var(--dot);
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  height: 14px;
  width: 14px;
  left: -21px;
  top: 5px;
  filter: brightness(0) saturate(100%) invert(71%) sepia(44%) saturate(342%) hue-rotate(7deg) brightness(93%) contrast(92%);
}
.pdf-container .dots-list li {
  position: relative;
  margin-bottom: 5px;
  text-align: start;
}

.asterisk-value {
  font-size: 0.8rem;
  font-style: italic;
  color: var(--grey);
}