.contact-page-container {
  margin-top: 20px;
}

.peronal-data-information {
  margin-top: 40px;
}

.peronal-data-information p {
  font-size: 0.7rem;
  text-align: justify;
  text-justify: auto;
}

.peronal-data-information p a {
  font-size: 0.7rem;
  color: var(--sky-blue);
}

.peronal-data-information p a:hover {
  text-decoration: underline;
}