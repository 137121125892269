.articles-editor-page-container {
  display: flex;
  position: relative;
  min-height: 70vh;
}
.articles-viewer-container-background {
  position: sticky;
  top: 0;
  max-width: 0;
  height: 1px;
  z-index: 20;
}
.articles-viewer-container::-webkit-scrollbar-track {
  background: var(--white);
  border-top-left-radius: 0;
}
.articles-viewer-container {
  z-index: 20;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
  border-top-right-radius: 15px;
  box-shadow: 0 0 20px rgb(0 0 0 / 10%);
}
.articles-viewer-buttons {
  display: flex;
  gap: 10px;
  position: relative !important;
  margin-bottom: 10px;
  margin-left: auto;
  width: fit-content;
}
.article-row {
  display: grid;
  grid-template-columns: 30px calc(100% - 500px) 90px 90px 75px 80px 70px;
  overflow-x: hidden;
  min-height: 40px;
  gap: 10px;
  background-color: var(--white);
  border-radius: 5px;
  padding: 5px;
}
.article-row * {
  font-size: 0.9rem;
}
.article-row .button {
  font-size: 0.8rem !important;
  padding: 3px 5px !important;
  box-shadow: none !important;
}
.closed .article-row {
  grid-template-columns: 30px calc(100% - 120px) 70px;
}
.article-row > div:nth-child(2) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: auto;
}
.articles-viewer-container {
  left: -60vw;
}
.articles-viewer-container,
.articles-viewer-header::before {
  background-color: var(--beige);
  max-width: 1000px;
  width: 70vw;
  transition: width 0.5s;
}
.articles-viewer-container {
  left: -20vw;
}
.articles-viewer-container.closed {
  width: 20vw;
}
.articles-viewer-header * {
  z-index: 210;
  position: relative;
}
.articles-viewer-header {
  z-index: 200;
  position: sticky;
  margin-top: -30px;
  top: 0px;
  background-color: var(--white);
  padding-top: 20px;
  padding-bottom: 15px;
  margin-bottom: 40px;
}
.articles-viewer-header::before {
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
  z-index: 20;
  content: "";
  background-color: var(--white);
  position: absolute;
  top: -30px;
  left: -30px;
  height: calc(100% + 30px);
}
.article-row div {
  height: fit-content;
  margin: auto 0;
}
.articles-editor-searchbar {
  margin-bottom: 10px;
  width: 100%;
  min-width: 0;
}
.article-row.first-row {
  background-color: var(--beige);
}
.article-row.first-row div {
  cursor: pointer;
}
.article-row.first-row div svg {
  transition: transform 0.3s;
}
.mobile-toggle {
  display: none;
}

@media (max-width: 1200px) {
  .articles-viewer-container.closed {
    width: 0;
    padding: 0;
  }

  .articles-viewer-toggle:not(.mobile-toggle) {
    display: none;
  }
  .articles-viewer-container,
  .articles-viewer-header::before {
    width: 90vw;
    padding: 20px;
  }
  .mobile-toggle {
    --padding: 20px !important;
    display: flex;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    position: absolute;
    left: 90vw;
    transition: left 0.5s;
    z-index: 1000;
  }
  .mobile-toggle.closed {
    left: 0;
  }
}
.article-status-published {
  background-color: var(--green);
}
.article-status-unpublished {
  background-color: var(--red);
}
.article-status-draft {
  background-color: var(--grey);
}
.article-status-published,
.article-status-unpublished,
.article-status-draft {
  font-size: 0.8rem;
  display: flex;
  padding: 6px 5px;
  cursor: context-menu;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 10px;
  opacity: 0.8;
}

@media (max-width: 576px) {
  .article-row div:first-child,
  .article-row div:nth-child(3),
  .article-row div:nth-child(4) {
    display: none;
  }
  .article-row {
    grid-template-columns: auto 70px 70px;
  }
  .articles-viewer-container,
  .articles-viewer-header::before {
    width: 100vw;
    padding: 20px;
  }
  .mobile-toggle {
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    left: calc(100vw - 60px);
  }
  .articles-viewer-buttons {
    margin-left: 10px;
  }
  .articles-viewer-header::before {
    height: 100%;
  }
}
