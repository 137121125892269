.panier-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 40px auto;
}

/*Quand Panier vide */
.panier-vide-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: fit-content;
  margin: auto;
  max-width: 800px;
}

.panier-vide-card h2 {
  font-size: 1.6rem;
  margin-bottom: 20px;
  font-family: Outfit;
font-weight: 500;
}

.panier-vide-card p {
  font-size: 1.2rem;
  margin-bottom: 20px;
  font-family: Outfit;
font-weight: 500;
}

.redirection-tarif-button {
  --padding: 10px 25px !important;
  --font-size: 1.3rem !important;
  margin-top: 20px;
  width: fit-content;
}

/*Quand Panier remplie */
.panier-remplie-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
  width: 100%;
}

.cart-table {
  width: 100%;
  border-collapse: collapse;
}

.cart-table th {
  text-transform: uppercase;
}

.cart-table th,
.cart-table td {
  text-align: left;
}

.selection-offre-cart {
  font-family: Outfit;
font-weight: 900;
  text-transform: uppercase;
}

.quantite-offre-cart {
  width: 15%;
}

.prix-ht-offre-cart {
  text-align: right !important;
  width: 15%;
}

.abonnement-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
}

.abonnement-card h2 {
  font-size: 1.6rem;
  margin-bottom: 10px;
  font-family: Outfit;
font-weight: 600;
}

.check-min-width {
  min-width: 15px;
}

.details-1-cart {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  width: 100%;
  max-width: 400px;
  font-family: Outfit;
font-weight: 700;
  list-style: none;
}

.toggle-button {
  display: none;
}

.engagement-abonnement-cart {
  font-family: Outfit;
font-weight: 700;
  font-size: 1.3rem;
  color: var(--sky-blue);
  padding-top: 10px;
}

.prix-details-section-cart {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.prix-details-section-cart::before {
  content: "";
  display: block;
  width: 30%;
}

.prix-details-cart {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 30%;
}

.total-ht {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.total-tva {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.total-reduction {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: var(--sky-blue);
}

.total-reduction p {
  color: var(--sky-blue);
  font-family: Outfit;
font-weight: 700;
}

.total-ttc {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.total-ttc p {
  font-family: Outfit;
font-weight: 900;
}

.button-suivant-cart {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

/*Responsive*/
@media (max-width: 992px) {
  .panier-container {
    margin-top: 20px;
    padding: 0;
  }

  .abonnement-card {
    margin: 0;
    padding: 15px;
  }

  .ml-md.dots-list.show {
    display: block;
  }

  .details-2-cart {
    display: none;
  }

  .details-2-cart.show {
    display: block;
  }

  .toggle-button {
    display: inline-flex;
    cursor: pointer;
    font-size: 0.8rem;
    display: flex;
    margin-top: 5px;
    margin-left: 20px;
    text-decoration: underline;
    font-family: Outfit;
font-weight: 700;
    align-items: center;
    justify-content: flex-start;
  }

  .inclus-offre-cart li {
    font-size: 0.9rem;
  }

  .prix-ht-offre-cart {
    text-align: right !important;
  }

  .engagement-abonnement-cart {
    font-size: 0.9rem;
  }
}

@media (max-width: 576px) {
  .panier-remplie-container {
    margin: 0;
  }

  .abonnement-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    padding: 0 auto;
  }

  .cart-table th {
    font-size: 0.8rem;
  }

  .details-2-cart {
    display: block;
  }

  .mobile-details-link {
    display: block;
    cursor: pointer;
    text-decoration: underline;
    color: var(--link-blue);
  }

  .cart-table th,
  .cart-table td {
    text-align: center;
  }

  .prix-ht-offre-cart {
    width: auto;
  }

  .prix-details-section-cart {
    display: flex;
    flex-direction: column;
  }

  .prix-details-cart {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
  }

  .button-suivant-cart {
    display: flex;
    justify-content: center;
  }
}
