.methode-row-border {
  display: flex;
  justify-content: space-between;
}
.methode-row-border img {
  height: 250px;
}
.row-border,
.row-border-right {
  padding: 45px 30px;
  position: relative;
  max-width: 650px;
}
.row-border {
  margin-right: 100px;
  background-color: var(--creamy-white);
  border-radius: 8px;
}
.row-border-right {
  margin-left: 100px;
  background-color: var(--creamy-white);
  border-radius: 8px;
}
.row-border:before,
.row-border-right:before {
  content: "";
  z-index: -1;
  position: absolute;
  height: 100%;
  top: -4px;
  left: -4px;
  width: 200px;
  background: linear-gradient(to left, transparent, transparent, var(--dark-blue), var(--gold));
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  border-radius: 10px;
  padding: 4px;
}
.row-border-right:before {
  left: auto;
  right: -4px;
  background: linear-gradient(to right, transparent, transparent, var(--dark-blue), var(--gold));
}
.border-part-right {
  left: calc(100% - 200px);
  transform: rotate(180deg);
}
.methode-row-border-img-left {
  margin-right: 50px;
}
.methode-row-border-img-right {
  margin-left: 50px;
}
.methode-row-border-right p {
  text-align: right;
}

@media (max-width: 1200px) {
  .methode-row-border img {
    max-width: 200px;
    height: auto;
    object-fit: scale-down;
  }
}
@media (max-width: 992px) {
  .methode-row-border {
    flex-direction: column-reverse;
  }
  .methode-row-border-right {
    flex-direction: column;
  }
  .row-border,
  .row-border-right {
    margin: 0 30px;
    padding-top: 0;
  }
  .row-border:before,
  .row-border-right:before {
    display: none;
  }
  .methode-row-border p {
    text-align: center;
  }
  .methode-row-border img {
    margin: 0 auto 20px;
  }
  .border-part,
  .border-part-right {
    display: none;
  }
}
@media (max-width: 576px) {
  .row-border,
  .row-border-right {
    padding: 20px 20px;
    margin: 0;
  }
  .row-border {
    text-align: left;
  }
  .row-border-right {
    text-align: right;
  }
  .row-border:before,
  .row-border-right:before {
    display: block;
  }
  .methode-row-border p {
    text-align: left;
  }
  .methode-row-border-right p {
    text-align: right;
  }
}
