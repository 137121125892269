.credit-simulator-form-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.reset-credit-simulator-form {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.reset-credit-simulator-form p {
  color: var(--red);
  text-decoration: underline;
}

.reset-credit-simulator-form p:hover {
  cursor: pointer;
}
