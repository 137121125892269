.exemples-rapports-container {
  justify-content: space-around;
  text-transform: uppercase;
  margin-bottom: 100px;
}
.exemples-rapports-container p {
  text-align: center;
  font-size: 1.3rem;
  margin-bottom: 25px;
  margin-top: 15px;
  font-family: Outfit;
font-weight: 700;
}
.hover-exemples-rapports-immeuble {
  transition: transform 0.3s ease-in-out;
}
.hover-exemples-rapports-immeuble:hover {
  transform: rotate(-5deg) scale(1.05);
  cursor: pointer;
}
.hover-exemples-rapports-commerce {
  transition: transform 0.3s ease-in-out;
}
.hover-exemples-rapports-commerce:hover {
  transform: scale(1.05);
  cursor: pointer;
}
.hover-exemples-rapports-usine {
  transition: transform 0.3s ease-in-out;
}
.hover-exemples-rapports-usine:hover {
  transform: rotate(5deg) scale(1.05);
  cursor: pointer;
}
.rapport-example {
  max-width: 300px;
  box-shadow: 0px 4.05904px 12.17713px 8.11809px rgba(0, 0, 0, 0.25);
  margin-bottom: 30px;
}
@media (max-width: 1200px) {
  .exemples-rapports-container {
    margin-bottom: 0;
  }
  .rapport-example {
    max-width: 200px;
  }
}

@media (max-width: 576px) {
  .rapport-example {
    margin-bottom: 20px;
  }
  .exemples-rapports-container p {
    margin-top: 5px;
    margin-bottom: 15px;
  }
  .components-row-container.exemples-rapports-container .button {
    margin-bottom: 10px;
  }
  .components-row-container.exemples-rapports-container .components-slider > div {
    margin: 0 20px;
  }
  .exemples-rapports-container {
    gap: 40px;
    margin-bottom: 30px;
    text-align: center;
  }
}
