.thumbnails-group-container {
  display: flex;
  flex-wrap: wrap;
  margin: var(--margin);
}

.thumbnails-group-container .thumbnail-group-unit {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.thumbnail-group-unit .thumbnail-container {
  height: calc(100% - 60px);
}

@media (min-width: 1200px) {
  .thumbnails-group-container .thumbnail-group-unit:nth-child(4n-1),
  .thumbnails-group-container .thumbnail-group-unit:nth-child(4n-2) {
    border: solid var(--gold) 2px;
    border-bottom-width: 0;
    border-left-width: 0;
  }
  .thumbnails-group-container .thumbnail-group-unit:nth-child(4n-3) {
    border-top: solid 2px;
    border-right: solid 2px;
    border-image: linear-gradient(to left, var(--gold), var(--gold), transparent) 1;
  }
  .thumbnails-group-container .thumbnail-group-unit:nth-child(4n) {
    border-top: solid 2px;
    border-image: linear-gradient(to right, var(--gold), var(--gold), transparent) 1;
  }
  .thumbnail-group-unit:nth-last-child(-n + 4) .thumbnail-container {
    height: calc(100% - 30px);
    margin-bottom: 0;
  }
  .thumbnails-group-container .thumbnail-group-unit.featured-article {
    width: 50%;
    border-image: linear-gradient(to left, var(--gold), var(--gold), transparent) 1;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .thumbnails-group-container .thumbnail-group-unit:nth-child(3n-1) {
    border: solid var(--gold) 2px;
    border-bottom-width: 0;
  }
  .thumbnails-group-container .thumbnail-group-unit:nth-child(3n-2) {
    border-top: solid 2px;
    border-image: linear-gradient(to left, var(--gold), var(--gold), transparent) 1;
  }
  .thumbnails-group-container .thumbnail-group-unit:nth-child(3n) {
    border-top: solid 2px;
    border-image: linear-gradient(to right, var(--gold), var(--gold), transparent) 1;
  }
  .thumbnail-group-unit:nth-last-child(-n + 3) .thumbnail-container {
    height: calc(100% - 30px);
    margin-bottom: -10px;
  }
  .thumbnails-group-container .thumbnail-group-unit {
    width: 33%;
  }
  .thumbnails-group-container .thumbnail-group-unit.featured-article {
    width: 66%;
    border-image: linear-gradient(to right, var(--gold), var(--gold), transparent) 1;
    border-image: linear-gradient(to left, var(--gold), var(--gold), transparent) 1;
  }
}

@media (max-width: 992px) {
  .thumbnails-group-container .thumbnail-group-unit:nth-child(2n-1) {
    border-top: solid 2px;
    border-image: linear-gradient(to left, var(--gold), var(--gold), transparent) 1;
  }
  .thumbnails-group-container .thumbnail-group-unit:nth-child(2n) {
    border-top: solid 2px;
    border-left: solid 2px;
    border-image: linear-gradient(to right, var(--gold), var(--gold), transparent) 1;
  }
  .thumbnails-group-container .thumbnail-group-unit {
    width: 50%;
  }
  .thumbnail-group-unit:nth-last-child(-n + 2) .thumbnail-container {
    height: calc(100% - 30px);
    margin-bottom: -10px;
  }
  .thumbnails-group-container .thumbnail-group-unit.featured-article {
    width: 100%;
    border-image: linear-gradient(to left, transparent, var(--gold), var(--gold), transparent) 1;
  }
}

@media (max-width: 576px) {
  .thumbnails-group-container .thumbnail-group-unit {
    width: 100%;
  }
  .thumbnails-group-container .thumbnail-container {
    margin: 15px 0 !important;
    height: auto;
  }
  .thumbnails-group-container .thumbnail-group-unit {
    border: 0 !important;
  }
}
