.methode-title-container h2,
.methode-container h2 span {
  display: flex;
  align-items: center;
  font-size: 3rem;
  font-family: Outfit;
font-weight: 600;
  text-transform: uppercase;
  gap: 18px;
}
.methode-title-container h2 span {
  font-family: Montserrat-Bold;
  font-size: 6rem;
  margin-top: -8px;
  -webkit-text-stroke: 3px var(--gold);
}
.methode-title-container p {
  position: relative;
  top: -12px;
  font-size: 1.5rem;
  font-family: Outfit;
font-weight: 600;
}
@media (max-width: 1200px) {
  .methode-title-container h2 span {
    margin-top: 8px;
    margin-bottom: 3px;
    font-size: 5rem;
    -webkit-text-stroke: 2px var(--gold);
  }
}
@media (max-width: 576px) {
  .methode-title-container h2,
  .methode-container h2 span {
    font-size: 1.5rem;
    margin-bottom: 5px;
  }
  .methode-title-container h2 span {
    margin-top: 5px;
    font-size: 4rem;
    font-family: Outfit;
font-weight: 700;
    -webkit-text-stroke: 3px var(--gold);
  }
}
