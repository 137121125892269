.recap-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 40px auto;
}

.recap-card-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
  width: 100%;
}

.abonnement-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
}

.abonnement-card h2 {
  font-size: 1.6rem;
  margin-bottom: 10px;
  font-family: Outfit;
font-weight: 600;
}

.recap-table {
  width: 100%;
  border-collapse: collapse;
}

.recap-table thead {
  width: 100%;
}

.nom-offre-thead {
  width: 30%;
}

.inclus-offre-thead {
  width: 70%;
}

.recap-table th {
  text-transform: uppercase;
}

.recap-table th,
.recap-table td {
  text-align: center;
}

.selection-offre-recap {
  font-family: Outfit;
font-weight: 900;
  text-transform: uppercase;
}

.inclus-offre-recap ul {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-left: 25%;
}

.check-min-width {
  min-width: 15px;
}

.details-1-recap {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  width: 100%;
  max-width: 400px;
  font-family: Outfit;
font-weight: 700;
  list-style: none;
}

.engagement-abonnement-recap {
  font-family: Outfit;
font-weight: 700;
  font-size: 1.3rem;
  color: var(--sky-blue);
  padding-top: 10px;
  display: flex;
  justify-content: center;
  text-transform: none;
}

.checkbox-commande-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  max-width: 800px;
  text-align: justify;
  margin: 0 auto;
}

.checkbox-commande-container a:not(.button) {
  color: var(--sky-blue);
  text-decoration: none;
}
.checkbox-commande-container a:hover:not(.button) {
  text-decoration: underline;
}

.obligatoire-checkbox-form p {
  font-family: Outfit;
  font-weight: 500;
  font-style: italic;
  font-size: 0.9rem;
  margin-left: 30px;
}

.button-suivant-recap {
  margin-top: 20px;
  display: flex;
  gap: 20px;
  justify-content: center;
  width: 100%;
}

.commande-form {
  display: flex;
  flex-direction: column;
}

/*Responsive*/
@media (max-width: 992px) {
  .recap-container {
    margin-top: 20px;
    padding: 0;
  }

  .abonnement-card {
    margin: 0;
    padding: 15px;
  }

  .inclus-offre-recap ul {
    margin-left: 10%;
  }

  .inclus-offre-recap li {
    font-size: 0.9rem;
  }

  .engagement-abonnement-recap {
    font-size: 0.8rem;
  }

  .checkbox-commande-container {
    margin-top: 10px;
  }
}

@media (max-width: 576px) {
  .recap-card-container {
    margin: 0;
  }

  .abonnement-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    padding: 0 auto;
  }

  .recap-table th {
    font-size: 0.8rem;
  }

  .recap-table td {
    text-align: left;
  }

  .engagement-abonnement-recap {
    display: flex;
    justify-content: flex-start;
    text-align: left;
    font-size: 1rem;
    margin-left: 21px;
  }

  .selection-offre-recap h3 {
    text-align: center;
  }

  .details-1-recap {
    align-items: left;
  }

  .recap-inclus-offre {
    display: flex;
    flex-direction: column;
    align-items: start;
    text-transform: none;
  }

  .button-suivant-recap {
    display: flex;
    justify-content: center;
  }

  .checkbox-commande-container {
    margin-top: 50px;
  }
}
