.pdf-container .estimation-calcs-container .frame-text-content > * {
  margin-bottom: 5px;
}
.pdf-container .estimation-calcs-container .frame-text-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pdf-container .estimation-calcs-container * {
  text-align: center;
}
.pdf-container .estimation-calcs-container .pdf-bold span {
  position: relative;
  top: -6px;
  font-size: 0.8rem;
  font-weight: 500;
  left: 3px;
}
.pdf-container .estimation-calcs-label-low {
  color: var(--third-text-color);
}
.pdf-container .estimation-calcs-label-mid {
  color: var(--secondary-color);
}
.pdf-container .estimation-calcs-label-mid + div {
  font-weight: 400;
}
.estimation-calcs-label-high {
  color: var(--text-color);
}
.pdf-container .estimation-calcs-label-low,
.pdf-container .estimation-calcs-label-mid,
.pdf-container .estimation-calcs-label-high {
  font-weight: 500;
  margin-bottom: 5px;
}
.pdf-container .estimation-calcs-row > div {
  width: 100%;
}
.pdf-container .estimation-calcs-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pdf-container .estimation-calcs-divider {
  background-color: var(--secondary-color);
  height: 2px;
  border-radius: 3px;
  width: 60px;
  margin: 0 30px;
}
.pdf-container .estimation-calcs-container .frame-text-content .estimation-calcs-legend {
  font-size: 0.9rem;
  font-weight: 300;
  color: var(--secondary-color);
  margin-bottom: 10px;
}
