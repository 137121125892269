.text-hr-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-family: Outfit;
font-weight: 700;
}
.text-hr-id {
  position: absolute;
  top: -20px;
}
.text-hr-side {
  position: absolute;
  width: 100%;
  height: 3px;
  border-radius: 3px;
  margin-right: 20px;
  background: linear-gradient(90deg, var(--colors) 100%);
  transform: rotate(180deg);
}
.text-hr-content {
  display: flex;
  flex-wrap: wrap;
  z-index: 2;
  width: 100%;
  max-width: calc(80% - 20px);
  min-width: min-content;
  justify-content: center;
  font-weight: inherit;
  font-family: inherit;
  font-style: inherit;
  background-color: var(--creamy-white);
  padding: 0 10px;
}
.text-hr-content span {
  text-transform: uppercase;
  font-size: var(--font-size);
  font-weight: inherit;
  font-family: inherit;
  font-style: inherit;
}
