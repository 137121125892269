.paginator-container {
  margin: 40px 0 20px;
}
.paginator-container,
.paginator-indexes {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.paginator-index,
.paginator-button {
  cursor: pointer;
  color: var(--blue);
  display: flex;
  justify-content: center;
  padding: 10px 0;
  font-size: 1.2rem;
}
.paginator-button {
  gap: 5px;
}
.paginator-index-active,
.paginator-index:hover,
.paginator-button:hover {
  text-decoration: underline;
}
.paginator-index,
.paginator-dots {
  width: 25px;
  font-size: 1.2rem;
}
.paginator-button.disabled {
  color: var(--grey);
  cursor: auto;
}
.paginator-button.disabled svg {
  --color: var(--grey) !important;
}
.paginator-button.disabled:hover {
  text-decoration: none;
}

@media (max-width: 576px) {
  .paginator-container {
    flex-direction: column;
    gap: 0;
  }
  .paginator-button {
    padding: 0;
  }
}
