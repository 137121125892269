.activites-list {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 650px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 9999;
  font-size: 0.85rem;
  max-height: 410px;
  row-gap: 0;
  position: absolute;
  height: auto;
  background-color: rgb(255, 255, 255);
  box-shadow: grey 3px 3px 7px;
  transition:
    max-height 0.3s,
    padding 0.3s;
  list-style: none;
}
.activites-list li {
  font-family: Outfit;
  font-weight: 700;
  font-size: 0.8rem;
  position: relative;
  padding: 5px;
  padding-left: 10px;
  color: var(--blue-focus);
  transition:
    color 0.3s,
    background-color 0.3s;
}
.activites-list li:not(.activites-no-result) {
  cursor: pointer;
}
.activites-list li:not(.activites-no-result):hover {
  color: var(--white);
  background-color: var(--dark-blue);
}
