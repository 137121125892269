.access-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.access-page h1 {
  margin-bottom: 20px;
  color: #313b5a;
}

.access-page input {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.access-page button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #313b5a;
  color: white;
  cursor: pointer;
}

.access-page button:hover {
  background-color: #262b3f;
}
