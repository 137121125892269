.pdf-container .cover-pdf-container {
  display: flex;
  flex-direction: column;
  max-height: 500px;
}
.pdf-container .cover-pdf-logo {
  width: 60%;
  margin: 30px auto auto;
  height: 100px;
  object-fit: contain;
  border: none !important;
}
.pdf-container .cover-pdf-container h1 {
  margin: 50px 0;
}
.pdf-container .cover-pdf-container h1,
.cover-pdf-container h1 span {
  font-size: 2rem;
  text-align: center;
  font-weight: 600;
}
.pdf-container .cover-pdf-container h1 span {
  font-weight: 300;
}
.pdf-container .cover-pdf-container > div {
  margin: auto;
  text-align: center;
  border: 1px solid var(--dark-blue);
  border-radius: 15px;
  padding: 30px;
  width: 90%;
  max-width: 90%;
}
.pdf-container .cover-pdf-container * {
  font-weight: 400;
}
.pdf-container .pdf-cover-name {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 10px;
}
.pdf-container .pdf-cover-address {
  margin-top: 10px;
}
.pdf-container .pdf-cover-address,
.pdf-cover-name {
  font-weight: 500;
}
.pdf-container .pdf-cover-title,
.pdf-cover-address {
  font-size: 1.2rem;
}
.pdf-container .cover-pdf-container.type-murs .pdf-business-image {
  bottom: 50px;
}
.pdf-container .cover-pdf-container .pdf-business-image {
  position: absolute;
  bottom: 60px;
  width: 550px;
  height: 420px;
  object-fit: cover;
  border-radius: 15px;
  margin: 0 auto;
  left: 0;
  right: 0;
}
