.first-input {
  width: 100% !important;
  padding: 10px !important;
}
.double-input-container {
  gap: 20px;
}
.double-input-container svg {
  margin: auto ;
}
.first-input::placeholder {
  color: var(--grey-dark);
  font-style: italic;
}
@media (max-width: 576px) {
  .double-input-container {
    margin-top: 15px;
    gap: 5px;
  }
}
