.sticky-searchbar-container.pinned {
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}
.sticky-searchbar-container {
  --top: 0;
  position: sticky;
  top: var(--top);
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: var(--margin);
  height: 80px;
  transition: top 0.3s;
  width: fit-content;
  padding: 0px 20px;
  gap: 15px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: var(--creamy-white);
}
.sticky-searchbar-container .search-bar-container {
  max-width: 300px;
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
}
.filtre-article-button {
  display: flex;
  flex-direction: column;
  margin: auto 0;
  height: 40px;
}
.searchbar-filter-container * {
  cursor: pointer;
}
.searchbar-filter-container {
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
}
.searchbar-filter-label {
  margin-right: 5px;
  font-size: 1rem;
  color: var(--blue);
}
.searchbar-filter-select {
  font-size: 1rem;
  padding: 3px 5px;
  border: 2px solid var(--blue);
  border-radius: 5px;
  background-color: var(--white);
  color: var(--blue);
}

.add-news-button {
  margin-top: 20px;
}

@media (max-width: 1200px) {
  .sticky-searchbar-container {
    margin-bottom: 0;
    flex-direction: column;
    gap: 10px;
  }
}

@media (max-width: 576px) {
  .sticky-searchbar-container {
    transform: none;
    left: unset;
    margin: 0 auto;
  }
  .searchbar-filter-container {
    flex-direction: column;
  }
}
