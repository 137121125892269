.folder-detail-page-container {
  display: flex;
  min-height: 70vh;
}

.folder-detail-page-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.folder-sidebar {
  width: 20%;
  padding: 20px;
  margin-right: 10px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 0 20px rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.folder-header-sidebar {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}

.folder-header-sidebar h2 {
  font-weight: 600;
  font-size: 1.2rem;
  text-transform: uppercase;
}

.folder-tabs {
  list-style: none;
  padding: 0;
  width: 100%;
}

.folder-tab {
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: var(--white);
  transition: background-color 0.1s ease;
  border: 1px solid transparent;
}

.folder-tab.active {
  background-color: #f5f5f5;
}

.folder-tab:nth-child(2) svg {
  margin-left: -2px;
  margin-right: 2px;
}

.folder-tab:nth-child(3) svg {
  margin-left: 2px;
  margin-right: 2px;
}

.folder-tab:hover {
  border: 1px solid #f5f5f5;
}
.folder-actions {
  margin-top: auto;
  width: 100%;
}
.action-button-folder {
  margin-top: 5px;
  width: 100%;
}

.folder-content {
  width: 80%;
  padding: 20px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 0 20px rgb(0 0 0 / 10%);
}

@media (max-width: 1200px) {
  .folder-detail-page-container {
    flex-direction: column;
  }

  .folder-sidebar {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
    flex-direction: column;
    justify-content: space-between;
  }

  .folder-header-sidebar {
    flex-direction: row;
    margin-bottom: 0;
    justify-content: center;
  }

  .folder-header-sidebar h2 {
    font-size: 1.5rem;
    text-align: center;
  }

  .folder-tabs {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
  }

  .folder-tab {
    margin-bottom: 0;
    font-size: 1.2rem;
    text-align: center;
  }

  .folder-actions {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .folder-content {
    width: 100%;
    padding: 15px;
  }
}

@media (max-width: 992px) {
  .folder-header-sidebar h2 {
    font-size: 1.2rem;
  }

  .folder-tab {
    padding: 6px 8px;
  }

  .folder-content {
    padding: 15px;
  }
}

@media (max-width: 576px) {
  .folder-sidebar {
    flex-direction: column;
    align-items: center;
  }

  .folder-header-sidebar {
    align-items: center;
  }
  .folder-header-sidebar h2 {
    font-size: 0.9rem;
    text-align: center;
  }

  .folder-tabs {
    flex-direction: column;
    width: 100%;
  }

  .folder-tab {
    padding: 5px;
    font-size: 0.8rem;
  }

  .folder-content {
    padding: 15px 10px;
  }

  .action-button-folder {
    font-size: 0.8rem;
  }
}
