.practical-tool-container {
  display: flex;
  gap: 30px;
  overflow: hidden;
  justify-content: space-between;
}
.practical-tool-description {
  margin-top: 15px;
}
.practical-tool-preview-container img {
  height: auto;
  width: 250px;
}
.practical-tool-preview-container {
  max-width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  gap: 15px;
  height: fit-content;
  position: relative;
}
.practical-tool-preview-container .button {
  position: absolute;
}

@media (max-width: 992px) {
  .practical-tool-container {
    flex-direction: column-reverse;
  }
  .practical-tool-preview-container {
    max-width: 100%;
    flex-direction: column;
  }
}
