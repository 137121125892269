.newsletter-form-container {
  padding-left: 10px;
}
.newsletter-checkbox-container {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}
.newsletter-checkbox-container label {
  padding: 3px 0;
}
.newsletter-form-container input {
  border-radius: 6px;
  border: 3px solid var(--light-gold);
  background-color: transparent;
  transition: all 0.3s ease;
}
.newsletter-checkbox-container input {
  appearance: none;
  min-width: 30px;
  min-height: 30px;
  max-width: 30px;
  max-height: 30px;
  cursor: pointer;
}
.newsletter-checkbox-container input:checked {
  background-color: var(--light-gold);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.newsletter-form-container p {
  margin: 40px 0;
}
.newsletter-form-container a {
  color: var(--gold);
}
.newsletter-form-container input[type="email"] {
  outline: 0;
  width: 100%;
  font-size: 1rem;
  padding: 8px;
  margin-bottom: 5px;
}
.newsletter-form-container input[type="email"]:focus {
  border-radius: 4px;
  border-color: transparent;
  border-bottom-color: var(--gold);
}
.newsletter-error {
  color: var(--red);
}

@media (max-width: 1200px) {
  .newsletter-form-container p {
    margin: 20px 0;
  }
  .newsletter-form-container input[type="email"] {
    margin-bottom: 20px;
  }
}
