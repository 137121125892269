@font-face {
  font-family: "Montserrat-Bold";
  src:
    local("Montserrat-Bold"),
    url("../../assets/fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Black";
  src:
    local("Montserrat-ExtraBold"),
    url("../../assets/fonts/Montserrat-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src:
    local("Montserrat-SemiBold"),
    url("../../assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Medium";
  src:
    local("Montserrat-Medium"),
    url("../../assets/fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Outfit";
  font-weight: 900;
  font-style: normal;
  src:
    local("Outfit-Black"),
    url("../../assets/fonts/Outfit-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Outfit";
  font-weight: 700;
  font-style: normal;
  src:
    local("Montserrat-Bold"),
    url("../../assets/fonts/Outfit-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Outfit";
  font-weight: 600;
  font-style: normal;
  src:
    local("Outfit-SemiBold"),
    url("../../assets/fonts/Outfit-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Outfit";
  font-weight: 500;
  font-style: normal;
  src:
    local("Outfit-Medium"),
    url("../../assets/fonts/Outfit-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Outfit";
  font-weight: 300;
  font-style: normal;
  src:
    local("Outfit-Medium"),
    url("../../assets/fonts/Outfit-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Outfit";
  font-weight: 200;
  font-style: normal;
  src:
    local("Outfit-Medium"),
    url("../../assets/fonts/Outfit-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src:
    local("Poppins-Bold"),
    url("../../assets/fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-BoldItalic";
  src:
    local("Poppins-BoldItalic"),
    url("../../assets/fonts/Poppins-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Light";
  src:
    local("Poppins-Light"),
    url("../../assets/fonts/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-LightItalic";
  src:
    local("Poppins-LightItalic"),
    url("../../assets/fonts/Poppins-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Medium";
  src:
    local("Poppins-Medium"),
    url("../../assets/fonts/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-MediumItalic";
  src:
    local("Poppins-MediumItalic"),
    url("../../assets/fonts/Poppins-MediumItalic.ttf") format("truetype");
}

.montserrat-black {
  font-family: Montserrat-Black !important;
  font-style: normal !important;
}
.montserrat-medium {
  font-family: Montserrat-Medium !important;
  font-style: normal !important;
}
.montserrat-semibold {
  font-family: Montserrat-SemiBold !important;
  font-style: normal !important;
}
.montserrat-bold {
  font-family: Montserrat-Bold !important;
  font-style: normal !important;
}

.outfit-black {
  font-family: Outfit !important;
  font-weight: 900 !important;
  font-style: normal !important;
}
.outfit-medium {
  font-family: Outfit !important;
  font-weight: 500 !important;
  font-style: normal !important;
}
.outfit-semibold {
  font-family: Outfit !important;
  font-weight: 600 !important;
  font-style: normal !important;
}
.outfit-bold {
  font-family: Outfit !important;
  font-weight: 700 !important;
  font-style: normal !important;
}

.poppins-bold {
  font-family: Poppins-Bold !important;
  font-style: normal !important;
}
.poppins-medium {
  font-family: Poppins-Medium !important;
  font-style: normal !important;
}
.poppins-light {
  font-family: Poppins-Light !important;
  font-style: normal !important;
}

.poppins-bold-it {
  font-family: Poppins-BoldItalic !important;
  font-style: normal !important;
}
.poppins-medium-it {
  font-family: Poppins-MediumItalic !important;
  font-style: normal !important;
}
.poppins-light-it {
  font-family: Poppins-LightItalic !important;
  font-style: normal !important;
}
