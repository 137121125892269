.page-title-container h1 {
  width: fit-content;
  text-align: center;
  margin: var(--margin);
  border-style: solid;
  border-image: linear-gradient(to right, transparent, var(--gold), var(--gold), transparent) 1;
  border-width: 3px;
  font-size: 2rem;
  padding: 12px 0 10px;
}

@media (max-width: 576px) {
  .page-title-container h1 {
    font-size: 1.5rem;
  }
}
