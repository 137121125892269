.homepage-card-container {
  width: 100%;
  background-color: var(--light-gold);
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  position: relative;
  margin-top: 100px;
}
.homepage-card-number {
  position: absolute;
  font-size: 8rem;
  -webkit-text-stroke: 8px var(--light-gold);
  right: 0;
  left: 0;
  margin: auto;
  top: -100px;
  font-family: Montserrat-Bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.homepage-card-container p {
  color: black;
}

.homepage-card-container p:first-of-type {
  margin-bottom: 14px;
  margin-top: 16px;
  font-family: Outfit;
font-weight: 600;
  font-size: 2rem;
}

.homepage-card-container p:last-of-type {
  font-family: Outfit;
font-weight: 600;
  font-size: 1.2rem;
}

@media (max-width: 1200px) {
  .homepage-card-container p:first-of-type {
    font-size: 1.5rem;
  }
  .homepage-card-container p:last-of-type {
    font-size: 1.1rem;
  }
}

@media (max-width: 576px) {
  .homepage-card-container {
    padding: 15px;
  }
}
