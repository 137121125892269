.methode-container h3 {
  font-size: 1.5rem;
  font-family: Outfit;
font-weight: 700;
}
.methode-container .text-hr-content span {
  font-size: 2.7rem;
}
.methode-img {
  max-width: 500px;
  max-height: 500px;
}
.methode-img-400 {
  max-width: 400px;
  max-height: 400px;
}
.methode-img-sm {
  max-width: 300px;
  max-height: 300px;
}
.methode-img-ssm {
  max-width: 250px;
  max-height: 250px;
  object-fit: contain;
}
.bottom-rounded-div {
  position: relative;
  top: -5px;
}
.methode-img-xs {
  max-width: 200px;
  max-height: 200px;
}
.gold-before-section {
  position: relative;
  text-align: left;
  padding-left: 30px;
  margin-left: auto;
}
.methode-estimation-fdc {
  margin-left: 100px;
  margin-right: 100px;
}
.gold-before-section:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 3px;
  top: 0;
  border-radius: 2px;
  left: 0;
  background: linear-gradient(to bottom, var(--gold), transparent);
}
.gradient-card-container {
  max-width: 40%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.gradient-card-container img {
  z-index: 2;
}
.gradient-card-container:first-of-type img {
  margin: -40px auto 0;
}
.gradient-card-container:last-of-type img {
  margin: 0 auto -40px;
}
.gradient-card {
  border-radius: 15px;
  background: linear-gradient(to bottom, var(--color), transparent);
  padding: 50px;
}
.gradient-card p {
  text-align: center;
  line-height: 30px;
}
.methode-quote-container {
  padding: 60px 100px;
  border-radius: 15px;
  position: relative;
}
.gradient-border-container {
  padding: 2px;
  background: linear-gradient(to left, var(--gold), var(--blue), var(--gold));
  border-radius: 17px;
}
.gradient-border-container > * {
  background-color: var(--creamy-white);
  border-radius: 15px;
}
.methode-quote {
  text-transform: uppercase;
  font-family: Outfit;
  font-weight: 700;
  font-size: 1.5rem;
  text-align: center;
  position: relative;
}
.methode-quote-container:before,
.methode-quote-container:after {
  position: absolute;
  line-height: 50px;
  color: var(--gold);
  font-family: Outfit;
  font-weight: 500;
  font-size: 8rem;
}
.methode-quote-container:before {
  content: "“";
  top: 70px;
  left: 25px;
}
.methode-quote-container:after {
  content: "“";
  transform: rotate(180deg);
  bottom: 70px;
  right: 25px;
}
.galaxy-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.methode-img-philo {
  max-width: 200px;
}
.galaxy-container img {
  margin: auto;
  max-height: 450px;
}
.galaxy-container > div {
  position: absolute;
}
.galaxy-container p {
  font-size: 3.5rem;
  font-family: Outfit;
font-weight: 600;
  position: relative;
}
.galaxy-container > div > div {
  background: #fcf9f5;
  filter: blur(10px);
  position: absolute;
  top: -20px;
  left: 0;
  width: 100%;
  height: calc(100% + 40px);
}
.methode-hr {
  width: 50%;
}
.analyse-title {
  position: relative;
  width: fit-content;
  margin: 170px auto;
}
.analyse-title img {
  position: absolute;
  max-width: 170px;
}
.analyse-title img:first-child {
  left: -140px;
  top: 30px;
}
.analyse-title img:last-child {
  right: -190px;
  top: -110px;
}
.methode-annee-container {
  padding: 0 50px;
}
.methode-annee-container > div {
  flex: 1;
}
.methode-annee {
  --color: var(--white);

  max-width: calc(50% - 15px);
  flex: 1;
  border-radius: 805px;
  aspect-ratio: 1/1;
  background-color: var(--bg-color);
  padding: 20px 20px 40px;
}
.methode-annee div {
  font-family: Montserrat-Bold;
  font-size: 3rem;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-text-stroke: 2px var(--gold);
}
.methode-annee p {
  color: var(--color);
  font-size: 0.9rem;
}
.methode-facteurs {
  --color: var(--white);

  padding: 30px;
  background-color: var(--bg-color);
  color: var(--color);
  border-radius: 50%;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 85px;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
}
.methode-facteurs p {
  max-width: 130px;
  font-family: Outfit;
font-weight: 600;
  text-align: center;
  color: var(--color);
}
.methode-facteurs svg {
  position: absolute;
  bottom: -90px;
}
.methode-facteurs:first-child svg {
  margin-left: 40px;
}
.methode-facteurs:last-child svg {
  margin-left: -40px;
}
.methode-facteurs-2nd-line .methode-facteurs {
  margin-top: 85px;
  margin-bottom: 0;
}
.methode-facteurs-2nd-line .methode-facteurs svg {
  bottom: auto;
  top: -90px;
}
.methode-nombre-activites {
  font-size: 5rem;
  font-family: Outfit;
font-weight: 700;
}
.methode-euro-container {
  background-color: var(--beige);
  max-width: 200px;
  padding: 20px 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.methode-euro-container img {
  max-width: 130px;
  margin: 0 auto 20px;
}
.methode-euro-container p {
  text-align: center;
  margin-top: auto;
  font-size: 1.2rem;
}
.border-text {
  font-size: 4rem;
  font-family: Montserrat-Bold;
  margin-top: 20px;
  margin-bottom: -15px;

  -webkit-text-stroke: 3px var(--gold);
}
.methode-card-beige {
  max-width: 500px;
  border-radius: 15px;
  background-color: var(--beige);
  padding: 40px;
  display: flex;
  margin-top: 30px;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: space-around;
}
.methode-card-beige p {
  text-align: center;
  font-size: 1.2rem;
}

@media (max-width: 1200px) {
  .analyse-title {
    margin: 90px auto 160px;
  }
  .analyse-title img:first-child {
    max-width: 110px;
    left: -90px;
    top: 40px;
  }
  .methode-annee-container {
    padding: 0;
  }
  .analyse-title img:last-child {
    max-width: 90px;
    right: -100px;
    top: -50px;
  }
  .approche-inedite div {
    max-width: none;
  }
  .methode-nombre-activites {
    font-size: 3rem;
    margin-top: 15px;
  }
  .methode-euro-container {
    padding: 20px;
    max-width: 170px;
  }
  .border-text {
    margin-top: 40px;
    margin-bottom: -10px;
    font-size: 5rem;
  }
  .methode-card-beige {
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 0;
  }
  .methode-card-beige p {
    font-size: 1rem;
  }
  .methode-euro-container img {
    max-width: 60px;
  }
  .methode-container .mt-xxl {
    margin-top: 60px !important;
  }
  .methode-container .mb-xxl {
    margin-bottom: 60px !important;
  }
  .methode-container .mt-xl {
    margin-bottom: 40px !important;
  }
  .profile-pics-container img {
    max-width: 200px;
  }
  .methode-img {
    max-width: 300px;
    max-height: 200px;
  }
  .gold-before-section {
    max-width: 100%;
    margin-left: 30px;
  }
  .gradient-card-container {
    max-width: 500px;
  }
  .gradient-card-container img {
    max-width: 100px;
  }
  .gradient-card p {
    line-height: 1.6;
  }
  .methode-valeurs > div {
    margin-top: auto;
  }
  .methode-valeurs img {
    max-width: 120px;
  }
  .methode-img-philo {
    max-height: 130px;
  }
  .galaxy-container img {
    max-width: 300px;
  }
  .galaxy-container p {
    font-size: 1.5rem;
  }
  .methode-estimation-fdc {
    margin-left: 80px;
    margin-right: 80px;
    position: relative;
  }
  .methode-estimation-fdc img {
    position: absolute;
    max-width: 200px;
    bottom: 30px;
    right: 30px;
  }
  .methode-annee {
    max-width: 50%;
  }
}

@media (max-width: 992px) {
  .methode-card-beige {
    padding: 40px;
    margin: 30px auto;
    width: 100%;
  }
  .analyse-title {
    margin: 90px auto 130px;
  }
  .analyse-title img:first-child {
    max-width: 90px;
    left: -70px;
    top: 20px;
  }
  .methode-annee-container {
    padding: 0;
  }
  .methode-annee {
    max-width: 220px;
  }
  .analyse-title img:last-child {
    max-width: 70px;
    right: -80px;
    top: -50px;
  }
  .methode-card-beige {
    padding: 40px 30px;
    margin-bottom: 0;
  }
  .methode-row-text-img .border-text {
    position: absolute;
    width: 100%;
    text-align: center;
    top: -150px;
    left: 0;
  }
  .methode-margin-xxl-900,
  .methode-container.container .methode-margin-xxl-900 {
    margin-top: 130px !important;
  }
  .gold-before-section {
    padding: 0;
    margin: 0 60px;
  }
  .gold-before-section:before {
    display: none;
  }
  .gradient-card-container {
    max-width: 100%;
    margin: 0 60px;
  }
  .gradient-card-container:first-of-type img {
    margin: 0 auto -40px;
  }
  .methode-valeurs .row > div {
    margin-top: auto;
  }
  .methode-valeurs .row {
    justify-content: space-around;
  }
  .methode-valeurs img {
    max-width: 150px;
    max-height: none;
  }
  .methode-img-philo {
    max-width: 120px;
  }
  .methode-estimation-fdc .dots-list {
    margin: auto;
    position: relative;
    top: 220px;
    margin-bottom: 50px;
  }
  .methode-estimation-fdc .dots-list,
  .methode-estimation-fdc .dots-list li {
    width: fit-content;
  }
  .methode-estimation-fdc img {
    position: relative;
    bottom: auto;
    right: auto;
    margin: 0 !important;
    top: -290px;
  }
  .galaxy-container p {
    font-size: 2.5rem;
  }
}

@media (max-width: 576px) {
  .methode-container .methode-margin-xl {
    margin-top: 100px !important;
  }
  .methode-euro-container {
    border-radius: 15px;
  }
  .methode-euro-container img {
    margin-bottom: 0;
  }
  .methode-nombre-activites {
    margin-top: -10px;
    font-size: 2.5rem;
  }
  .methode-facteurs,
  .methode-facteurs-2nd-line .methode-facteurs {
    width: 50%;
    padding: 20px;
    margin: 0;
    margin-top: 0;
  }
  .methode-facteurs:nth-child(2n),
  .methode-facteurs-2nd-line .methode-facteurs:nth-child(2n -1) {
    margin-left: auto;
  }
  .methode-facteurs-2nd-line .methode-facteurs:nth-child(2n) {
    margin-left: 0;
  }
  .methode-facteurs-2nd-line .methode-facteurs:nth-child(2n) svg {
    left: auto;
    right: -50%;
  }
  .methode-facteurs svg {
    bottom: auto;
    right: -50%;
    top: 50%;
    height: 50%;
    width: 50%;
    margin-left: 0;
  }
  .methode-facteurs:nth-child(2n) svg,
  .methode-facteurs-2nd-line .methode-facteurs:nth-child(2n -1) svg {
    right: unset;
    left: -50%;
  }
  .methode-facteurs-2nd-line .methode-facteurs svg {
    top: 50%;
    margin-left: 0;
  }
  .methode-annee {
    margin: auto;
    background-color: transparent;
    max-width: none;
    aspect-ratio: unset;
    padding: 0;
  }
  .methode-annee p {
    text-align: center;
    font-size: 1.2rem;
    font-family: Outfit;
font-weight: 600;
    color: var(--dark-blue);
  }
  .methode-annee div {
    font-size: 2.5rem;
  }
  .analyse-title {
    margin: 50px auto;
  }
  .border-text {
    margin: 10px 0 -30px;
  }
  .methode-row-text-img .border-text {
    top: -100px;
  }
  .bg-beige .methode-row-text-img {
    top: -190px;
    margin-bottom: -150px !important;
  }
  .gold-before-section {
    margin: 0;
    padding: 0;
    margin-top: 20px;
  }
  .gradient-card-container img {
    display: none;
  }
  .gradient-card-container {
    margin: 0;
  }
  .methode-quote-container {
    padding: 60px;
  }
  .methode-quote {
    font-size: 1rem;
  }
  .methode-quote-container:before {
    left: 1px;
    top: 30px;
  }
  .methode-quote-container:after {
    right: 1px;
    bottom: 30px;
  }
  .methode-valeurs {
    gap: 0;
  }
  .gold-dot {
    position: absolute;
    bottom: -15px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: var(--gold);
  }
  .methode-estimation-fdc {
    margin-left: 0;
    margin-right: 0;
  }
  .methode-estimation-fdc img {
    display: none;
  }
  .methode-estimation-fdc .dots-list {
    top: 0;
    margin: 0;
  }
  .methode-quote-container:before {
    content: "“";
    top: 62px;
    left: 25px;
  }
  .methode-quote-container:after {
    content: "“";
    transform: rotate(180deg);
    bottom: 62px;
    right: 25px;
  }
  .galaxy-container p {
    font-size: 2.2rem;
  }
}
