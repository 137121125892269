.multiples-inputs-container {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}
.multiples-inputs-row {
  display: flex;
  gap: 10px;
  align-items: space-between;
}
.multiple-inputs-container .input-container .form-error {
  left: 5px;
  min-width: max-content;
}

@media (max-width: 992px) {
  .multiples-inputs-row {
    flex-direction: column;
  }
}
