.pdf-container .pdf-business-image-container {
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}
.pdf-container .pdf-business-img {
  width: 550px;
  height: 420px;
  object-fit: cover;
  border-radius: 15px;
}
