.tab-content-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.tab-content-header h2 {
  margin: 0;
  font-weight: 600;
  font-size: 1.7rem;
  display: flex;
  align-items: center;
}

.tab-content-header h2 .tuto-icon {
  margin-left: 15px;
}

.tab-content-header p {
  margin-top: 10px;
  font-size: 1.1rem;
  font-weight: 500; /* Utilisez une valeur numérique compatible */
  color: var(--grey);
}

.folder-simulateur-buttons {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 20px;
}

.folder-table-simulateur {
  margin-top: 20px;
  border: 1px solid var(--light-grey);
  border-radius: 10px;
  padding: 10px;
}

.folder-table-simulateur-header {
  margin-right: 14px;
  margin-bottom: -5px;
  display: grid;
  gap: 10px;
  padding: 0 10px;
  grid-template-columns: 1fr auto;
  grid-template-rows: 100%;
}

.folder-table-simulateur-body {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
}

.credit-simulator-item {
  position: relative;
  padding: 10px;
  margin-bottom: 10px;
  background-color: var(--dark-blue);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.credit-simulator-item p {
  margin: 0;
  color: var(--white);
}

.info-block-simulateur {
  display: flex;
  justify-content: center;
  align-items: center;
}

.folder-table-simulateur-header,
.credit-simulator-item  {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
}

.folder-table-simulateur-header p:last-child {
  text-align: right;
}

.simulateur-dropdown {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.dropdown-icon-simulators {
  transition: transform 0.3s;
  cursor: pointer;
  margin-right: 30px;
}

.dropdown-menu-simulation {
  position: absolute;
  top: 25px;
  right: 0;
  width: max-content;
  overflow: hidden;
  max-height: 0;
  background-color: var(--white);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  list-style: none;
  z-index: 1000;
  transition:
    max-height 0.3s,
    padding 0.3s;
  padding: 0 20px;
  display: block;
}

.dropdown-menu-simulation.show {
  padding: 20px;
  max-height: 150px;
}

.dropdown-menu-simulation li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 8px;
}

.dropdown-menu-simulation li:last-child {
  margin-bottom: 0;
}

.dropdown-menu-simulation li button {
  background-color: transparent;
  border: none;
  color: var(--dark-blue);
  cursor: pointer;
  transition: color 0.2s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0;
}

.dropdown-menu-simulation li button:hover {
  color: var(--blue);
}

.folder-table-simulateur-footer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.modal-buttons-row {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}
