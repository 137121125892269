input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  cursor: pointer;
  left: 5px;
}
input[type="date"] {
  padding-left: 30px;
}
.before-euro::before,
.before-percent::before {
  position: absolute;
  font-family: Outfit;
font-weight: 600;
  font-variant: small-caps;
  top: 3px;
}
.before-euro::before {
  content: "€";
}
.before-percent::before {
  content: "%";
}
.before-euro input,
.before-percent input {
  padding-left: 20px;
}
.before-search input {
  padding-left: 25px;
}
.before-search-content {
  position: absolute;
  top: 3px;
}
.filled-input {
  background-color: var(--bg-color);
  color: var(--white) !important;
  border-radius: 10px;
  font-family: Outfit;
font-weight: 700;
}
.input-container {
  width: 100%;
  position: relative;
  padding-bottom: 3px;
}
.input-container .form-error {
  left: 20px;
  bottom: -16px;
}
.input-container .form-error:before {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.input-container.with-error input,
.number-input-container.with-error input,
.input-container.with-error select {
  border-bottom-color: var(--red);
}
.input-words-count {
  position: absolute;
  top: 35px;
  font-family: Poppins-MediumItalic;
  right: 20px;
  color: var(--grey);
  font-size: 0.8rem;
}
