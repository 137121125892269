.blur-unavailable {
  filter: blur(5px);
}
.redirection-unavailable {
  position: absolute;
  width: 300px;
  z-index: 10;
  top: 15vh;
  left: 0;
  right: 0;
  margin: auto;
}
.offre-container {
  background-color: var(--beige);
  border-radius: 10px;
  padding: 30px 40px;
  padding-top: 80px;
  position: relative;
  max-width: 500px;
  margin: 35px 30px 0 auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.offre-crown {
  position: absolute;
  z-index: 2;
  top: -45px;
  left: calc(50% - 45px);
}
.offre-container:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 50px;
  left: 0;
  top: 0;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: var(--dark-blue);
}
.offre-container.liberty:before {
  background-color: var(--light-blue);
}
.offre-container.optimum:before {
  background-color: var(--gold);
}
.offre-container * {
  font-size: 1.2rem;
}
.offre-buttons-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  padding: 10px 30px;
}
.offre-buttons-container .link-with-arrow {
  color: var(--dark-blue);
  font-family: Outfit;
font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 20px;
}
.offre-buttons-container .button {
  width: fit-content;
}

@media (max-width: 992px) {
  .offre-container {
    margin: 0 auto 40px;
  }
}

@media (max-width: 576px) {
  .offre-container {
    margin-top: 50px;
  }
}
