.error-container {
  display: flex;
  gap: 100px;
  margin-top: 50px;
}
.error-container img {
  width: 50%;
  max-height: 500px;
}
.error-description {
  display: flex;
  width: fit-content;
  flex-direction: column;
  justify-content: center;
}
.error-description h1 {
  font-size: 5rem;
  font-family: Outfit;
  font-weight: 700;
}
.error-description p:first-of-type {
  font-size: 2rem;
  font-family: Outfit;
font-weight: 600;
  line-height: 1.3;
}

@media (max-width: 992px) {
  .error-container {
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
  .error-description {
    margin: 0 50px;
  }
  .error-container img {
    max-height: 300px;
  }
}

@media (max-width: 576px) {
  .error-container img {
    max-height: 250px;
  }
  .error-container {
    gap: 20px;
    margin-top: 10px;
  }
  .error-description h1 {
    font-size: 2rem;
  }
  .error-description p:first-of-type {
    font-size: 1.2rem;
  }
}
