.info-add-financing-plans {
  margin-bottom: 20px;
}

.info-add-financing-plans p {
  color: var(--grey);
  text-align: justify;
}

.checkbox-list-container {
  width: 100%;
}

.checkbox-list-container h3 {
  font-size: 1.2rem;
}

.import-estimations-to-deliverable {
  max-height: 220px;
  overflow-y: auto;
}

.import-estimations-to-deliverable::-webkit-scrollbar {
  width: 6px;
}

.import-estimations-to-deliverable::-webkit-scrollbar-thumb {
  background-color: var(--grey);
  border-radius: 3px;
}

.checkbox-estimation-item {
  background-color: var(--dark-blue);
  border-radius: 10px;
}

.checkbox-estimation-item label {
  color: white;
}

.modal-buttons-row {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
