.title-form {
  font-size: 1.75rem;
}

.preview-images {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.image-container {
  margin-left: 10px;
  margin-bottom: 20px;
  position: relative;
}

.preview-image {
  margin-top: 10px;
  width: 250px;
  height: 150px;
  object-fit: cover;
}

.update-news-form {
  padding: 20px;
}

.confirmation-message {
  color: green;
  text-align: center;
  margin-bottom: 1rem;
}

.add-button {
  border: 3px solid var(--main-color);
  background-color: white;
  margin-bottom: 10px;
}

.buttons {
  display: flex;
  justify-content: space-evenly;
}

.back-button {
  margin-left: 6%;
}

.dropdown-label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: var(--blue);
}

.styled-dropdown {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid var(--light-blue);
  border-radius: 4px;
  background-color: var(--white);
  color: var(--blue);
  font-size: 16px;
  outline: none;
  cursor: pointer;
}

.styled-dropdown:focus {
  border-color: none;
}

.styled-dropdown:hover {
  background-color: var(--light-blue);
  color: var(--blue);
}
