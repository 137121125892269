.filters-group-container {
  box-shadow: 3px 3px 20px rgba(123, 123, 123, 0.25);
  padding: 5px 0;
  margin-bottom: 10px;
  background-color: var(--creamy-white);
}
.filters-group-container .container > div {
  justify-content: center;
  margin: auto;
  display: flex;
  gap: 20px;
}
.filter {
  font-size: 0.9rem;
  font-family: Outfit;
font-weight: 600;
  border: 2px solid var(--dark-blue);
  border-radius: 3px;
  padding: 6px 15px 5px;
  text-transform: uppercase;
  cursor: pointer;
  transition:
    color 0.3s,
    background-color 0.3s;
  white-space: nowrap;
}
.filter:hover,
.filter.active {
  color: var(--white);
  background-color: var(--dark-blue);
}
.filters-group-container *::-webkit-scrollbar {
  height: 8px;
}
.filters-group-container *::-webkit-scrollbar-track {
  margin: 10px;
}
.filters-group-container .container {
  max-width: min(var(--container-width), calc(100% - 100px));
}
.filter-container {
  position: relative;
  display: inline-block;
  overflow: visible;
}
.filter-container:hover .soon-tag-global {
  display: block;
}
.soon-tag-global {
  display: none;
  position: absolute;
  top: -14px;
  right: -10px;
  background-color: var(--pale-blue);
  color: var(--dark-blue);
  font-size: 0.7rem;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 3px;
  text-transform: uppercase;
  z-index: 10000;
}
.filter.disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
  border-color: #ccc;
}
.filter.disabled:hover {
  background-color: #ccc;
  color: #666;
}
@media (min-width: 992px) {
  .filters-group-container .components-slider {
    gap: 20px;
    overflow: visible;
  }
  .filters-group-container .components-row {
    padding-left: 2px;
    overflow: visible;
  }
  .filters-group-container .components-row-container {
    overflow: visible;
  }
}

@media (max-width: 992px) {
  .filters-group-container .container {
    display: flex;
    gap: 20px;
    overflow-x: scroll;
  }
  .filter.active {
    padding: 6px 6px 5px;
    opacity: 1;
  }
  .filter:hover,
  .filter.active {
    background-color: unset;
    color: var(--dark-blue);
  }
  .filters-group-container.pinned {
    box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.15);
  }
  .filters-group-container .container {
    margin: 0;
    max-width: 100%;
    flex-wrap: nowrap;
    overflow-x: scroll;
    justify-content: flex-start;
    padding: 10px;
    gap: 20px;
    box-shadow: none;
    margin: auto;
    width: fit-content;
  }
  .filter {
    border: none;
    opacity: 0.6;
  }
}
