.step-2-buttons {
  z-index: 0;
  top: -410px;
  position: relative;
}
.raisons-sociales-dropdown {
  position: absolute;
  top: 210px;
  left: 120px;
}
.parcours-zone-influence {
  margin-top: 20px;
}
.input-radio:checked + .radio-container .parcours-zone-influence {
  --color: var(--white) !important
;
}
.emplacement-gold-text {
  color: var(--gold);
}
.borderless-fieldset .murs-activites-list {
  top: 30px;
  max-height: 92px;
}
.rh-multiple-inputs-container .button {
  width: fit-content;
}
.rh-multiple-inputs-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

@media (max-width: 1200px) {
  .zone-influence-radio label p {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .parcours-zone-influence {
    display: none;
  }
}

@media (max-width: 992px) {
  .zone-influence-radio .radio-group {
    gap: 15px;
    margin-top: 5px;
  }
  .zone-influence-radio .radio-group > div {
    max-width: 47%;
    margin: 0;
  }
}

@media (max-width: 576px) {
  .zone-influence-radio .radio-group {
    gap: unset;
    column-gap: 30px;
    margin-top: 0;
  }
  .zone-influence-radio .radio-group > div {
    max-width: 100%;
    margin-top: 10px;
  }
}
