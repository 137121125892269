.mes-formations-container h2 {
  font-size: 1.5rem;
}
.last-video-played-container {
  display: flex;
  margin-right: 30px;
  margin-top: 30px;
  gap: 1rem;
  margin-bottom: 30px;
}
.last-video-played-container h3,
.formation-container h3 {
  text-transform: uppercase;
  font-size: 1.5rem;
  font-family: Outfit;
font-weight: 600;
  margin-top: 10px;
  margin-bottom: 20px;
}
.last-video-played-container > div:last-child {
  min-width: 640px;
}
.progression-container {
  padding: 30px 20px;
  margin-bottom: 30px;
  margin-top: auto;
}
.progression-container h2 {
  text-align: center;
  margin-bottom: 30px;
}
.category-container h2 {
  margin-bottom: 30px;
}
.formation-progress-container {
  display: flex;
  width: 100%;
  gap: 10px;
  margin-bottom: 30px;
}
.formation-progress-container * {
  font-size: 0.9rem;
  font-family: Outfit;
font-weight: 600;
}
.formation-progress-container div {
  width: 100%;
}
.formation-container svg {
  min-width: 30px;
}
.progress-bar {
  width: 100%;
  background-color: var(--light-grey);
  height: 12px;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
}
.formation-container {
  display: flex;
  width: calc(var(--container-width) - 180px);
}
.progress-bar:before {
  content: "";
  height: 100%;
  width: var(--progression);
  position: absolute;
  top: 0;
  background-color: var(--gold);
  left: 0;
  transition: width 0.3s;
}
.formation-container > div:first-child {
  width: 100%;
  max-width: 30%;
  padding-right: 30px;
}
.video-thumbnail-container {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  transition: transform 0.3s;
}
.video-thumbnail-container img {
  aspect-ratio: 16/9;
  width: 100%;
  display: flex;
}
.video-duration {
  background-color: var(--white);
  width: fit-content;
  padding: 5px 10px;
  font-size: 0.8rem;
  border-radius: 5px;
  position: absolute;
  right: 15px;
  bottom: 15px;
}
.video-completed {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--light-grey);
  border-bottom-left-radius: 100%;
  height: 40px;
  padding: 5px 0 0 5px;
  border-top-right-radius: 5px;
}
.video-progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: var(--light-grey);
}
.video-progress-bar::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: var(--progression);
  background-color: var(--gold);
}
.formation-videos-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px 20px;
  max-width: 70%;
}
.play-button {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  margin: auto;
  border: 2px solid var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
}
.play-button:before {
  content: "";
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 12.5px 0 12.5px 21.7px;
  border-color: transparent transparent transparent var(--white);
  transform: rotate(0deg);
  margin-left: 5px;
  margin-top: 1px;
  position: absolute;
}
.video-container {
  width: calc(33% - 12px);
  cursor: pointer;
}
.video-container:hover .video-thumbnail-container {
  transform: scale(1.02);
}
.video-container p {
  margin-left: 10px;
  font-family: Outfit;
font-weight: 600;
  margin-top: 3px;
}
#scroller_anchor {
  position: relative;
  top: -100px;
}
.formations-filters-group {
  z-index: 100;
}

@media (max-width: 1400px) {
  .last-video-played-container {
    flex-direction: column;
  }
}

@media (max-width: 1200px) {
  .progression-container {
    background-color: transparent;
    box-shadow: none;
    margin-bottom: 0;
  }
  .progression-container h2 {
    text-align: left;
  }
  .formation-container {
    flex-direction: column;
    gap: 20px;
  }
  .formation-container > div:first-child,
  .formation-videos-container {
    max-width: 100%;
  }
  .last-video-played-container > div:last-child {
    min-width: unset;
  }
}
