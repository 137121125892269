.thumbnails-row-container {
  --thumbnail-width: 70vw;
  margin: var(--margin);
  position: relative;
}
.thumbnails-row {
  max-width: 100%;
  overflow-x: hidden;
}
.thumbnails-slider {
  width: max-content;
  display: flex;
  position: relative;
  right: var(--slide);
  padding: 0 calc((100vw - var(--thumbnail-width) - 20px) / 2);
  transition: right 0.3s;
}
.thumbnails-slider .thumbnail-container {
  height: calc(100% - 20px);
  max-width: var(--thumbnail-width);
  margin: 10px;
}
.thumbnail-row-bracket {
  position: absolute !important;
  top: calc(50% - 20px);
  left: -40px;
}
.thumbnail-row-bracket:last-of-type {
  right: -40px;
  left: auto;
}

@media (min-width: 400px) {
  .thumbnails-row-container {
    --thumbnail-width: calc(55vw);
  }
}

@media (min-width: 576px) {
  .thumbnails-row-container .thumbnails-row-unit {
    max-width: calc(var(--thumbnail-max-width) - 50px);
  }
  .thumbnails-row-container {
    --thumbnail-width: calc(100%);
  }
  .thumbnails-slider {
    padding: 0 calc((100vw - (var(--thumbnail-max-width) - 10px) * 2) / 2);
  }
  .thumbnails-row-unit {
    margin: 20px;
  }
  .thumbnails-slider {
    gap: 2px;
  }
}

@media (min-width: 768px) {
  .thumbnails-row-container .thumbnails-row-unit {
    max-width: var(--thumbnail-max-width);
  }
  .thumbnails-row-container {
    --thumbnail-width: calc(100%);
  }
  .thumbnails-slider {
    padding: 0 calc((100vw - (var(--thumbnail-max-width) + 40px) * 2) / 2);
  }
  .thumbnails-row-unit {
    margin: 10px 20px;
  }
  .thumbnails-slider {
    gap: 2px;
  }
}

@media (max-width: 992px) {
  .thumbnail-row-bracket {
    display: none;
  }
}

@media (max-width: 576px) {
  .thumbnails-slider .thumbnail-container {
    height: auto !important;
  }
}

@media (min-width: 992px) {
  .thumbnails-row {
    border-top: 2px solid;
    border-bottom: 2px solid;
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0), var(--gold), var(--gold), rgba(0, 0, 0, 0)) 1;
  }
  .thumbnails-slider {
    padding: 0;
  }
  .thumbnails-slider .thumbnail-container {
    --thumbnail-width: var(--thumbnail-max-width);
  }
}
