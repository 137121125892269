* {
  --step: 0;
}
.parcours-carousel-container {
  --height: 2000px;

  position: relative;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  transition: height 0.3s;
}
.parcours-carousel-container.with-margin {
  margin-bottom: 410px;
}
.parcours-carousel {
  display: flex;
  gap: 1rem;
  width: 100%;
  transform: translateX(calc(var(--step) * (var(--container-width) - 4px) * -1));
  transition: transform 0.3s;
}
.parcours-carousel form {
  min-width: 100%;
  padding: 0 50px;
  margin: 0;
  overflow: hidden;
}

@media (max-width: 576px) {
  .parcours-carousel {
    transform: translateX(calc(var(--step) * (100vw - 4px) * -1));
  }
  .parcours-carousel form {
    padding: 0;
  }
  .parcours-carousel .borderless-fieldset {
    box-shadow: none;
  }
}
