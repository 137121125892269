.hidden-category {
  visibility: hidden;
  max-height: 0;
}
.formation-hr {
  background: linear-gradient(to right, transparent, var(--gold), transparent);
  width: 100vw;
  height: 4px;
  border-radius: 100%;
  border: 20vw solid var(--creamy-white);
  border-top: 0;
  border-bottom: 0;
}
@media (max-width: 576px) {
  .formations-row {
    margin: 0;
    max-width: 100%;
  }
  .progression-container h2 {
    text-align: left;
  }
  .category-container {
    box-shadow: none;
    padding: 10px;
    background-color: transparent;
    padding: 0;
  }
  .video-container {
    width: 300px;
  }
  .video-container * {
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  .formations-row-bracket {
    display: none;
  }
  .formation-videos-container {
    position: relative;
    right: var(--slide);
    width: fit-content;
    transition: right 0.3s;
    padding: 0 10px;
  }
  .formations-slider {
    flex-direction: column;
    gap: 40px;
  }
  .formation-progress-container {
    margin-bottom: 10px;
  }
  .formation-container div:first-child {
    max-width: calc(100vw - 20px);
  }
}
