.nouveaute-item {
  margin: 10px;
  padding: 10px;
}

.nouveaute-card {
  padding: 20px;
  margin-top: 10px;
}

.point {
  position: relative;
  display: flex;
  align-items: center;
}

.nouveaute-card:hover {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
}

.nouveaute-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 15px;
  width: 100%;
}

.nouveaute-description {
  flex: 1;
  font-size: 1rem;
  margin-bottom: 15px;
}

.nouveaute-description.center {
  text-align: center;
  margin: 10 auto;
}

.nouveaute-description.left {
  text-align: left;
  margin-bottom: 15px;
  word-wrap: break-word;
}

.carousel-image {
  width: 60%;
  margin-bottom: 20px;
  margin-left: 20px;
}

@media (max-width: 768px) {
  .carousel-image {
    width: 100%;
    margin-left: 0;
  }
}

.nouveaute-title {
  font-size: 1.75rem;
}

.nouveaute-image {
  width: 55%;
  margin-top: 10px;
  margin-bottom: 10px;
  align-self: flex-start;
}

.nouveaute-header {
  display: flex;
  margin-top: 5px;
  margin-bottom: 10px;
}

.nouveaute-date {
  color: var(--grey);
}

.tag-button {
  color: var(--white);
  border: none;
  padding: 5px 10px;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 0.8rem;
  margin-bottom: 10px;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 10px;
}

.blue-dot {
  width: 15px;
  height: 15px;
  background-color: var(--blue);
  border-radius: 50%;
  position: absolute;
  left: -56px;
  top: 50%;
  transform: translateY(-50%);
}

.category-button {
  background-color: var(--white);
  border: 2px solid var(--blue);
  color: var(--blue);
  padding: 5px 10px;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 0.7rem;
  margin-left: 15px;
}

.sort-button {
  background-color: var(--white);
  border: 2px solid var(--blue);
  color: var(--blue);
  padding: 5px 10px;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 0.7rem;
  margin-left: 15px;
}

.like-dislike-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 10px;
}

.tuto-button {
  border: 3px solid var(--main-color);
  background-color: white;
}
.error-message {
  color: var(--green);
  text-align: center;
}
