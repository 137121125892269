.homepage-text-img-container {
  position: relative;
  margin-bottom: 70px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  height: 350px;
  margin: calc((100vh - 350px) / 2) 10vw;
}
.homepage-text-img-container img {
  max-width: 380px;
  max-height: 100%;
  object-fit: contain;
  width: 100%;
  max-height: 350px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto 0;
}
.homepage-text-img-container p:first-of-type {
  font-family: Outfit;
font-weight: 700;
}
.homepage-text-img-container p {
  font-size: 1.5rem;
  text-align: right;
  margin-left: 450px;
  font-family: Outfit;
font-weight: 600;
}

@media (max-width: 1200px) {
  .homepage-text-img-container {
    margin: 0 50px;
    height: 320px;
  }
  .homepage-text-img-container img {
    max-width: 250px;
  }
  .homepage-text-img-container p {
    margin-left: 300px;
    font-size: 1.2rem;
  }
}

@media (max-width: 992px) {
  .homepage-text-img-container {
    align-items: center;
    height: auto;
    gap: 20px;
    margin-bottom: 70px;
  }
  .homepage-text-img-container img {
    position: relative;
    order: 0;
  }
  .homepage-text-img-container p {
    order: 1;
    text-align: center !important;
    margin: 0 !important;
  }
}

@media (max-width: 576px) {
  .homepage-text-img-container {
    margin: 0 0 70px 0;
  }
}
