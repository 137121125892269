.pdf-container .annex-title {
    font-size: 4rem;
    font-weight: 500;
    width: 100%;
    text-align: center;
    padding: 50px 80px;
    background-color: var(--main-color);
    border-radius: 15px;
    margin: 150px auto 0;
    color: var(--secondary-text-color);
    text-transform: uppercase;
}
.pdf-container .annex-pdf-subtitles {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    height: fit-content;
    margin: auto 0;
    padding: 50px 0;
    justify-content: space-evenly;
    border: 1px solid black;
}
.pdf-container .annex-pdf-subtitles a:last-child {
    margin-bottom: 0;
}
.pdf-container .annex-pdf-subtitles a {
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 1.5rem;
    display: flex;
    text-decoration: none;
    width: 480px;
}
.pdf-container .annex-pdf-subtitles a img {
    margin-right: 15px;
    width: 25px;
    object-fit: contain;
}