.tarifs-row {
  display: flex;
  gap: 2vw;
  justify-content: center;
}
.tarifs-page-container {
  position: relative;
  margin: 50px 6vw 30px;
}
.tarifs-row:first-child {
  width: 100%;
  position: absolute;
  bottom: 0;
}
.tarif-container,
.empty-tarif-container {
  min-width: calc(33% - 1vw);
}
.maestro-crown {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: -50px;
  z-index: 2;
}
.tarif-container {
  background-color: var(--white);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  overflow: hidden;
}
.tarif-title {
  font-family: Outfit;
font-weight: 900;
  position: relative;
  width: 100%;
  padding: 25px 0;
  font-size: 1.7rem;
  text-transform: uppercase;
  font-family: Outfit;
font-weight: 900;
  text-align: center;
  background-color: var(--color);
}
.maestro-tarif .tarif-title {
  color: var(--white);
}
.maestro-tarif .tarif-price,
.maestro-price {
  margin-top: 30px;
  font-family: Outfit;
font-weight: 700;
  display: block;
  font-size: 2.8rem;
}
.maestro-tarif .tarif-price {
  line-height: 0.7;
  margin-top: -5px;
}
.tarif-discount {
  position: absolute;
  background-color: var(--dark-red);
  border-radius: 10px;
  bottom: -18px;
  font-size: 1.2rem;
  padding: 3px 15px;
  color: var(--white);
  font-family: Outfit;
font-weight: 600;
  left: 0;
  right: 0;
  width: fit-content;
  margin: auto;
  text-align: center;
}
.price-row {
  margin-top: 15px;
  text-align: center;
}
.tarif-price {
  line-height: 1;
}
.tarif-price,
.normal-price-crossed {
  font-size: 2rem;
  font-family: Outfit;
font-weight: 700;
}
.normal-price-crossed {
  font-size: 1.8rem;
}
.maestro-tarif .normal-price-crossed {
  margin: 0;
}
.tarif-duration.with-promo {
  position: absolute;
  width: 100%;
  left: 0;
}
.normal-price-crossed {
  color: var(--grey);
  margin-right: 20px;
  position: relative;
}
.normal-price-crossed:after {
  content: "";
  height: 2px;
  width: calc(100% + 8px);
  position: absolute;
  top: 46%;
  left: -4px;
  background-color: var(--dark-red);
}
.tarif-with-discount {
  margin-top: 20px;
}
.tarif-duration {
  color: var(--grey);
  font-size: 1.1rem;
}
.tarif-engagment {
  margin: 35px 0 18px;
  text-align: center;
  font-size: 0.9rem;
  font-family: Outfit;
font-weight: 700;
  line-height: 1.4;
}
.tarif-engagment span {
  font-size: 1.2rem;
  font-family: Outfit;
font-weight: 700;
}
.tarifs-detail {
  margin-top: 2vw;
  background-color: var(--white);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  overflow: hidden;
  padding: 30px;
  padding-top: 20px;
}
.tarif-detail-title {
  text-transform: uppercase;
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 10px;
  font-family: Outfit;
font-weight: 700;
}
.tarifs-detail .row-500,
.tarifs-detail .row-1000 {
  gap: 20px;
  justify-content: center;
}
.tarifs-detail svg {
  margin-bottom: 5px;
}
.tarif-content {
  position: relative;
  padding: 10px;
  padding-bottom: 25px;
}
.tarifs-rapports-row {
  margin-top: -60px;
}
.tarif-activate-button {
  cursor: not-allowed;
}
.beta-tarif {
  margin-top: 10px;
  font-weight: 700;
}

.tarif-v1 {
  margin-top: 10px;
  text-decoration: underline;
  color: var(--sky-blue);
}

@media (max-width: 1200px) {
  .tarifs-rapports-row {
    margin-top: -30px;
  }
  .maestro-tarif .tarif-price,
  .maestro-price {
    margin-top: 8px;
    font-size: 3rem;
  }
  .tarif-engagment {
    font-size: 1rem;
  }
  .tarif-engagment span {
    font-size: 1.3rem;
  }
  .tarifs-page-container {
    position: relative;
    margin: 0;
    margin-top: 60px;
  }
  .maestro-crown {
    transform: scale(0.8);
    transform-origin: bottom;
  }
  .tarif-title {
    padding: 30px 0;
  }
  .tarif-content {
    padding: 0 20px 20px;
  }
  .tarif-with-discount,
  .maestro-tarif .tarif-with-discount {
    margin-top: 40px;
  }
  .tarif-engagment {
    margin: 25px 0 20px;
  }
  .maestro-tarif .normal-price-crossed {
    display: block;
    width: fit-content;
    margin: 20px auto 0;
  }
  .tarif-detail-title {
    font-size: 1.5rem;
  }
}

@media (max-width: 992px) {
  .tarifs-row:first-child {
    position: relative;
    margin-bottom: 3vw;
  }
  .tarif-container {
    min-width: calc(50% - 1.5vw);
  }
  .empty-tarif-container {
    min-width: unset;
    width: 0;
  }
  .tarifs-detail .row-500 {
    gap: 50px;
    justify-content: space-around;
  }
  .tarifs-detail .row-500 .centered {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .tarif-container {
    min-width: 100%;
  }
  .tarifs-row {
    flex-direction: column;
    gap: 40px;
  }
  .tarifs-row:first-child {
    margin-bottom: 40px;
  }
  .tarifs-detail {
    margin-top: 40px;
  }
  .tarifs-detail .row-500 {
    gap: 20px;
  }
}
