@keyframes slideInFromTop {
  from {
    transform: translateY(-40px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.dotsColumn-visible {
  animation: slideInFromTop 0.6s ease-out forwards;
}
